import {
  takeLatest
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { resendVerificationEmailEndpoint } from '@/constants/apiRoutes'

import {
  RESEND_VERIFICATION_EMAIL,
} from '@/store/actions/users'
import createNotifications from "@/helpers/notification";

function* resendVerificationEmail({ payload }) {
  try {
    yield request({
          url: resendVerificationEmailEndpoint(payload),
          method: 'post',
        })
   
  } catch (error) {
    console.log('TCL: function*getUserSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(RESEND_VERIFICATION_EMAIL.REQUEST, resendVerificationEmail)
}
