import { GET_INVENTORY_FILE } from '@/store/actions/inventory/index';

export const requestGetFile = (payload) => ({
  type: GET_INVENTORY_FILE.REQUEST,
  payload,
})

export const successRequestGetFile = (payload) => ({
  type: GET_INVENTORY_FILE.SUCCESS,
  payload,
})

export const errorRequestGetFile = (payload) => ({
  type: GET_INVENTORY_FILE.ERROR,
  payload,
})