import React, {
  useState, useMemo, useCallback, useEffect,
} from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import { FormattedMessage as Lang } from 'react-intl'
import { REQUEST_STATUSES } from '@/constants/requests'
import ViewTree from '@/components/blocks/ViewTree'
import TextControl from '@/components/controls/TextControl'
import PageSidebar from '@/components/regions/sidebars/PageSidebar'
import UsersIcons from '@/components/icons/users'
import useHtmlTitle from '@/hooks/useHtmlTitle'
import {
  COMPANY,
  GROUP,
  GROUPS,
  USER,
  ADMIN,
  TENANT,
  USER_EDIT_FROM_ADMIN,
  TENANT_EDIT,
  ADMIN_EDIT,
  USER_EDIT,
  GROUPS_EDIT,
  USERS_GROUP,
} from '@/constants/viewTree'
import set from 'lodash/set'
import CreateTenant from './components/CreateTenant'
import CreateAdmin from './components/CreateAdmin'
import MultipleUserEdit from './components/MultipleUserEdit'
import UsersPresentation from './components/UsersPresentation'
import CreateGroupOfUsers from './components/CreateGroupOfUsers'
import EditAdmin from './components/EditAdmin'
import EditUserFromAdmin from './components/EditUserFromAdmin'
import AdminsSortPanel from './components/AdminsSortPanel'
import EditUser from './components/EditUser'
import {
  Main, ContentWrapper, AdminsBarContainer,
} from './styles'

const Users = ({
// VALUES
  zoneProcessOptions,
  type,
  tenants,
  admins,
  groups,
  users,
  selectedTenant,
  selectedEntity,
  status,
  systems,
  isFormModifying,
  applications,
  applicationsModels,
  serviceDeskStatus,
  // ACTIONS
  tenantActions,
  adminActions,
  groupActions,
  userActions,
  // COMMON
  setEntity,
  setType,
  setSelectedTenant,
  getCityProcesses,
  userSortBy,
  userSortOrder,
  setUsersSort,
  loggedInUser,
  sortedUsers,
  setFormIsModifying,
  requestGetApplications,
  requestGetApplicationsModels,
  addSDIntegration,
  requestDeleteSDIntegration,
  setTenantServiceDesk,
  intl,
}) => {
  useHtmlTitle(intl.messages['menu.users'])
  const [multipleEditingFlag, setMultipleEditingFlag] = useState(false)
  const [viewType, setViewType] = useState('grid')
  const [selectedUsersIdArray, setSelectedUsersIdArray] = useState([])
  const [toggledTenantPath, setToggledTenantPath] = useState({})
  const [isCreateAdmin, setIsCreateAdmin] = useState(false)

  const isActionsDisabled = useMemo(() => status === REQUEST_STATUSES.PENDING, [status])
  const userRoles = useMemo(() => get(loggedInUser, 'roles', {}), [loggedInUser])

  useEffect(() => {
    if (!userRoles.isSuperAdmin && tenants.length === 1 && !selectedTenant.id) {
      setSelectedTenant(tenants[0])
    }
  },
  [selectedTenant, setSelectedTenant, tenants, loggedInUser, userRoles.isSuperAdmin])

  useEffect(() => {
    setViewType('grid')
    setMultipleEditingFlag(false)
    setSelectedUsersIdArray([])
  }, [type])

  useEffect(() => {
    tenantActions.requestGetAllTenants()
    getCityProcesses()
    requestGetApplications()
    requestGetApplicationsModels()
  }, [
    getCityProcesses,
    tenantActions,
    requestGetApplications,
    requestGetApplicationsModels,
  ])
  const typesToElement = useMemo(() => ({
    [GROUP]: groups,
    [ADMIN]: admins,
    [TENANT]: tenants,
    [USER]: users,
  }), [
    admins,
    groups,
    tenants,
    users,
  ])

  const tenantStructure = useMemo(() => {
    if (!tenants.length) {
      return []
    }
    const newStructure = tenants.map((element) => {
      const tenantAdmins = (typesToElement[ADMIN] || {})[element.id] || []
      const tenantGroups = (typesToElement[GROUP] || {})[element.id] || []
      let newTenantGroups = []
      if (Object.keys(tenantGroups).length > 0) {
        newTenantGroups = tenantGroups.map((group) => {
          const groupUsers = group.usersAmount ? (typesToElement[USER] || {})[`${group.tenantId}-${group.id}`] || [] : []

          return {
            ...group,
            children: groupUsers,
          }
        })
      }
      return {
        ...element,
        loading: !((tenantAdmins.length > 0 || newTenantGroups.length > 0)),
        children: [
          ...element.children,
          ...tenantAdmins,
          ...newTenantGroups,
        ],
      }
    })
    return newStructure.map((tenant) => {
      const pathsForTenant = toggledTenantPath[tenant.id] || []
      const updatedTenant = { ...tenant }
      pathsForTenant.map((path) => {
        set(updatedTenant, `${path}toggled.`, true)
        return path
      })
      return updatedTenant
    })
  },
  [
    tenants,
    typesToElement,
    toggledTenantPath,
  ])

  useEffect(() => {
    if (Object.keys(selectedTenant).length > 0 && selectedTenant.children) {
      const operatedChild = selectedTenant.children
        .filter((child) => child.id === GROUP || child.id === ADMIN)
      const tenantAdmins = (typesToElement[ADMIN] || {})[selectedTenant.id] || []
      const tenantGroups = (typesToElement[GROUP] || {})[selectedTenant.id] || []
      const selectedTenantGroup = (selectedTenant.children || []).filter(
        (tenantChildren) => tenantChildren.type === GROUP
          && tenantChildren.id !== GROUP
          && tenantChildren.children.some((groupElement) => groupElement.id === USER),
      )[0] || {}
      const newUserInGroup = (selectedTenantGroup.children || []).filter(
        (user) => user.type === USER && user.id === USER,
      )[0] || {}
      let newTenantGroups = []
      if (Object.keys(tenantGroups).length > 0) {
        newTenantGroups = tenantGroups.map((group) => {
          const groupUsers = (typesToElement[USER] || {})[`${group.tenantId}-${group.id}`] || []
          if (
            newUserInGroup.groupId === group.id
            && newUserInGroup.tenantId === group.tenantId
            && newUserInGroup
          ) {
            return {
              ...group,
              loading: false,
              children: [
                newUserInGroup,
                ...groupUsers,
              ],
            }
          }
          return {
            ...group,
            loading: false,
            children: [
              ...groupUsers,
            ],
          }
        })
      }
      const tenantChildren = [
        ...operatedChild,
        ...tenantAdmins,
        ...newTenantGroups,
      ]
      const newChildren = tenantChildren.length > 0 ? tenantChildren : [{ isFake: true }]
      const newSelectedTenant = {
        ...selectedTenant,
        children: [
          ...selectedTenant.usersAmount > 0
            ? newChildren
            : [],
        ],
      }
      const newSelectedTenantWithSetPath = { ...newSelectedTenant }
      const pathsForTenant = toggledTenantPath[newSelectedTenant.id] || []
      pathsForTenant.map((path) => {
        set(newSelectedTenantWithSetPath, `${path}toggled.`, true)
        return path
      })
      if (!isEqual(newSelectedTenantWithSetPath, selectedTenant)) {
        setSelectedTenant(newSelectedTenantWithSetPath)
      }
    }
  },
  [
    toggledTenantPath,
    typesToElement,
    setSelectedTenant,
    selectedTenant,
    selectedEntity.id,
    selectedUsersIdArray,
  ])

  const [search, setSearch] = useState('')
  const [editMode, setEditMode] = useState(false)
  const [isConfirm, setIsConfirm] = useState(false)
  const [userImageUrl, setUserImageUrl] = useState('')
  const handleChange = useCallback((value) => {
    setSearch(value)
  }, [setSearch])

  const resetSearch = useCallback(() => {
    setSearch('')
  }, [setSearch])

  const closeForm = useCallback(() => {
    setIsCreateAdmin(false)
    setFormIsModifying(false)
    setType(null)
    setEditMode(false)
    if (!(type === ADMIN || type === USER)) {
      setSelectedTenant({})
    }
    setEntity({})
  }, [setFormIsModifying, setType, type, setEntity, setSelectedTenant])

  const renderContent = useMemo(() => {
    if (type === TENANT) {
      return (
        <CreateTenant
          key={selectedTenant}
          selectedTenant={selectedTenant}
          closeForm={closeForm}
          zoneProcessOptions={zoneProcessOptions}
          submitTenantForm={tenantActions.requestCreateTenant}
          setSelectedTenant={setSelectedTenant}
          userRole={userRoles}
          actionsDisabled={isActionsDisabled}
          systems={systems}
          applications={applications}
          applicationsModels={applicationsModels}
          setFormIsModifying={setFormIsModifying}
          isFormModifying={isFormModifying}
          serviceDeskStatus={serviceDeskStatus}
          addSDIntegration={addSDIntegration}
          requestDeleteSDIntegration={requestDeleteSDIntegration}
          setTenantServiceDesk={setTenantServiceDesk}
        />
      )
    }
    if (type === TENANT_EDIT) {
      return (
        <CreateTenant
          editMode
          key={selectedTenant.id}
          selectedTenant={selectedTenant}
          closeForm={closeForm}
          selectedEntity={selectedEntity}
          zoneProcessOptions={zoneProcessOptions}
          submitTenantForm={tenantActions.requestUpdateTenant}
          deleteTenant={tenantActions.requestDeleteTenant}
          setSelectedTenant={setSelectedTenant}
          userRole={userRoles}
          actionsDisabled={isActionsDisabled}
          systems={systems}
          applications={applications}
          applicationsModels={applicationsModels}
          setFormIsModifying={setFormIsModifying}
          isFormModifying={isFormModifying}
          serviceDeskStatus={serviceDeskStatus}
          addSDIntegration={addSDIntegration}
          requestDeleteSDIntegration={requestDeleteSDIntegration}
          setTenantServiceDesk={setTenantServiceDesk}
        />
      )
    }
    if (type === ADMIN || type === USER) {
      return (
        <CreateAdmin
          type={type}
          closeForm={closeForm}
          setUserImageUrl={setUserImageUrl}
          requestCreateUser={userActions.requestCreateUser}
          requestCreateAdminTenant={adminActions.requestCreateAdminTenant}
          selectedTenant={selectedTenant}
          selectedAdmin={selectedEntity}
          setSelectedTenant={setSelectedTenant}
          requestDeleteAdminTenant={adminActions.requestDeleteAdminTenant}
          updateAdmin={userActions.requestUpdateUser}
          isConfirm={isConfirm}
          requestGetTenant={tenantActions.requestGetTenant}
          requestDeleteUser={userActions.requestDeleteUser}
          requestGetTenantGroup={groupActions.requestGetTenantGroup}
          actionsDisabled={isActionsDisabled}
          userRole={userRoles}
          setFormIsModifying={setFormIsModifying}
          isFormModifying={isFormModifying}
          isCreateAdmin = {isCreateAdmin}
          setType={setType}
          requestResendVerificationEmail={userActions.requestResendVerificationEmail}
        />
      )
    }
    if (type === ADMIN_EDIT) {
      return (
        <EditAdmin
          key={JSON.stringify(selectedEntity)}
          editMode={editMode}
          setEditMode={setEditMode}
          type={type}
          setType={setType}
          closeForm={closeForm}
          selectedTenant={selectedTenant}
          selectedAdmin={selectedEntity}
          userImageUrl={userImageUrl}
          setUserImageUrl={setUserImageUrl}
          formData={selectedEntity}
          updateAdmin={userActions.requestUpdateUser}
          deleteAdmin={adminActions.requestDeleteAdminTenant}
          userRole={userRoles}
          actionsDisabled={isActionsDisabled}
          setFormIsModifying={setFormIsModifying}
          isFormModifying={isFormModifying}
        />
      )
    }
    if (type === USER_EDIT_FROM_ADMIN) {
      return (
        <EditUserFromAdmin
          key={JSON.stringify(selectedEntity)}
          groups={groups[selectedTenant.id]}
          selectedUser={selectedEntity}
          requestDeleteUser={userActions.requestDeleteUser}
          requestUpdateUserFromAdmin={userActions.requestUpdateUserFromAdmin}
          actionsDisabled={isActionsDisabled}
          userRole={userRoles}
          setFormIsModifying={setFormIsModifying}
          isFormModifying={isFormModifying}
          closeForm={closeForm}
        />
      )
    }
    if (type === USER_EDIT) {
      return (
        <EditUser
          key={JSON.stringify(selectedEntity)}
          editMode={editMode}
          setEditMode={setEditMode}
          type={type}
          setType={setType}
          closeForm={closeForm}
          selectedTenant={selectedTenant}
          selectedAdmin={selectedEntity}
          userImageUrl={userImageUrl}
          setUserImageUrl={setUserImageUrl}
          formData={selectedEntity}
          updateUser={userActions.requestUpdateUser}
          actionsDisabled={isActionsDisabled}
          setFormIsModifying={setFormIsModifying}
          isFormModifying={isFormModifying}
        />
      )
    }
    if (type === GROUPS) {
      return (
        <CreateGroupOfUsers
          key={selectedTenant.id}
          selectedTenant={selectedTenant}
          closeForm={closeForm}
          zoneProcessOptions={zoneProcessOptions}
          submitFormGroup={groupActions.requestCreateTenantGroup}
          setSelectedTenant={setSelectedTenant}
          actionsDisabled={isActionsDisabled}
          userRole={userRoles}
          systems={systems}
          setFormIsModifying={setFormIsModifying}
          isFormModifying={isFormModifying}
          applications={applications}
          applicationsModels={applicationsModels}
          serviceDeskStatus={serviceDeskStatus}
          addSDIntegration={addSDIntegration}
          requestDeleteSDIntegration={requestDeleteSDIntegration}
          setTenantServiceDesk={setTenantServiceDesk}
        />
      )
    }
    if (type === GROUPS_EDIT && selectedUsersIdArray.length === 0) {
      return (
        <CreateGroupOfUsers
          edit
          key={selectedTenant.id}
          selectedTenant={selectedTenant}
          selectedUsersGroup={selectedEntity}
          closeForm={closeForm}
          zoneProcessOptions={zoneProcessOptions}
          submitFormGroup={groupActions.requestUpdateTenantGroup}
          deleteGroup={groupActions.requestDeleteTenantGroup}
          setSelectedTenant={setSelectedTenant}
          actionsDisabled={isActionsDisabled}
          userRole={userRoles}
          systems={systems}
          setFormIsModifying={setFormIsModifying}
          isFormModifying={isFormModifying}
          applications={applications}
          applicationsModels={applicationsModels}
          serviceDeskStatus={serviceDeskStatus}
          addSDIntegration={addSDIntegration}
          requestDeleteSDIntegration={requestDeleteSDIntegration}
          setTenantServiceDesk={setTenantServiceDesk}
        />
      )
    }
    if (type === GROUPS_EDIT && selectedUsersIdArray.length > 0) {
      return (
        <MultipleUserEdit
          closeForm={closeForm}
          users={users}
          selectedTenant={selectedTenant}
          selectedUsersGroup={selectedEntity}
          selectedUsersIdArray={selectedUsersIdArray}
          setSelectedUsersIdArray={setSelectedUsersIdArray}
          onSubmit={userActions.requestUsersMultipleEditing}
          onDelete={userActions.requestUsersMultipleDelete}
          actionsDisabled={isActionsDisabled}
          updateTableSorting={setUsersSort}
          setFormIsModifying={setFormIsModifying}
          isFormModifying={isFormModifying}
          sortedUsers={sortedUsers}
        />
      )
    }
    return null
  },
  [
    isConfirm,
    selectedUsersIdArray,
    isActionsDisabled,
    type,
    selectedTenant,
    closeForm,
    zoneProcessOptions,
    setSelectedTenant,
    selectedEntity,
    editMode,
    setType,
    userImageUrl,
    userRoles,
    groups,
    users,
    tenantActions,
    adminActions,
    groupActions,
    userActions,
    setUsersSort,
    // integrationOptions,
    systems,
    applications,
    applicationsModels,
    isFormModifying,
    setFormIsModifying,
    serviceDeskStatus,
    addSDIntegration,
    requestDeleteSDIntegration,
    setTenantServiceDesk,
    isCreateAdmin,
    sortedUsers
  ])

  const selectTenantByID = useCallback((id) => tenantStructure
    .filter((element) => element.id === id)[0], [tenantStructure])

  const selectUser = useCallback((node, event) => () => {
    if (event === 'toggle') {
      const tenantId = node.tenantId || node.id
      const nodePath = node.path
      const nodeToggledValue = node.toggled || false
      const newTenantToggledNode = (toggledTenantPath[tenantId] || []).filter(
        (element) => element !== nodePath,
      )
      if (!nodeToggledValue) {
        newTenantToggledNode.push(nodePath)
      }
      setToggledTenantPath({
        ...toggledTenantPath,
        [tenantId]: newTenantToggledNode,
      })
      if (node.type === COMPANY && !node.toggled) {
        adminActions.requestGetAllAdminTenants(node.id)
        if (!userRoles.isSuperAdmin) {
          groupActions.requestGetTenantGroups(node.id)
        }
      }
      if (node.type === GROUP && !node.toggled) {
        userActions.requestGetAllUsers({ tenantId: node.tenantId, groupId: node.id })
      }
    }
    if (event === 'click') {
      if (node.type === COMPANY) {
        setType(TENANT_EDIT)
        setEntity({})
        setSelectedTenant(node)
        if (!userRoles.isSuperAdmin) {
          groupActions.requestGetTenantGroups(node.id)
        }
        // adminActions.requestGetAllAdminTenants(node.id)
        tenantActions.requestGetTenant({ id: node.id, setTenant: true })
        return null
      }
      if (node.type === ADMIN && node.id === ADMIN) {
        const tenantByAdminId = selectTenantByID(node.tenantId)
        const newChildren = (tenantByAdminId.children || [])
          .filter((child) => child.type && child.id)
        const newTenantByAdminId = {
          ...tenantByAdminId,
          children: [
            {
              ...node,
            },
            ...newChildren,
          ],
        }
        setSelectedTenant(newTenantByAdminId)
        return null
      }
      if (node.type === GROUP && node.id !== GROUP) {
        setEntity({})
        const tenantByUsersGroupId = selectTenantByID(node.tenantId)
        setSelectedTenant(tenantByUsersGroupId)
        tenantActions.requestGetTenant({ id: node.tenantId, onlyUpdateSelectedTenant: true })
        groupActions.requestGetTenantGroup({
          tenantId: node.tenantId,
          groupId: node.id,
        })
        userActions.requestGetAllUsers({ tenantId: node.tenantId, groupId: node.id })
        return null
      }
      if (node.type === ADMIN) {
        setIsConfirm(true)
        setEntity({})
        const tenantByUsersGroupId = selectTenantByID(node.tenantId)
        setSelectedTenant(tenantByUsersGroupId)
        adminActions.requestGetAdminTenant({
          tenantId: node.tenantId,
          id: node.id,
        })
        return null
      }
      if (node.type === USER) {
        setEntity({})
        setIsConfirm(true)
        userActions.requestGetUser({
          tenantId: node.tenantId,
          id: node.id,
        })
        return null
      }
    }
    return null
  },
  [
    toggledTenantPath,
    adminActions,
    userRoles.isSuperAdmin,
    groupActions, userActions,
    setType,
    setEntity,
    setSelectedTenant,
    tenantActions,
    selectTenantByID,
  ])

  const createTenantHandler = useCallback(() => {
    setEditMode(false)
    const newSelectedTenant = {
      id: COMPANY,
      type: COMPANY,
      name: 'Новая огранизация',
      children: [],
    }
    setSelectedTenant(newSelectedTenant)
    setType(TENANT)
  }, [
    setType,
    setEditMode,
    setSelectedTenant,
  ])

  const createGroupHandler = useCallback(() => {
    const newSelectedTenant = selectTenantByID(selectedTenant.id)
    if (!newSelectedTenant) {
      return null
    }
    const newTenantChildren = (newSelectedTenant.children || []).filter(
      (child) => child.id !== GROUP && child.type && child.id,
    )
    const newSelectedTenantForUsersGroup = {
      ...selectedTenant,
      toggled: true,
      children: [
        {
          id: GROUP,
          type: GROUP,
          name: 'Новая группа',
        },
        ...newTenantChildren,
      ],
    }
    tenantActions.requestGetTenant({
      id: newSelectedTenantForUsersGroup.id,
      onlyUpdateSelectedTenant: true,
    })
    setSelectedTenant(newSelectedTenantForUsersGroup)
    setEntity({})
    setType(GROUPS)
    return null
  }, [selectTenantByID, selectedTenant, tenantActions, setSelectedTenant, setEntity, setType])

  const prepareSelectedTenantToNewAdmin = useCallback(() => {
    setIsCreateAdmin(true)
    const newSelectedTenant = selectTenantByID(selectedTenant.id)
    const newTenantChildren = newSelectedTenant.children.filter(
      (child) => child.id !== ADMIN && child.type && child.name,
    )
    const newSelectedTenantForNewAdmin = {
      ...selectedTenant,
      toggled: true,
      children: [
        ...newTenantChildren,
      ],
    }
    setSelectedTenant(newSelectedTenantForNewAdmin)
    setEntity({})
    setIsConfirm(false)
    setType(ADMIN)
  }, [
    selectTenantByID,
    setSelectedTenant,
    setEntity,
    selectedTenant,
    setIsConfirm,
    setType,
  ])

  const prepareSelectedTenantToNewUser = useCallback(() => {
    const newSelectedTenant = selectTenantByID(selectedTenant.id)
    const newTenantChildren = newSelectedTenant.children.filter(
      (child) => child.type !== USER && child.type !== ADMIN && child.type && child.name,
    )
    const selectedGroupIndex = newTenantChildren
      .findIndex((entity) => entity.id === selectedEntity.id)
    // const newSelectedGroup = {
    //   ...newTenantChildren[selectedGroupIndex],
    //   type: GROUP,
    //   toggled: true,
    //   children: [
    //     ...newTenantChildren[selectedGroupIndex].children,
    //   ],
    // }
    newTenantChildren[selectedGroupIndex] = {
      ...newTenantChildren[selectedGroupIndex],
      type: GROUP,
      toggled: true,
      children: [
        ...newTenantChildren[selectedGroupIndex].children,
      ],
    }
    const newSelectedTenantForNewAdmin = {
      ...selectedTenant,
      toggled: true,
      children: [
        ...newTenantChildren,
      ],
    }
    let pathIndex
    selectedTenant.children.filter((element, index) => {
      if (element.id === selectedEntity.id) {
        pathIndex = index
      }
      return false
    })
    if (pathIndex) {
      setToggledTenantPath({
        ...toggledTenantPath,
        [selectedTenant.id]: [
          ...toggledTenantPath[selectedTenant.id] || [],
          `children[${pathIndex}].`,
        ],
      })
    }
    setSelectedTenant(newSelectedTenantForNewAdmin)
    setIsConfirm(false)
    setType(USER)
  }, [selectTenantByID, selectedTenant, selectedEntity.id, toggledTenantPath, setSelectedTenant, setType])

  const renderSidebar = useMemo(
    () => {
      const newTenants = tenantStructure.map((tenant) => {
        if (tenant.id === selectedTenant.id) {
          const newChildren = tenant.children
            .filter((element) => (element.type && element.name) || element.isFake)
          return {
            ...selectedTenant,
            ...tenant,
            loading: false,
            children: [
              ...newChildren,
            ],
          }
        }
        return tenant
      })
      return (
        <PageSidebar
          userTree
          title={<Lang id="usersPage.titles.users" />}
          content={(
            <>
              {newTenants.map(((tenant) => (
                <ViewTree
                  key={JSON.stringify(tenant)}
                  treeData={tenant}
                  searchQuery={search}
                  onSelect={selectUser}
                />
              )))}
              {(type === TENANT) && Object.keys(selectedTenant).length && (
                <ViewTree
                  key={JSON.stringify(selectedTenant)}
                  treeData={selectedTenant}
                  searchQuery={search}
                />
              )}
            </>
            )}
          headerContent={(
            <Lang id="usersPage.titles.search">
              {(placeholder) => (
                <TextControl
                  dark
                  placeholder={placeholder}
                  name="search"
                  icon={UsersIcons.MagnifierIcon}
                  onChange={handleChange}
                  value={search}
                  resetButton
                  resetField={resetSearch}
                />
              )}
            </Lang>
          )}
        />
      )
    },
    [tenantStructure, type, selectedTenant, search, selectUser, handleChange, resetSearch],
  )

  const renderAdminBar = useMemo(() => (
    <AdminsBarContainer>
      {!isFormModifying && (
        <AdminsSortPanel
          type={type}
          startCreateAdmin={prepareSelectedTenantToNewAdmin}
          startCreateUser={prepareSelectedTenantToNewUser}
          userRole={userRoles}
          createGroupHandler={createGroupHandler}
          createTenantHandler={createTenantHandler}
          setFormIsModifying={setFormIsModifying}
          disabledAdd={selectedEntity.type === USERS_GROUP && selectedEntity.blocked}
        />
      )}
      <UsersPresentation
        type={type}
        userRole={userRoles}
        loggedInUser={loggedInUser}
        selectedTenant={selectedTenant}
        selectedEntity={selectedEntity}
        viewType={viewType}
        setViewType={setViewType}
        sortType={userSortBy}
        sortValue={userSortOrder}
        updateTableSorting={setUsersSort}
        groups={groups}
        admins={admins}
        users={users}
        selectedUsersIdArray={selectedUsersIdArray}
        setSelectedUsersIdArray={setSelectedUsersIdArray}
        multipleEditingFlag={multipleEditingFlag}
        sortedUsers={sortedUsers}
        selectTenantByID={selectTenantByID}
        setSelectedTenant={setSelectedTenant}
        setEntity={setEntity}
        setIsConfirm={setIsConfirm}
        userActions={userActions}
        adminActions={adminActions}
      />
    </AdminsBarContainer>
  ),
  [
    adminActions,
    selectTenantByID,
    setSelectedTenant,
    setEntity,
    setIsConfirm,
    userActions,
    type,
    createTenantHandler,
    createGroupHandler,
    prepareSelectedTenantToNewAdmin,
    prepareSelectedTenantToNewUser,
    multipleEditingFlag,
    viewType,
    loggedInUser,
    selectedTenant,
    selectedEntity,
    groups,
    admins,
    users,
    selectedUsersIdArray,
    sortedUsers,
    userSortBy,
    userSortOrder,
    setUsersSort,
    userRoles,
    setFormIsModifying,
    isFormModifying,
  ])

  return (
    <Main>
      {!isFormModifying && renderSidebar}
      <ContentWrapper>
        {renderContent}
      </ContentWrapper>
      {renderAdminBar}
    </Main>
  )
}

Users.propTypes = {
  type: pt.string,
  isFormModifying: pt.bool,
  setFormIsModifying: pt.func,
  sortedUsers: pt.arrayOf(pt.object),
  admins: pt.arrayOf(pt.object),
  groups: pt.arrayOf(pt.object),
  users: pt.arrayOf(pt.object),
  loggedInUser: pt.objectOf(pt.object),
  systems: pt.objectOf(pt.object),
  status: pt.bool.isRequired,
  setType: pt.func,
  getCityProcesses: pt.func,
  setEntity: pt.func,
  setSelectedTenant: pt.func,
  zoneProcessOptions: pt.arrayOf(pt.object),
  tenants: pt.arrayOf(pt.object),
  selectedTenant: pt.objectOf(pt.object),
  selectedEntity: pt.objectOf(pt.object),
  userRole: pt.shape({
    isSuperAdmin: pt.bool,
    isAdmin: pt.bool,
  }).isRequired,
  tenantActions: pt.shape({
    requestCreateTenant: pt.func,
    requestDeleteTenant: pt.func,
    requestGetAllTenants: pt.func,
    requestGetTenant: pt.func,
    requestUpdateTenant: pt.func,
  }).isRequired,
  adminActions: pt.shape({
    requestCreateAdminTenant: pt.func,
    requestDeleteAdminTenant: pt.func,
    requestGetAdminTenant: pt.func,
    requestGetAllAdminTenants: pt.func,
  }).isRequired,
  groupActions: pt.shape({
    requestCreateTenantGroup: pt.func,
    requestDeleteTenantGroup: pt.func,
    requestGetTenantGroup: pt.func,
    requestGetTenantGroups: pt.func,
    requestUpdateTenantGroup: pt.func,
  }).isRequired,
  userActions: pt.shape({
    requestCreateUser: pt.func,
    requestUpdateUser: pt.func,
    requestUpdateUserFromAdmin: pt.func,
    requestGetAllUsers: pt.func,
    requestGetUser: pt.func,
    requestDeleteUser: pt.func,
    requestUsersMultipleEditing: pt.func,
  }).isRequired,
  userSortBy: pt.string,
  userSortOrder: pt.string,
  setUsersSort: pt.func,
}
Users.defaultProps = {
  type: null,
  admins: [],
  groups: [],
  users: [],
  systems: {},
  sortedUsers: [],
  setType: noop,
  getCityProcesses: noop,
  setEntity: noop,
  setSelectedTenant: noop,
  zoneProcessOptions: [],
  tenants: [],
  selectedTenant: {},
  selectedEntity: {},
  loggedInUser: {},
  userSortBy: '',
  userSortOrder: '',
  setUsersSort: noop,
  isFormModifying: false,
  setFormIsModifying: noop,
}

export default React.memo(Users)
