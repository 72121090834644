import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const LineArtIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)
  return (
    <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
      <path className="svgFill" fill={fill} fill-rule="evenodd" clip-rule="evenodd" d="M18 9A9 9 0 1 1 0 9a9 9 0 0 1 18 0ZM8 5V4h2v1H8Zm2 9V6H8v8h2Zm6-6h-2v4h2V8ZM2 8h2v4H2V8Zm2-2H2v1h2V6Zm12 0h-2v1h2V6ZM5 9h2V8H5v1Zm8 0h-2V8h2v1Z"/>
    </svg>

  
  )
}

LineArtIcon.propTypes = {
  onClick: pt.func,
  color: pt.string,
}
LineArtIcon.defaultProps = {
  onClick: noop,
  color: null,
}

export default memoWithName(LineArtIcon)
