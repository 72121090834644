import { MARKS_COLORS } from '@/constants/styles/mapsMarks'

export const isOneTypeInCluster = (statistic) => Object
  .keys(statistic)
  .filter((element) => statistic[element] > 0).length <= 1

export const getPieChartData = (statistic) => Object
  .keys(statistic)
  .reduce((accumulator, element) => {
    if (statistic[element] > 0) {
      accumulator.push({
        // title: element,
        value: statistic[element],
        color: MARKS_COLORS[element],
      })
    }
    return accumulator
  }, [])
