import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  GET_PASSPORT_AND_CARD_DATA,
  SET_OPERATED_ELEMENT,
  errorGetOperatedElement,
  successGetPassportAndCardData,
} from '@/store/actions/passportization'
import {
  getOperatedElement,
} from '@/store/selectors/passportization'

import {
  generateGetObjectCard,
  GET_PASSPORT_FILES_URL,
} from '@/constants/apiRoutes'
import get from 'lodash/get'
import installationPassportConfig from '@/constants/instalationPassport'
import { isAdditionalFields, isInConfig } from '@/store/sagas/installation/getCard'

function* getPassportAndCardSaga({ payload }) {
  try {
    const operatedElement = yield select(getOperatedElement)
    const { id } = operatedElement
    // if (treeNodeType && name && treeNodeType === OBJECT_ELEMENT) {
      const response = yield request({
        url: generateGetObjectCard(id),
        method: 'get',
      })
      if (response && response.data) {
        const data = { ...response.data }
        const parentElementName = { parentProjectName: data.parentName }
        const formattedData = {
          ...data,
          ...parentElementName,
          point: {
            latitude: data.point.latitude?.toFixed(6),
            longitude: data.point.longitude?.toFixed(6),
          },
          id: data.treeElementId || data.id,
          identifier: data.objectIdOuter,
        }

        const integrationType = get(data, 'integrationType', null)
        const objectType = get(data, 'installationType', null)
        const cardAndPassportConfig = get(installationPassportConfig, integrationType, [])
        const cardConfig = get(cardAndPassportConfig, `card.${objectType}`, [])
        const passportConfig = get(cardAndPassportConfig, `passport.${objectType}`, [])
        const config = [...cardConfig, ...passportConfig]

        const formattedObjectFields = (data.objectFields || []).map((element) => {
          const localName = `${element.passportBlock}.${element.fieldName}`
          if (isInConfig(localName, config) || isAdditionalFields(element.fieldName)) {
            return {
              ...element,
              fieldName: localName,
            }
          }
          return element
        })

        const filterElementFields = formattedObjectFields.filter((element) => element.fieldName.split('.')[0] !== 'ATTACHMENT')
        const attachment = formattedObjectFields.filter((element) => element.fieldName.split('.')[0] === 'ATTACHMENT')
        const photoFileId = (attachment.filter((element) => element.fieldName === 'ATTACHMENT.PHOTO_ID')[0] || {}).value || null
        const PDFFileId = (attachment.filter((element) => element.fieldName === 'ATTACHMENT.DOCUMENT_ID')[0] || {}).value || null
        const photoFileName = (attachment.filter((element) => element.fieldName === 'ATTACHMENT.PHOTO_TITLE')[0] || {}).value || null
        const PDFFileName = (attachment.filter((element) => element.fieldName === 'ATTACHMENT.DOCUMENT_TITLE')[0] || {}).value || null
        try {
          if (photoFileId) {
            const { data: photoFile } = yield request({
              url: `${GET_PASSPORT_FILES_URL}?id=${photoFileId}`,
              method: 'get',
              options: {
                responseType: 'blob',
              },
            })
            const photoFileFromBlob = new window.File([photoFile], photoFileName, { type: 'image/png' })
            filterElementFields.push({
              passportBlock: 'ATTACHMENT',
              fieldName: 'ATTACHMENT.PHOTO_TITLE',
              value: photoFileFromBlob,
            })
          }
        } catch (photoError) {
          console.log('photoError', photoError)
        }
        try {
          if (PDFFileId) {
            const { data: PDFFile } = yield request({
              url: `${GET_PASSPORT_FILES_URL}?id=${PDFFileId}`,
              method: 'get',
              options: {
                responseType: 'blob',
              },
            })
            const fileFileFromBlob = new window.File([PDFFile], PDFFileName, { type: 'application/pdf' })
            filterElementFields.push({
              passportBlock: 'ATTACHMENT',
              fieldName: 'ATTACHMENT.DOCUMENT_TITLE',
              value: fileFileFromBlob,
            })
          }
        } catch (fileError) {
          console.log('fileError', fileError)
        }
        formattedData.objectElementFieldsDto = filterElementFields
        formattedData.photoId = photoFileId
        formattedData.fileId = PDFFileId

        yield put(successGetPassportAndCardData(formattedData))
      }
    // }
  } catch (error) {
    yield put(errorGetOperatedElement(error))
    yield put(successGetPassportAndCardData({}))
  }
}

export default function* root() {
  yield takeLatest(GET_PASSPORT_AND_CARD_DATA.REQUEST, getPassportAndCardSaga)
  yield takeLatest(SET_OPERATED_ELEMENT, getPassportAndCardSaga)
}
