import React, { useMemo, useCallback } from 'react'
import noop from 'lodash/noop'
import pt from 'prop-types'
import LiveTime from '@/components/icons/livetime'
import SelectControl from '@/components/controls/SelectControl'
import CoreIcons from '@/components/icons/core'
import { FormattedMessage as Lang } from 'react-intl'
import PortalTooltip from '@/components/blocks/PortalTooltip'

import {
  Container,
  ItemContainer,
  SelectContainer,
  Title,
  SyncContainer,
  Info,
} from './styles'

export const DEFAULT_PAGES_COUNT = 5

const TableFooter = ({
  page,
  resultCount,
  perPage,
  setPage,
  setResultPerPage,
  onUpdateData,
  notToShowFooterPerPage,
}) => {
  const pages = useMemo(() => Math.ceil(resultCount / perPage), [resultCount, perPage])
  const setPageHandler = (pageNumber) => () => {
    setPage(pageNumber ? pageNumber : 1)
  }
  
  const createMiddleNavWhitLessThanLimit = useCallback(() => {
    const navArray = []
    for (let i = 0; i < pages; i += 1) {
      navArray.push({
        value: i + 1,
        name: i + 1,
      })
    }
    return navArray
  }, [pages])

  const createMiddleNavInLimit = useCallback(() => {
    const navArray = []
    let i = page - 2
    for (i; i < page + 1; i += 1) {
      navArray.push({
        value: i + 1,
        name: i + 1,
      })
    }
    navArray.unshift({
      value: i - 3,
      name: '...',
    })
    navArray.push({
      value: i + 1,
      name: '...',
    })
    return navArray
  }, [page])

  const createMiddleNavInLeftLimit = useCallback(() => {
    const navArray = []
    let i = pages - 3
    for (i; i < pages; i += 1) {
      navArray.push({
        value: i + 1,
        name: i + 1,
      })
    }
    navArray.unshift({
      value: i - 4,
      name: '...',
    })
    return navArray
  }, [pages])

  const createMiddleNavInRightLimit = () => {
    const navArray = []
    let i = 0
    for (i; i < 3; i += 1) {
      navArray.push({
        value: i + 1,
        name: i + 1,
      })
    }
    navArray.push({
      value: i + 1,
      name: '...',
    })
    return navArray
  }

  const middleNav = useMemo(() => {
    if (pages <= 3) {
      return createMiddleNavWhitLessThanLimit()
    }
    if (page >= 3 && page + 2 <= pages) {
      return createMiddleNavInLimit()
    }
    if (page <= pages && page > pages - 3) {
      return createMiddleNavInLeftLimit()
    }
    if (page > 0 && page < 3) {
      return createMiddleNavInRightLimit()
    }
    return []
  },
  [
    pages,
    page,
    createMiddleNavWhitLessThanLimit,
    createMiddleNavInLimit,
    createMiddleNavInLeftLimit,
  ])

  const navConfig = useMemo(() => {
    const firsOption = [
      {
        value: 1,
        isConstructive: true,
        icon: LiveTime.PrevIcon,
        tooltip: (<Lang id="tooltip.first" />),
      },
    ]
    const lastOptions = [
      {
        value: pages,
        isConstructive: true,
        icon: LiveTime.NextIcon,
        tooltip: (<Lang id="tooltip.last" />),
      },
    ]
    return [...firsOption, ...middleNav, ...lastOptions]
  }, [pages, middleNav])

  const selectOptions = [
    {
      value: 10,
      title: 10,
    },
    {
      value: 20,
      title: 20,
    },
    {
      value: 50,
      title: 50,
    },
    {
      value: 100,
      title: 100,
    },
  ]
  return (
    <Container>
      {!notToShowFooterPerPage && (
        <SelectContainer>
          <Title selectControl>Показывать по</Title>
          <SelectControl
            options={selectOptions}
            value={perPage}
            onChange={setResultPerPage}
            autoWidth
            top
          />
        </SelectContainer>
      )}
      {navConfig.map((element) => {
        const Icon = element.icon || null
        const disabled = element.isConstructive && element.value === page
        if (disabled) {
          return (<ItemContainer disabled />)
        }
        return (
          <PortalTooltip
            title={element.tooltip || null}
            renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
              <ItemContainer
                ref={wrapperRef}
                onMouseEnter={onMouseEnterHandler}
                onMouseLeave={onMouseLeaveHandler}
                onClick={setPageHandler(element.value)}
                isActive={element.value === page && !element.isConstructive}
              >
                {element.icon && !disabled && (<Icon />)}
                {element.name}
              </ItemContainer>
            )}
          />
        )
      })}
      <Info>
        <Title>{`Записи ${(page - 1) * perPage + 1} - ${page * perPage} из ${resultCount}`}</Title>
      </Info>
      <PortalTooltip
        title={(<Lang id="tooltip.refresh" />)}
        renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
          <SyncContainer
            ref={wrapperRef}
            onMouseEnter={onMouseEnterHandler}
            onMouseLeave={onMouseLeaveHandler}
            onClick={onUpdateData}
          >
            <CoreIcons.SyncIcon />
          </SyncContainer>
        )}
      />
    </Container>
  )
}

TableFooter.propTypes = {
  page: pt.number,
  perPage: pt.number,
  resultCount: pt.number,
  setResultPerPage: pt.func,
  setPage: pt.func,
  onUpdateData: pt.func,
}

TableFooter.defaultProps = {
  page: 2,
  perPage: 50,
  resultCount: 20,
  setResultPerPage: noop,
  setPage: noop,
  onUpdateData: noop,
}

export default TableFooter
