import React, {
  useMemo, useCallback
} from 'react'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import pt from 'prop-types'
import { Formik, Form } from 'formik'
import { FormattedMessage as Lang } from 'react-intl'
import LabelWithIcon from '@/components/blocks/LabelWithIcon'
import FileControllField from '@/components/fields/FileControllField'
import SelectField from '@/components/fields/SelectField'
import CoreIcons from '@/components/icons/core'
import {
  deskFormValidation,
} from '@/constants/validationFields'
import {
  CLIENT_ID,
  SECRET_ID,
  SD_NAME,
  URL,
  FILE,
  SERVICE_DESK_INTEGRATIONS,
} from '@/constants/forms/integration'
import { SERVICE_DESK } from '@/constants/applications'

import {
  InputAndLabelContainer,
  ButtonContainer,
  MessageTitle,
  FormWrapper,
  CustomButton,
  StyledTextField,
} from './styles'

const DescForm = ({
  onSetSettings,
  addIntegration,
  handleCloseWidget,
  widgetId,
  widgetType,
  requestDeleteIntegration,
  currentServiceDeskStatus,
  isSuperAdmin,
  userApplications,
  setUserServiceDesk,
  serviceDeskStatus,
  isCityPortal,
}) => {
  const form = React.createRef()
  const userServiceDesk = useMemo(() => (
    userApplications.find((application) => application.code === SERVICE_DESK)
  ), [userApplications])

  const serviceDeskIntegrations = useMemo(() => {
    const localWidgetIntegrationId = (serviceDeskStatus.filter((el) => widgetId === el.widgetId)[0] || {}).id || null
    const integratedSDIds = serviceDeskStatus.reduce((accumulator, el) => {
      if (localWidgetIntegrationId === el.id) {
        return accumulator
      }
      return [...accumulator, el.id]
    }, [])
    if (userServiceDesk) {
      return JSON.parse(userServiceDesk.settings)
        .map((serviceDesk) => ({
          ...serviceDesk,
          value: serviceDesk.id,
          title: serviceDesk.name,
        }))
        .filter((serviceDesk) => localWidgetIntegrationId === serviceDesk.id || !integratedSDIds.includes(serviceDesk.value))
    }
    return []
  }, [userServiceDesk, serviceDeskStatus, widgetId])

  const onSubmit = (values) => {
    if (currentServiceDeskStatus) {
      requestDeleteIntegration({ widgetId })
      onSetSettings(widgetId, {}, widgetType)
    } else {
      addIntegration({ id: widgetId, values, widgetType })
    }
    handleCloseWidget()
  }

  const renderButton = useCallback((values, errors) => {
    if (!currentServiceDeskStatus && !isEmpty(values) && isEmpty(errors)) {
      return (
        <CustomButton>
          <Lang id="widgets.connect" />
        </CustomButton>
      )
    }
    if (currentServiceDeskStatus) {
      return (
        <CustomButton disconnect>
          <Lang id="widgets.disconnect" />
        </CustomButton>
      )
    }
    return null
  }, [currentServiceDeskStatus])

  const handleOnChangeServiceDesk = useCallback((name, value) => {
    const selectedServiceDesk = JSON.parse(userServiceDesk.settings).find(
      (serviceDesk) => serviceDesk.id === value,
    )
    if (currentServiceDeskStatus) {
      const newServiceDeskStatus = serviceDeskStatus.filter(
        (status) => widgetId !== status.widgetId,
      )
      setUserServiceDesk([
        ...newServiceDeskStatus,
        {
          ...selectedServiceDesk,
          widgetId,
        },
      ])
    } else {
      setUserServiceDesk([
        ...serviceDeskStatus,
        {
          ...selectedServiceDesk,
          widgetId,
        },
      ])
    }
  }, [currentServiceDeskStatus, serviceDeskStatus, setUserServiceDesk, userServiceDesk, widgetId])

  const renderForm = useCallback((handleSubmit, touched, errors, isSubmitting, values) => {
    if (isSuperAdmin || isCityPortal) {
      return (
        <>
          <Form onSubmit={handleSubmit} id="integration-form" name="integration-form">
            <MessageTitle>
              {isCityPortal ? 'Интеграция с порталом:' : <Lang id="widgets.intergationWithService" />}
            </MessageTitle>
            <InputAndLabelContainer>
              <LabelWithIcon
                isError={(touched[SD_NAME] && errors[SD_NAME])}
                title={<Lang id="widgets.name" />}
              />
              <StyledTextField
                error={(touched[SD_NAME] && errors[SD_NAME])}
                name={SD_NAME}
                resetButton
                fieldProps={{
                  autoComplete: 'off',
                  disabled: isSubmitting || currentServiceDeskStatus,
                }}
              />
            </InputAndLabelContainer>
            <InputAndLabelContainer>
              <LabelWithIcon
                isError={(touched[FILE] && errors[FILE])}
                title={<Lang id="widgets.fileWithSettings" />}
              />
              <FileControllField
                error={(touched[FILE] && errors[FILE])}
                name={FILE}
                type="file"
                accept="application/json"
                styleType="secondary"
                placeholder="Загрузить"
                icon={(<CoreIcons.FileUploadIcon />)}
                textInfo="Тип файла: JSON Макс. размер: 1 мб."
              />
            </InputAndLabelContainer>
            <InputAndLabelContainer>
              <LabelWithIcon
                isError={(touched[URL] && errors[URL])}
                title={<Lang id="widgets.url" />}
              />
              <StyledTextField
                error={(touched[URL] && errors[URL])}
                name={URL}
                resetButton
                fieldProps={{
                  autoComplete: 'off',
                  disabled: isSubmitting || currentServiceDeskStatus,
                }}
              />
            </InputAndLabelContainer>
            <InputAndLabelContainer>
              <LabelWithIcon
                isError={(touched[CLIENT_ID] && errors[CLIENT_ID])}
                title={<Lang id="widgets.clientID" />}
              />
              <StyledTextField
                error={(touched[CLIENT_ID] && errors[CLIENT_ID])}
                name={CLIENT_ID}
                resetButton
                fieldProps={{
                  autoComplete: 'off',
                  disabled: isSubmitting || currentServiceDeskStatus,
                }}
              />
            </InputAndLabelContainer>
            <InputAndLabelContainer>
              <LabelWithIcon
                isError={(touched[SECRET_ID] && errors[SECRET_ID])}
                title={<Lang id="widgets.secretID" />}
              />
              <StyledTextField
                error={(!touched[SECRET_ID] && errors[SECRET_ID])}
                name={SECRET_ID}
                resetButton
                fieldProps={{
                  autoComplete: 'off',
                  disabled: isSubmitting || currentServiceDeskStatus,
                }}
              />
            </InputAndLabelContainer>
            <ButtonContainer>
              {renderButton(values, errors)}
            </ButtonContainer>
          </Form>
        </>
      )
    }
    return (
      <>
        <MessageTitle>
          Экземпляр приложения
        </MessageTitle>
        <SelectField
          name={SERVICE_DESK_INTEGRATIONS}
          options={serviceDeskIntegrations}
          withSearch
          onAfterChange={handleOnChangeServiceDesk}
          placeholder="Не выбран"
        />
      </>
    )
  },
  [
    handleOnChangeServiceDesk,
    isSuperAdmin,
    renderButton,
    serviceDeskIntegrations,
    currentServiceDeskStatus,
    isCityPortal,
  ])

  const initialValues = useMemo(() => {
    if (isSuperAdmin && currentServiceDeskStatus) {
      return {
        [SD_NAME]: currentServiceDeskStatus[SD_NAME],
        [URL]: currentServiceDeskStatus[URL],
        [FILE]: currentServiceDeskStatus.fileName,
        [CLIENT_ID]: currentServiceDeskStatus.clientId,
        [SECRET_ID]: currentServiceDeskStatus.secretId,
      }
    }
    if (currentServiceDeskStatus && !isSuperAdmin) {
      const isValidIntegration = !!serviceDeskIntegrations
        .some((element) => element.id === currentServiceDeskStatus.id)
      if (!isValidIntegration) {
        return {}
      }
      return {
        [SERVICE_DESK_INTEGRATIONS]: currentServiceDeskStatus.id,
      }
    }
  }, [currentServiceDeskStatus, isSuperAdmin, serviceDeskIntegrations])

  return (
    <Formik
      initialValues={initialValues}
      ref={form}
      validationSchema={currentServiceDeskStatus ? {} : deskFormValidation(serviceDeskStatus)}
      enableReinitialize
      onSubmit={onSubmit}
      render={(formProps) => {
        const {
          touched, errors, handleSubmit, isSubmitting, values
        } = formProps
        return (
          <FormWrapper>
            {renderForm(handleSubmit, touched, errors, isSubmitting, values)}
          </FormWrapper>
        )
      }}
    />
  )
}

DescForm.defaultProps = {
  data: {},
  onSelectSettings: noop,
}

DescForm.propTypes = {
  data: pt.object,
  onSelectSettings: pt.func,
}

export default React.memo(DescForm)
