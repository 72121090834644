import { all } from 'redux-saga/effects'

import getRootElement from './getRootElement'
import getCard from './getCard'
import getObjectTelemetry from './getObjectTelemetry'
import getNodeStatistic from './getNodeStatistic'

import createGeoZone from './createGeoZone'
import editGeoZone from './editGeoZone'
import deleteGeoZone from './deleteGeoZone'

import createProject from './createProject'
import editProject from './editProject'
import deleteProject from './deleteProject'
import deleteProjectCustomField from './deleteProjectCustomField'

import createIntegration from './createIntegration'
import editIntegration from './editIntegration'
import deleteIntegration from './deleteIntegration'

import createObject from './createObject'
import deleteObject from './deleteObject'
import editObject from './editObject'

import getIntegrationTypes from './getIntegrationTypes'
import getObjectTypes from './getObjectTypes'
import getTenantParents from './getTenantParents'
import getScheme from './getScheme'
import downloadMultipleObject from './downloadMultipleObject'
import getZonesAndPinsAndTelemetry from './getZonesAndPinsAndTelemetry'
import getObjectValidationScheme from './getObjectValidationScheme'
import getObjectConnectedIdentifiers from './getObjectConnectedIdentifiers'
import getConnectedLinesCoordinates from './getConnectedLinesCoordinates'

import uploadObjectFile from './uploadObjectFile'
import getObjectFile from './getObjectFile'
import exportObjectPDF from './exportObjectPDF'
import deleteObjectFile from './deleteObjectFile'

import getAllIntegrations from './getAllIntegrations'
import verifyObject from './verifyObject'
import downloadScheme from './downloadScheme'

import installationEventsRoot from './events'
import getTree from './getTree'

export default function* root() {
  yield all([
    createGeoZone(),
    editGeoZone(),
    deleteGeoZone(),
    getRootElement(),
    getCard(),
    createProject(),
    editProject(),
    deleteProject(),
    createIntegration(),
    editIntegration(),
    deleteIntegration(),
    getIntegrationTypes(),
    getObjectTypes(),
    createObject(),
    deleteObject(),
    editObject(),
    getTenantParents(),
    getScheme(),
    downloadMultipleObject(),
    getZonesAndPinsAndTelemetry(),
    getObjectValidationScheme(),
    uploadObjectFile(),
    getObjectFile(),
    exportObjectPDF(),
    deleteObjectFile(),
    getAllIntegrations(),
    verifyObject(),
    downloadScheme(),
    installationEventsRoot(),
    deleteProjectCustomField(),
    getObjectTelemetry(),
    getNodeStatistic(),
    getObjectConnectedIdentifiers(),
    getConnectedLinesCoordinates(),
    getTree(),
  ])
}
