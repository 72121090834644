import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import qs from 'query-string'

import {
  GET_REALE_TABLE,
  OBJECT_REFERENCE_REALE_TABLE,
} from '@/store/actions/objectReference'
import { successObjectReferenceRealeTable, setLoadingTable } from '@/store/actions/objectReference/getTable'
import { GET_REALE_TABLE_URL } from '@/constants/apiRoutes'
import {
  getTableParametersReale,
  getImmoveableTableFilter,
} from '@/store/selectors/objectReferenceReale'


function* getRealeTableSaga() {
  const {search} = window.location
  const projectId = qs.parse(search)['projectId']

  try {
    yield put(setLoadingTable(true))
    const tableParameters = yield select(getTableParametersReale)
    const filterValue = yield select(getImmoveableTableFilter)
    const response = yield request({
      url: GET_REALE_TABLE_URL({
        params: {
          parentId: projectId,
          pageNumber: tableParameters.page - 1,
          pageSize: tableParameters.perPage,
          searchText: tableParameters.requestQuery || '',
          sort: [`${tableParameters.sort.field}: ${tableParameters.sort.direction}`],
          names: filterValue['realProperty'] && filterValue['realProperty']['name'].map((item) => item.value),
          years: filterValue['realProperty'] && filterValue['realProperty']['year'].map((item) => item.value)
        },
      }),
      method: 'get',
    })

    if (response.data) {
      const {
        content,
        pageable,
      } = response.data
        yield put(successObjectReferenceRealeTable({
        data: content,
        totalElements: pageable.totalElements,
        totalPages: pageable.totalPages,
      }))
    }
    yield put(setLoadingTable(false))


  } catch (error) {
    yield put(setLoadingTable(false))

  }

  return null
}

export default function* root() {
  yield takeLatest(GET_REALE_TABLE.REQUEST, getRealeTableSaga)
  yield takeLatest(OBJECT_REFERENCE_REALE_TABLE.SORT, getRealeTableSaga)
  yield takeLatest(OBJECT_REFERENCE_REALE_TABLE.PER_PAGE, getRealeTableSaga)
  yield takeLatest(OBJECT_REFERENCE_REALE_TABLE.PAGE, getRealeTableSaga)
  yield takeLatest(OBJECT_REFERENCE_REALE_TABLE.REQUEST_QUERY, getRealeTableSaga)

}
