import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { SEARCH_TABLE_ENDPOINT } from '@/constants/apiRoutes'
import {
  getSearchTableParameters,
  getSearchValues,
} from '@/store/selectors/search'
import createNotifications from '@/helpers/notification'
import { errorSearchTable, successSearchTable } from '@/store/actions/search/getTable'
import { GET_SEARCH_TABLE, SEARCH_TABLE } from '@/store/actions/search'
import { setGlobalLoading } from '@/store/actions/appSettings'

function* getSearchTableSaga() {
  try {
    yield put(setGlobalLoading(true))
    const tableParameters = yield select(getSearchTableParameters)
    const searchValues = yield select(getSearchValues)

    const response = yield request({
      url: SEARCH_TABLE_ENDPOINT({
        params: {
          searchByName: searchValues.name,
          installationTypes: searchValues.installationTypes,
          geoZoneIds: searchValues.geoZone,
          projectIds: searchValues.project,
          integrationIds: searchValues.integrationIds,
          regions: searchValues.region,
          cities: searchValues.city,

          pageNumber: tableParameters.page - 1,
          pageSize: tableParameters.perPage,
          searchByTable: tableParameters.requestQuery || '',
          sort: [`${tableParameters.sort.field}: ${tableParameters.sort.direction}`],
        },
      }),
      method: 'get',
    })

    const { content, pageable } = response.data
    yield put(setGlobalLoading(false))
    yield put(successSearchTable({
      data: content,
      totalElements: pageable.totalElements,
    }))

    } catch (error) {
    yield put(setGlobalLoading(false))
    yield put(errorSearchTable(error))
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось получить результаты расширенного поиска.\nПовторите попытку позже.',
      type: 'error',
    })
  }
    return null
}

export default function* root() {
  yield takeLatest(GET_SEARCH_TABLE.REQUEST, getSearchTableSaga)
  yield takeLatest(SEARCH_TABLE.SORT, getSearchTableSaga)
  yield takeLatest(SEARCH_TABLE.PER_PAGE, getSearchTableSaga)
  yield takeLatest(SEARCH_TABLE.PAGE, getSearchTableSaga)
  yield takeLatest(SEARCH_TABLE.REQUEST_QUERY, getSearchTableSaga)
}