import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import {
  getData,
  getSelector,
  getGraphType,
  getGraphOptions,
  getGraphPercent,
  getGraphYear,
  getSelectedGraphPercentOptions,
  getSelectedGraphYearOptions,
  getFileExportStatus,
  getLoadingTable,
  getLiveTimeObjectTypesOptions,
  getLiveTimeObjectTypes,
} from '@/store/selectors/lifeTimeReport'
import { setSelectedNode } from '@/store/actions/appSettings'
import {
  getGlobalLoading,
  getSelectedNode
} from '@/store/selectors/appSettings'
import {
  setGraphType,
  setGraphOptions,
  setNewGraphYearOptions,
  setNewGraphPercentOptions, setLiveTimeObjectTypes,
} from '@/store/actions/lifeTimeReport'
import {
  requestLiveTimeReportBarData,
} from '@/store/actions/lifeTimeReport/getBar'
import {
  requestLiveTimeReportTable,
} from '@/store/actions/lifeTimeReport/getTable'
import {
  requestLiveTimeReportFile,
} from '@/store/actions/lifeTimeReport/getFile'
import { setGoToPassportization } from '@/store/actions/Ppr'
import LiveTime from './component'
import { requestLiveTimeReportObjectTypes } from '@/store/actions/lifeTimeReport/getObjectTypes'
import { requestGetCard } from '@/store/actions/installation/getCard'


const mapStateToProps = (state) => ({
  data: getData(state),
  selector: getSelector(state),
  graphType: getGraphType(state),
  graphOptions: getGraphOptions(state),
  graphYear: getGraphYear(state),
  graphPercent: getGraphPercent(state),
  selectedGraphPercentOptions: getSelectedGraphPercentOptions(state),
  selectedGraphYearOptions: getSelectedGraphYearOptions(state),
  node: getSelectedNode(state),
  fileStatus: getFileExportStatus(state),
  loadingTable: getLoadingTable(state),
  globalLoading: getGlobalLoading(state),
  objectTypesOptions: getLiveTimeObjectTypesOptions(state),
  objectTypes: getLiveTimeObjectTypes(state),
})

const mapDispatchToProps = (dispatch) => ({
  setGraphOptions: (payload) => dispatch(setGraphOptions(payload)),
  setGraphType: (payload) => dispatch(setGraphType(payload)),
  setNode: (payload) => dispatch(setSelectedNode(payload)),
  setNewGraphYearOptions: (payload) => dispatch(setNewGraphYearOptions(payload)),
  setNewGraphPercentOptions: (payload) => dispatch(setNewGraphPercentOptions(payload)),
  getGraphData: (payload) => dispatch(requestLiveTimeReportBarData(payload)),
  getTableData: (payload) => dispatch(requestLiveTimeReportTable(payload)),
  getReportFile: (payload) => dispatch(requestLiveTimeReportFile(payload)),
  getObjectTypesOptions: () => dispatch(requestLiveTimeReportObjectTypes()),
  setObjectTypes: (payload) => dispatch(setLiveTimeObjectTypes(payload)),
  setGoToPassportization: (payload) => dispatch(setGoToPassportization(payload)),
  requestGetCard: (payload) => dispatch(requestGetCard(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(LiveTime))
