import * as yup from 'yup'
import invariant from 'invariant'
import without from 'lodash/without'
import isNaN from 'lodash/isNaN'
import {
  CLIENT_ID,
  SECRET_ID,
  PASSWORD,
  PORT,
  URL,
  NAME,
  PERIOD,
  UNIT,
  LOGIN,
  SD_NAME,
  FILE,
} from '@/constants/forms/integration'
import {
  USER_NAME,
  USER_EMAIL,
  REPORT_TITLE,
} from '@/constants/forms/reportManager'
import {
  ADMIN_NAME,
  ADMIN_SURNAME,
  ADMIN_PATRONYMIC,
  ADMIN_TELEPHON,
  ADMIN_EMAIL,
  ADMIN_POSITION,
  ADMIN_ABOUT_ME,
  ADMIN_PHOTO,
  ADMIN_PHOTO_RESOLUTION,
} from '@/constants/forms/admin'
import {
  MIN_VALUE,
  MAX_VALUE,
  MIN_CRITICAL_VALUE,
  MAX_CRITICAL_VALUE,
} from '@/constants/forms/objectWidgetSettings'

import {
  NAME as GROUP_NAME,
  PHONE_NUMBER as GROUP_PHONE_NUMBER,
  EMAIL as GROUP_EMAIL,
  SUB_END_DATA as GROUP_SUB_END_DATA,
} from '@/constants/forms/group'

import CREATE_INTEGRATION_NAMES from '@/constants/forms/createIntegration'
import CREATE_GEOZONE_NAMES from '@/constants/forms/createGeoZone'
import CREATE_REGION_NAMES from '@/constants/forms/createProject'
import CREATE_SCHEDULE_NAMES from '@/constants/forms/createSchedule'
import CREATE_OBJECTS_GROUP_NAMES from '@/constants/forms/createObjectsGroup'

export const SELECT_GROUP = 'SELECT_GROUP'
export const BLOCK_USERS = 'BLOCK_USERS'

export const DEFAULT_PERIOD = '12'
export const DEFAULT_UNIT = 'час'
export const FILE_SIZE = 1024 * 1024
export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
]

export const FORM_SCHEMAS_DATA = {
  [LOGIN]: '',
  [PASSWORD]: '',
  [PORT]: '',
  [URL]: '',
  [NAME]: '',
  [PERIOD]: DEFAULT_PERIOD,
  [UNIT]: DEFAULT_UNIT,
}

export const FORM_ACY_WIDGET = {
  [PERIOD]: DEFAULT_PERIOD,
  [UNIT]: DEFAULT_UNIT,
}

export const ORGANIZATION_NAME = 'organizationName'
export const BLOCK_ORGANIZATION = 'blockOrganization'

export const ONLY_NUMBERS = /\d+/g
export const NUMBER_WITHOUT_FIRST_ZERO = /^[1-9][0-9]*$/

const latitude = yup.string('validation.number')
  .transform((value) => ((isNaN(value) || !value) ? undefined : value))
  .required('validation.required')
  .test('maxLimit', 'validation.latitudeMax', (value) => parseFloat(value) <= 90)
  .test('minLimit', 'validation.latitudeMin', (value) => parseFloat(value) >= -90)
  .test('decimalLength', 'validation.float6Digits', (value) => ((value || '')
    .toString()
    .split('.')[1] || '').length === 6)

const longitude = yup.string('validation.number')
  .transform((value) => ((isNaN(value) || !value) ? undefined : value))
  .required('validation.required')
  .test('maxLimit', 'validation.longitudeMax', (value) => parseFloat(value) <= 180)
  .test('minLimit', 'validation.longitudeMin', (value) => parseFloat(value) >= -180)
  .test('decimalLength', 'validation.float6Digits', (value) => ((value || '')
    .toString()
    .split('.')[1] || '').length === 6)

const radius = yup.string('validation.number')
    .transform((value) => ((isNaN(value) || !value) ? undefined : value))
    .required('validation.required')
    .test('minLimit', 'validation.radiusMin', (value) => parseFloat(value) >= 3)

export const ACYWIDGET_CONFIG = [
  {
    value: 'sec',
    title: 'сек',
    multiplier: 1,
  },
  {
    value: 'min',
    title: 'мин',
    multiplier: 60,
  },
]

yup.addMethod(
  yup.object,
  'atLeastOneRequired',
  function (list, message) {
    invariant(
      list.every((field) => this.fields[field]),
      'All required fields should be defined before calling atLeastOneRequired',
    )
    return this.shape(
      list.reduce(
        (accumulator, field) => ({
          ...accumulator,
          [field]: this.fields[field].when(without(list, field), {
            is: (...values) => !values.some((item) => item),
            then: this.fields[field].required(message),
          }),
        }),
        {},
      ),
      list.reduce(
        (accumulator, item, index, originalList) => [
          ...accumulator,
          ...originalList
            .slice(index + 1)
            .map((originalListItem) => [item, originalListItem]),
        ],
        [],
      ),
    )
  },
)

export const ACYWidgetValidationScheme = yup
  .object()
  .shape({
    [PERIOD]: yup
      .number('validation.integer')
      .integer('validation.integer')
      .min(1, 'validation.integerMoreThan0')
      .max(60, 'validation.integerLessThan60')
      .required('validation.required'),
    [UNIT]: yup
      .string()
      .required('validation.required'),
  })
export const MultipleUserEditScheme = yup
  .object()
  .shape({
    [SELECT_GROUP]: yup
      .string(),
    [BLOCK_USERS]: yup
      .bool()
  })

export const DEFAULT_INTEGRATION_FIELDS = {
  [LOGIN]: yup
    .string()
    .required('validation.required'),
  [PASSWORD]: yup
    .string()
    .min(6, 'validation.minLength6')
    .required('validation.required'),
  [PORT]: yup
    .number()
    .min(1, 'validation.valueFrom1To65535')
    .max(65535, 'validation.valueFrom1To65535'),
  [NAME]: yup
    .string()
    .required('validation.required')
    .max(50, 'validation.maxLength50'),
  [URL]: yup
    .string()
    .url('validation.urlFormat')
    .required('validation.required'),
  [PERIOD]: yup
    .number()
    .max(24, 'validation.integerLessThan24'),
  [UNIT]: yup
    .string()
    .required('validation.required'),
}

export const getCustomIntegrationFieldValidator = (fields) => fields
  .reduce((accumulator, field) => {
    if (!DEFAULT_INTEGRATION_FIELDS[field]) {
      return {
        ...accumulator,
        [field]: yup
          .string()
          .required('validation.required'),
      }
    }
    return accumulator
  }, {})

export const integrationSchemaCreate = (fields) => yup
  .object()
  .shape({
    ...DEFAULT_INTEGRATION_FIELDS,
    ...getCustomIntegrationFieldValidator(fields),
    [LOGIN]: yup
      .string()
      .required('validation.required'),
    [PASSWORD]: yup
      .string()
      .min(6, 'validation.minLength6')
      .required('validation.required'),
  })

export const integrationSchemaUpdate = (fields) => yup
  .object()
  .shape({
    ...DEFAULT_INTEGRATION_FIELDS,
    ...getCustomIntegrationFieldValidator(fields),
    [LOGIN]: yup
      .string(),
    [PASSWORD]: yup
      .string()
      .min(6, 'validation.minLength6'),
  })

export const userGroupCreate = yup
  .object()
  .shape({
    [ORGANIZATION_NAME]: yup
      .string()
      .required('validation.required'),
  })

export const validationSchemaManySDSettings = (data, allServiceDeskIntegrations) => {
  const reducer = function (accumulator, node) {
    const isConnected = allServiceDeskIntegrations
      .some((serviceDesk) => serviceDesk.temporaryId === node.id)
    return ({
      ...accumulator,
      [`${SD_NAME}-${node.id}`]: yup
        .string()
        .required('validation.required'),
      [`${URL}-${node.id}`]: yup
        .string()
        .required('validation.required'),
      [`${CLIENT_ID}-${node.id}`]: yup
        .string()
        .required('validation.required')
        .test(
          'theSameClientId',
          'validation.clientIdExist',
          (value) => {
            const clientIdExist = (allServiceDeskIntegrations || []).some(
              (serviceDeskIntegration) => (
                value === serviceDeskIntegration.clientId
              ),
            )
            if (clientIdExist && !isConnected) {
              return false
            }
            return true
          },
        ),
      [`${SECRET_ID}-${node.id}`]: yup
        .string()
        .required('validation.required')
        .test(
          'theSameSecretId',
          'validation.secretIdExist',
          (value) => {
            const secretIdExist = (allServiceDeskIntegrations || []).some(
              (serviceDeskIntegration) => (
                value === serviceDeskIntegration.secretId
              ),
            )
            if (secretIdExist && !isConnected) {
              return false
            }
            return true
          },
        ),
      [`${FILE}-${node.id}`]: yup
        .mixed()
        .required('validation.required'),
    })
  }
  return yup.object().shape((data || []).reduce(reducer, {}))
}

export const validationRecipients = (data) => {
  const reducer = function (accumulator, node) {
    return ({
      ...accumulator,
      [`${USER_EMAIL}-${node.id}`]: yup
        .string()
        .required('validation.required')
        .max(128, 'validation.maxLength128')
        .email('validation.emailIncorrect'),
      [`${USER_NAME}-${node.id}`]: yup
        .string()
        .required('validation.required')
        .max(64, 'validation.maxLength64'),
    })
  }
  const recipientValidation = data.reduce(reducer, {})
  const allValidation = {
    ...recipientValidation,
    [REPORT_TITLE]: yup
      .string()
      .max(128, 'validation.maxLength128'),
  }
  return yup.object().shape(allValidation)
}

export const validationSchemaManyWidgetSettings = (data) => {
  const reducer = function (accumulator, node) {
    return ({
      ...accumulator,
      [`${MIN_CRITICAL_VALUE}${node.title}`]: yup
        .boolean()
        .test('check',
          'validation.noLimitSet',
          function (value) {
            if (value
              && (
                this.parent[`${MIN_VALUE}${node.title}`] === undefined
                || this.parent[`${MIN_VALUE}${node.title}`] === '')
            ) {
              return false
            }
            return true
          }),
      [`${MAX_CRITICAL_VALUE}${node.title}`]: yup
        .boolean()
        .test('check',
          'validation.noLimitSet',
          function (value) {
            if (value
              && (
                this.parent[`${MAX_VALUE}${node.title}`] === undefined
                || this.parent[`${MAX_VALUE}${node.title}`] === '')
            ) {
              return false
            }
            return true
          }),
      [`${MAX_VALUE}${node.title}`]: yup
        .number()
        .typeError('validation.numbersOnly'),
      [`${MIN_VALUE}${node.title}`]: yup
        .number()
        .typeError('validation.numbersOnly')
        .test('compare',
          'validation.minLevelMoreThanMax',
          function (value) {
            if (Number.parseFloat(value || 0) === 0
              && Number.parseFloat(this.parent[`${MAX_VALUE}${node.title}`] || 0) === 0) {
              return true
            }
            return Number.parseFloat(value || 0) < Number.parseFloat(this.parent[`${MAX_VALUE}${node.title}`] || 0)
          }),
    })
  }
  return yup.object().shape(data.reduce(reducer, {}))
}

export const validationSchemaWidgetSettings = yup.object().shape({
  [MIN_CRITICAL_VALUE]: yup
    .boolean()
    .test('check',
      'validation.noLimitSet',
      function (value) {
        if (value && (this.parent[MIN_VALUE] === undefined || this.parent[MIN_VALUE] === '')) {
          return false
        }
        return true
      }),
  [MAX_CRITICAL_VALUE]: yup
    .boolean()
    .test('check',
      'validation.noLimitSet',
      function (value) {
        if (value && (this.parent[MAX_VALUE] === undefined || this.parent[MAX_VALUE] === '')) {
          return false
        }
        return true
      }),
  [MAX_VALUE]: yup
    .number()
    .typeError('validation.numbersOnly'),
  [MIN_VALUE]: yup
    .number()
    .typeError('validation.numbersOnly')
    .test('compare',
      'validation.minLevelMoreThanMax',
      function (value) {
        if (
          Number.parseFloat(value || 0) === 0
          && Number.parseFloat(this.parent[MAX_VALUE] || 0) === 0
        ) {
          return true
        }
        return Number.parseFloat(value || 0) < Number.parseFloat(this.parent[MAX_VALUE] || 0)
      }),
})

export const createAdminSchema = yup
  .object()
  .shape({
    [ADMIN_SURNAME]: yup
      .string()
      .required('validation.required'),
    [ADMIN_NAME]: yup
      .string()
      .required('validation.required'),
    [ADMIN_PATRONYMIC]: yup
      .string(),
    [ADMIN_EMAIL]: yup
      .string()
      .email('validation.emailIncorrect')
      .required('validation.required'),
  })

export const editUserValidationScheme = yup
  .object()
  .shape({
    [ADMIN_SURNAME]: yup
      .string()
      .required('validation.required'),
    [ADMIN_PATRONYMIC]: yup
      .string(),
    [ADMIN_NAME]: yup
      .string()
      .required('validation.required'),
    [ADMIN_TELEPHON]: yup
      .string()
      .matches(
        /^[8|7]-?\(?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/,
        'validation.phoneNumberFormat',
      )
      .required('validation.required'),
    [ADMIN_EMAIL]: yup
      .string()
      .email('validation.emailIncorrect'),
    [ADMIN_POSITION]: yup
      .string(),
    [ADMIN_ABOUT_ME]: yup
      .string(),
    [ADMIN_PHOTO]: yup
      .mixed()
      .test(
        'fileSize',
        'validation.fileLessThan1Mb',
        (value) => {
          if (value) {
            return value.size <= FILE_SIZE
          }
          return true
        },
      )
      .test(
        'fileFormat',
        'validation.fileFormat',
        (value) => {
          if (value) {
            return SUPPORTED_FORMATS.includes(value.type)
          }
          return true
        },
      ),
    [ADMIN_PHOTO_RESOLUTION]: yup
      .mixed()
      .test(
        'fileResolution',
        'validation.fileProportion',
        (value) => value,
      ),
  })

export const addGroupValidationScheme = yup
  .object()
  .shape({
    [GROUP_NAME]: yup
      .string()
      .required('validation.required'),
    [GROUP_PHONE_NUMBER]: yup
      .string()
      .matches(
        /^[8|7]-?\(?\d{3}\)?-?\d{3}-?\d{2}-?\d{2}$/,
        'validation.phoneNumberFormat',
      )
      .required('validation.required'),
    [GROUP_EMAIL]: yup
      .string()
      .email('validation.emailIncorrect')
      .required('validation.required'),
    [GROUP_SUB_END_DATA]: yup
      .string()
      .matches(
        /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.]([2-9][0-9][2-9])\d$/,
        'validation.dateFormat',
      )
      .required('validation.required'),
  })

export const deskFormValidation = (allServiceDeskIntegrations) => yup
  .object()
  .shape({
    [SD_NAME]: yup
      .string()
      .required('validation.required'),
    [URL]: yup
      .string()
      .required('validation.required'),
    [CLIENT_ID]: yup
      .string()
      .required('validation.required')
      .test(
        'theSameClientId',
        'validation.clientIdExist',
        (value) => {
          const clientIdExist = (allServiceDeskIntegrations || []).some(
            (serviceDeskIntegration) => (
              value === serviceDeskIntegration.clientId
            ),
          )
          if (clientIdExist) {
            return false
          }
          return true
        },
      ),
    [SECRET_ID]: yup
      .string()
      .required('validation.required')
      .test(
        'theSameSecretId',
        'validation.secretIdExist',
        (value) => {
          const secretIdExist = (allServiceDeskIntegrations || []).some(
            (serviceDeskIntegration) => (
              value === serviceDeskIntegration.secretId
            ),
          )
          if (secretIdExist) {
            return false
          }
          return true
        },
      ),
    [FILE]: yup
      .mixed()
      .required('validation.required'),
  })

export const createRegionValidator = values => yup
  .object()
  .shape({
    [CREATE_GEOZONE_NAMES.NAME]: yup
      .string()
      .max(35, 'validation.maxLength35')
      .required('validation.required'),
    // [CREATE_GEOZONE_NAMES.PARENT]: yup
    //   .string()
    //   .required('validation.required'),
    [CREATE_GEOZONE_NAMES.LONGITUDE]: longitude,
    [CREATE_GEOZONE_NAMES.LATITUDE]: latitude,
    [CREATE_GEOZONE_NAMES.MARK]: yup
      .string()
      .max(300, 'validation.maxLength300'),
    [CREATE_GEOZONE_NAMES.RADIUS]: yup
        .string('validation.number')
        .required('validation.required')
        .test('minLimit', 'validation.radiusMin', (value) => parseFloat(value) >= 3)
        .test('maxLimit', `не более ${values.maxRadius} км`, (value) => parseFloat(value) <= values.maxRadius)
  })

export const createRootRegionValidator = yup
  .object()
  .shape({
    [CREATE_GEOZONE_NAMES.NAME]: yup
      .string()
      .max(15, 'validation.maxLength15')
      .required('validation.required'),
    [CREATE_GEOZONE_NAMES.LONGITUDE]: longitude,
    [CREATE_GEOZONE_NAMES.LATITUDE]: latitude,
    [CREATE_GEOZONE_NAMES.MARK]: yup
      .string()
      .max(300, 'validation.maxLength300'),
    [CREATE_GEOZONE_NAMES.MAX_RADIUS]: radius,
  })

export const createProjectValidator = yup
  .object()
  .shape({
    [CREATE_REGION_NAMES.NAME]: yup
      .string()
      .max(35, 'validation.maxLength35')
      .required('validation.required'),
    // [CREATE_REGION_NAMES.PARENT]: yup
    //   .string()
    //   .required('validation.required'),
    [CREATE_REGION_NAMES.MARK]: yup
      .string()
      .max(300, 'validation.maxLength300'),
    [CREATE_REGION_NAMES.CUSTOM_FIELD]: yup.array()
      .of(
        yup.object().shape({
          nameField: yup.string().when('remove', {
            is: true,
            otherwise: (validator) => validator
              .max(64, 'validation.maxLength64')
              .required('validation.required'),
          }),
          value: yup.string().when('remove', {
            is: true,
            otherwise: (validator) => validator
              .max(128, 'validation.maxLength128')
              .required('validation.required'),
          }),
        }),
      ),
  })

export const createIntegration = yup
  .object()
  .shape({
    [CREATE_INTEGRATION_NAMES.NAME]: yup
      .string()
      .max(35, 'validation.maxLength35')
      .required('validation.required'),
    // [CREATE_INTEGRATION_NAMES.PARENT_PROJECT]: yup
    //   .string()
    //   .required('validation.required'),
    [CREATE_INTEGRATION_NAMES.TYPE]: yup
      .string()
      .required('validation.required'),
  })

export const createScheduleValidator = yup
    .object()
    .shape({
        [CREATE_SCHEDULE_NAMES.NAME]: yup
            .string()
            .max(50, 'validation.maxLength50')
            .required('validation.required'),
        [CREATE_SCHEDULE_NAMES.TIMEZONE]: yup
            .string()
            .required('validation.required'),
        [CREATE_SCHEDULE_NAMES.DEVICE_TYPE]: yup
            .string()
            .required('validation.required'),
        [CREATE_SCHEDULE_NAMES.PHASE]: yup
            .array()
            .nullable()
            .when([CREATE_SCHEDULE_NAMES.DEVICE_TYPE], {
              is: 'SHUNO',
              then: (schema) => schema.min(1, ' ').required('validation.required'),
              otherwise: (schema) => schema
            }),
        [CREATE_SCHEDULE_NAMES.COMMENT]: yup
            .string()
            .max(150, 'validation.maxLength150'),
    })
export const createTaskValidator = yup
    .object()
    .shape({
        [CREATE_SCHEDULE_NAMES.TASK_NAME]: yup
            .string()
            .max(50, 'validation.maxLength50')
            .required('validation.required'),
        [CREATE_SCHEDULE_NAMES.START_DATE]: yup
            .date()
            .required('validation.required')
            .test('compare',
                'validation.dateStartMoreThanEnd',
                function (value) {
                    if (this.parent[CREATE_SCHEDULE_NAMES.END_DATE] === undefined || this.parent[CREATE_SCHEDULE_NAMES.END_DATE] === null) {
                      return true
                    }
                    if (value <= this.parent[CREATE_SCHEDULE_NAMES.END_DATE]) {
                      return true
                    }
                }),
        [CREATE_SCHEDULE_NAMES.END_DATE]: yup
            .date()
            .required('validation.required'),
        [CREATE_SCHEDULE_NAMES.ACTIONS]: yup.array()
            .min(1, ' ')
            .required(' ')
            .of(
                yup.object().shape({
                    hours: yup
                      .string()
                      .length(2, ' ')
                      .required(' '),
                    minutes: yup
                      .string()
                      .length(2, ' ')
                      .required(' '),
                    percent: yup
                      .number()
                      .typeError(' ')
                      .min(0, ' ')
                      .max(100, ' ')
                      .required(' '),
                }),
            ),
    })
export const createObjectsGroupValidator = yup
  .object()
  .shape({
    [CREATE_OBJECTS_GROUP_NAMES.NAME]: yup
      .string()
      .max(30, 'validation.maxLength30')
      .required('validation.required'),
    [CREATE_OBJECTS_GROUP_NAMES.COLOR]: yup
      .string()
      .required('validation.required'),
  })
