import React from 'react'
import qs from 'query-string'
import { FormattedMessage as Lang } from 'react-intl'
import WidgetsIcons from '@/components/icons/widgets'
import MenuIcons from '@/components/icons/menu'
import {
  WIDGETS_DASHBOARD_URL,
  WIDGETS_DASHBOARD_GOV_URL,
  SEARCH_URL,
  PPR_URL,
  OBJECTS_GROUPS_URL,
} from '@/constants/routes'
import { PROCESSES_BY_ID, ID_BY_APPLICATIONS } from '@/constants/widgets'
import {
  START_DATE,
  END_DATE,
  PERIOD,
  INTERVAL,
} from '@/constants/forms/tyumenOurHomeFilter'
import {
  ALL_PERIOD,
  MONTHLY,
} from '@/constants/tyumenOurHome'
import { APP_BY_WIDGET_ID } from './names'

const widgetsShop = [
  {
    applicationId: ID_BY_APPLICATIONS.PPR,
    id: APP_BY_WIDGET_ID.PPR,
    icon: WidgetsIcons.PprIcon,
    menuIcon: MenuIcons.pprIcon,
    path: PPR_URL,
    title: <Lang id="menu.ppr"/>,
    description: <Lang id="widgets.pprDescription"/>,
    titleMenu: <Lang id="menu.pprMenuSidbar"/>,
    renderComponent: () => (null),
  },
  // {
  //   applicationId: ID_BY_APPLICATIONS.ACTIVITY_LOG,
  //   id: APP_BY_WIDGET_ID.ACTIVITI_LOG,
  //   icon: WidgetsIcons.ActivityLogIcon,
  //   menuIcon: MenuIcons.ActivityLogIcon,
  //   path: ACTIVITY_LOG_URL,
  //   title: <Lang id="menu.activityLog" />,
  //   description: <Lang id="widgets.activitiLogDescription"/>,
  //   titleMenu: <Lang id="menu.activitiLogMenuSidebar"/>,
  //   renderComponent: () => (null),
  // },
  {
    applicationId: ID_BY_APPLICATIONS.SEARCH,
    id: APP_BY_WIDGET_ID.SEARCH,
    icon: WidgetsIcons.SearchIcon,
    menuIcon: MenuIcons.SearchIcon,
    path: SEARCH_URL,
    title: (<Lang id="widgets.search" />),
    description: <Lang id="widgets.searchDescription" />,
    renderComponent: () => (null),
  },
  {
    applicationId: ID_BY_APPLICATIONS.HEATING,
    processId: PROCESSES_BY_ID.HEAT_SUPPLY,
    id: APP_BY_WIDGET_ID.HEAT_SUPPLY,
    icon: WidgetsIcons.HeatIcon,
    menuIcon: MenuIcons.HeatSupplyIcon,
    title: (<Lang id="widgets.heatSupply" />),
    description: <Lang id="widgets.heatSupplyDescription" />,
    renderComponent: () => (null),
  },
  {
    applicationId: ID_BY_APPLICATIONS.WATER,
    processId: PROCESSES_BY_ID.WATER_SUPPLY,
    id: APP_BY_WIDGET_ID.WATER_SUPPLY,
    icon: WidgetsIcons.WaterIcon,
    menuIcon: MenuIcons.WaterSupplyIcon,
    title: (<Lang id="widgets.waterSupply" />),
    description: <Lang id="widgets.waterSupplyDescription" />,
    renderComponent: () => (null),
  },
  {
    applicationId: ID_BY_APPLICATIONS.CHARGING_STATIONS,
    processId: PROCESSES_BY_ID.CHARGING_STATIONS,
    id: APP_BY_WIDGET_ID.CHARGING_STATIONS,
    icon: WidgetsIcons.CarElectricStationsIcon,
    menuIcon: MenuIcons.CarElectricStationsIcon,
    title: (<Lang id="widgets.chargingStation" />),
    description: <Lang id="widgets.chargingStationDescription" />,
    renderComponent: () => (null),
  },
  {
    applicationId: ID_BY_APPLICATIONS.ART_LIGHTING,
    id: APP_BY_WIDGET_ID.ARCHITECTURE_ILLUMINATION,
    icon: WidgetsIcons.IlluminationIcon,
    menuIcon: MenuIcons.IlluminationIcon,
    title: (<Lang id="widgets.architectureIllumination" />),
    description: <Lang id="widgets.architectureIlluminationDescription" />,
    renderComponent: () => (null),
  },
  {
    applicationId: ID_BY_APPLICATIONS.WASTE_COLLECTION,
    processId: PROCESSES_BY_ID.GARBAGE_COLLECTION,
    id: APP_BY_WIDGET_ID.GARBAGE_COLLECTION,
    icon: WidgetsIcons.GarbageCollectionIcon,
    menuIcon: MenuIcons.SmartRecycleBinIcon,
    title: (<Lang id="widgets.garbageCollection" />),
    description: <Lang id="widgets.garbageCollectionDescription" />,
    renderComponent: () => (null),
  },
  {
    applicationId: ID_BY_APPLICATIONS.ROAD_MONITORING,
    id: APP_BY_WIDGET_ID.ROAD_INFRASTRUCTURE,
    icon: WidgetsIcons.RoadIcon,
    menuIcon: MenuIcons.RoadIcon,
    title: (<Lang id="widgets.roadInfrastructure" />),
    description: <Lang id="widgets.roadInfrastructureDescription" />,
    renderComponent: () => (null),
  },
  {
    applicationId: ID_BY_APPLICATIONS.TRAFFIC_LIGHTS,
    id: APP_BY_WIDGET_ID.TRAFFIC_LIGHT,
    icon: WidgetsIcons.TrafficLightIcon,
    menuIcon: MenuIcons.TrafficLightIcon,
    title: (<Lang id="widgets.trafficLight" />),
    description: <Lang id="widgets.trafficLightDescription" />,
    renderComponent: () => (null),
  },
  {
    applicationId: ID_BY_APPLICATIONS.INFO_BOARDS,
    id: APP_BY_WIDGET_ID.INFO_FRAME,
    icon: WidgetsIcons.InfoFrameIcon,
    menuIcon: MenuIcons.InfoFrameIcon,
    title: (<Lang id="widgets.infoFrame" />),
    description: <Lang id="widgets.infoFrameDescription" />,
    renderComponent: () => (null),
  },
  {
    applicationId: ID_BY_APPLICATIONS.DISABLED_PARKING_SPACES,
    id: APP_BY_WIDGET_ID.HANDICAPPED_PLACES,
    icon: WidgetsIcons.HandicappedIcon,
    menuIcon: MenuIcons.HandicappedIcon,
    title: (<Lang id="widgets.handicappedPlaces" />),
    description: <Lang id="widgets.handicappedPlacesDescription" />,
    renderComponent: () => (null),
  },
  {
    applicationId: ID_BY_APPLICATIONS.DASHBOARD,
    id: APP_BY_WIDGET_ID.WIDGETS_DASHBOARD,
    maxCount: 1,
    icon: WidgetsIcons.WidgetsDashboard,
    menuIcon: MenuIcons.WidgetsDashboard,
    path: WIDGETS_DASHBOARD_URL,
    params: qs.stringify({
      [START_DATE]: null,
      [END_DATE]: null,
      [PERIOD]: ALL_PERIOD,
      [INTERVAL]: MONTHLY,
    }),
    title: (<Lang id="widgets.widgetsDashboard" />),
    description: <Lang id="widgets.widgetsDashboardDescription" />,
    renderComponent: () => (null),
  },
  {
    applicationId: ID_BY_APPLICATIONS.DASHBOARD2,
    id: APP_BY_WIDGET_ID.WIDGETS_DASHBOARD_GOV,
    maxCount: 1,
    icon: WidgetsIcons.WidgetsDashboard,
    menuIcon: MenuIcons.WidgetsDashboard,
    path: WIDGETS_DASHBOARD_GOV_URL,
    title: (<Lang id="widgets.widgetsDashboardGov" />),
    description: <Lang id="widgets.widgetsDashboardGovDescription" />,
    renderComponent: () => (null),
  },
  {
    applicationId: ID_BY_APPLICATIONS.OBJECTS_GROUPS,
    id: APP_BY_WIDGET_ID.OBJECTS_GROUPS,
    maxCount: 1,
    icon: WidgetsIcons.ObjectsGroupsIcon,
    menuIcon: MenuIcons.ObjectsGroupsIcon,
    path: OBJECTS_GROUPS_URL,
    title: (<Lang id="widgets.objectsGroups" />),
    description: <Lang id="widgets.objectsGroupsDescription" />,
    renderComponent: () => (null),
  },
]

export default widgetsShop
