import React from 'react'
import PieChart from 'react-minimal-pie-chart'
import pt from 'prop-types'
import { CLUSTER_ANGLE_MARGIN, PIE_CHART_SIZE, PIE_CHART_POSITION } from '@/constants/maps'
import { getPieChartData, isOneTypeInCluster } from '@/helpers/maps/getPieChartData'
import { REGENT_GRAY } from '@/constants/styles/defaultTheme'

const MapMarkPieChart = ({ statistic = {}, size, theme, isPassportization }) => {
  const data = getPieChartData(statistic)
  const isOneType = isOneTypeInCluster(statistic)
  return (
    <PieChart
      data={isPassportization ? [{color: REGENT_GRAY, value: 1 }] : data}
      paddingAngle={isOneType ? CLUSTER_ANGLE_MARGIN.zero : CLUSTER_ANGLE_MARGIN.small}
      background={theme.colors.backgrounds.default}
      style={{
        ...PIE_CHART_SIZE[size],
        ...PIE_CHART_POSITION,
      }}
    />
  )
}

MapMarkPieChart.defaultProps = {
  size: 'small',
}

MapMarkPieChart.propTypes = {
  theme: pt.objectOf(pt.object).isRequired,
  size: pt.string,
  statistic: pt.objectOf(pt.number).isRequired,
}


export default MapMarkPieChart
