import React from 'react'
import { CellContainer } from './styles'

const ActivitiLogCell = ({value}) => {
  return (
    <CellContainer>
      {value}
    </CellContainer>
  )
}

ActivitiLogCell.propTypes = {
}
ActivitiLogCell.defaultProps = {

}

export default ActivitiLogCell
