import { connect } from 'react-redux'
import { getRoot, getRootStatus, getTreeData } from '@/store/selectors/installation'
import Component from './component'
import { requestGetRootElements } from '@/store/actions/installation/getRootElemenet'
import { getUserData } from '@/store/selectors/appSettings'
import {
  setMapLayer,
  setSearchTree,
  setRussianBorders,
  resetSearchTree,
  setTrueStatusGlobalFilter,
} from '@/store/actions/appSettings'
import {
  getMapLayer,
  getSearchTree,
  getGlobalFilters,
  getToggleRussianBorders,
} from '@/store/selectors/appSettings'

const mapStateToProps = (state) => ({
  roots: getRoot(state),
  rootStatus: getRootStatus(state),
  mapLayer: getMapLayer(state),
  searchTree: getSearchTree(state),
  user: getUserData(state),
  globalFilters: getGlobalFilters(state),
  treeDataStatick: getTreeData(state),
  isRussianBorders: getToggleRussianBorders(state),
})

const mapDispatchToProps = (dispatch) => ({
  requestGetRootElements: (payload) => dispatch(requestGetRootElements(payload)),
  setMapLayer: (payload) => dispatch(setMapLayer(payload)),
  setSearchTree: (payload) => dispatch(setSearchTree(payload)),
  toggleRussianBorders: (payload) => dispatch(setRussianBorders(payload)),
  resetSearchTree: () => dispatch(resetSearchTree()),
  setTrueStatusGlobalFilter: () => dispatch(setTrueStatusGlobalFilter()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Component)
