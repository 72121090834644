
export default {
  'NAME': 'GENERAL_INFORMATION',
  'IDENTIFIER': 'GENERAL_INFORMATION',
  'INSTALLATION': 'GENERAL_INFORMATION',
  'EQUIPMENT_TYPE': 'GENERAL_INFORMATION',
  'MODEL_MARK': 'GENERAL_INFORMATION',
  'PRODUCER': 'GENERAL_INFORMATION',
  'SUPPLIER': 'GENERAL_INFORMATION',
  'CONTROL_CUPBOARD_TYPE': 'GENERAL_INFORMATION',
  'CUPBOARD_SERIAL_NUMBER': 'GENERAL_INFORMATION',
  'INCOMING_COUNTER_MODEL': 'GENERAL_INFORMATION',
  'SERIAL_NUMBER_INCOMING_COUNTER': 'GENERAL_INFORMATION',
  'OUTGOING_COUNTER_MODEL': 'GENERAL_INFORMATION',
  'SERIAL_NUMBER_OUTGOING_COUNTER': 'GENERAL_INFORMATION',
  'REGISTRY_NUMBER': 'GENERAL_INFORMATION',
  'ROOT_OBJECT': 'GENERAL_INFORMATION',
  'DEVICE_SERIAL_NUMBER': 'GENERAL_INFORMATION',
  'METER_SN': 'GENERAL_INFORMATION',
  'CONN_TYPE': 'GENERAL_INFORMATION',
  'VERSION_API': 'GENERAL_INFORMATION',
  'PROCEDURE_SUPPLIER': 'GENERAL_INFORMATION',
  'LIGHTING_CONTROL_BOX_IDENTIFIER': 'GENERAL_INFORMATION',
  'AUTOMATED_CONTROL_SYSTEM_IDENTIFIER': 'GENERAL_INFORMATION',
  'LAMP_IDENTIFIER': 'GENERAL_INFORMATION',
  'PARENT_IDENTIFIER': 'GENERAL_INFORMATION',
  'PARENT_TYPE': 'GENERAL_INFORMATION',
  'LINE_IDENTIFIER': 'GENERAL_INFORMATION',
  'BASE_STATION_IDENTIFIER': 'GENERAL_INFORMATION',
  'UTILITY_POLE_IDENTIFIER': 'GENERAL_INFORMATION',
  'REGISTRY_NUMBER_ASUNO': 'GENERAL_INFORMATION',
  'POWER_CONSUMPTION': 'GENERAL_INFORMATION',
  'WORKING_TEMPERATURE_RANGE': 'TECHNICAL_CONSTRAINTS',
  'WORKING_VOLTAGE_RANGE': 'TECHNICAL_CONSTRAINTS',
  'LIGHT_OUTPUT': 'TECHNICAL_CONSTRAINTS',
  'CALIBRATION_INTERVAL': 'TECHNICAL_CONSTRAINTS',
  'COLOUR_TEMPERATURE': 'TECHNICAL_CONSTRAINTS',
  'COLOUR_RENDERING_INDEX': 'TECHNICAL_CONSTRAINTS',
  'LAMP_EFFICIENCY': 'TECHNICAL_CONSTRAINTS',
  'SCATTERING_ANGLE': 'TECHNICAL_CONSTRAINTS',
  'LIGHT_INTENSITY_DISTRIBUTION_CURVE_TYPE': 'TECHNICAL_CONSTRAINTS',
  'FLICKER_PERCENT': 'TECHNICAL_CONSTRAINTS',
  'RATED_POWER': 'TECHNICAL_CONSTRAINTS',
  'ELECTRICITY_SUPPLIER': 'TECHNICAL_CONSTRAINTS',
  'ACTUAL_CONSUMPTION_POWER': 'TECHNICAL_CONSTRAINTS',
  'NUMBER_MEASUREMENT_CHANNELS': 'TECHNICAL_CONSTRAINTS',
  'NUMBER_CONTROLLED_HEATING_SYSTEM': 'TECHNICAL_CONSTRAINTS',
  'MEASURING_RANGE_AVERAGE_VOLUMETRIC_FLOW': 'TECHNICAL_CONSTRAINTS',
  'TEMPERATURE_MEASUREMENT_RANGE': 'TECHNICAL_CONSTRAINTS',
  'MEASUREMENT_RANGE_TEMPERATURE_DIFFERENCE_SUPPLY_AND_RETURN_PIPELIN': 'TECHNICAL_CONSTRAINTS',
  'PRESSURE_MEASUREMENT_RANGE': 'TECHNICAL_CONSTRAINTS',
  'DC_POWER_SUPPLY_VOLTAGE': 'TECHNICAL_CONSTRAINTS',
  'LIMITS_PERMISSIBLE_RELATIVE_ERROR_TEMPERATURE_MEASUREMENT': 'TECHNICAL_CONSTRAINTS',
  'LAMP_TYPE': 'TECHNICAL_CONSTRAINTS',
  'HOUSING_MATERIAL': 'TECHNICAL_CONSTRAINTS',
  'STANDARD_COLOR_RAL': 'TECHNICAL_CONSTRAINTS',
  'UTILITY_POLE_TYPE': 'TECHNICAL_CONSTRAINTS',
  'COLOR_LAR': 'TECHNICAL_CONSTRAINTS',
  'SIGN_PAINTING': 'TECHNICAL_CONSTRAINTS',
  'START_DATE': 'EXPLOITATION',
  'EXPLOITATION_END_DATE': 'EXPLOITATION',
  'ORGANIZATION': 'EXPLOITATION',
  'OWNER': 'EXPLOITATION',
  'GUARANTEE_PERIOD': 'EXPLOITATION',
  'LIFE_TIME': 'EXPLOITATION',
  'LIGHT_FIXTURE_WORK_RESOURCE': 'EXPLOITATION',
  'PHOTO_TITLE': 'EXPLOITATION',
  'DOCUMENT_TITLE': 'EXPLOITATION',
  'BALANCE_SHEET_HOLDER': 'EXPLOITATION',
  'PRODUCER_WARRANTY_EXPIRATION_DATE': 'EXPLOITATION',
  'SUPPLIER_WARRANTY_EXPIRATION_DATE': 'EXPLOITATION',
  'PROJECT': 'LOCATION',
  'LATITUDE': 'LOCATION',
  'LONGITUDE': 'LOCATION',
  'REGION': 'LOCATION',
  'CITY': 'LOCATION',
  'STREET': 'LOCATION',
  'HOME_NUMBER': 'LOCATION',
  'PREVIOUS_LINKED_IDENTIFIER': 'OBJECT_CHARACTERISTIC',
  'LIGHTING_CATEGORY': 'OBJECT_CHARACTERISTIC',
  'IMPORTANCE': 'OBJECT_CHARACTERISTIC',
  'WORK_MODE': 'OBJECT_CHARACTERISTIC',
  'INSTALLATION_TYPE': 'OBJECT_CHARACTERISTIC',
  'CONTROL_TYPE': 'OBJECT_CHARACTERISTIC',
  'LIGHTING_TYPE': 'OBJECT_CHARACTERISTIC',
  'LOCATION_TYPE': 'OBJECT_CHARACTERISTIC',
  'OUTGOING_LINES_NUMBER': 'OBJECT_CHARACTERISTIC',
  'SETUP': 'OBJECT_CHARACTERISTIC',
  'INDICATORS': 'OBJECT_CHARACTERISTIC',
  'CONNECTED_FIXTURES_NUMBER': 'OBJECT_CHARACTERISTIC',
  'LIGHT_FUNCTIONALITY': 'OBJECT_CHARACTERISTIC',
  'TARIFF_ONE': 'OBJECT_CHARACTERISTIC',
  'TARIFF_TWO': 'OBJECT_CHARACTERISTIC',
  'TARIFF_THREE': 'OBJECT_CHARACTERISTIC',
  'TARIFF_FOUR': 'OBJECT_CHARACTERISTIC',
  'CONNECTOR': 'MANAGEMENT_ORGANIZATION_SYSTEM',
  'PRESENCE_BASE_STATION': 'MANAGEMENT_ORGANIZATION_SYSTEM',
  'CONTROLLER_MODEL': 'MANAGEMENT_ORGANIZATION_SYSTEM',
  'CONTROLLER_SERIAL_NUMBER': 'MANAGEMENT_ORGANIZATION_SYSTEM',
  'SWVER': 'MANAGEMENT_ORGANIZATION_SYSTEM',
  'ICCID1': 'MANAGEMENT_ORGANIZATION_SYSTEM',
  'ICCID2': 'MANAGEMENT_ORGANIZATION_SYSTEM',
  'TIME_ZONE': 'MANAGEMENT_ORGANIZATION_SYSTEM',
  'CONNECTED_LAMP_NUMBER': 'SURVEY_DATA',
  'LAMP_INSTALLATION_HEIGHT': 'SURVEY_DATA',
  'ROADWAY_WIDTH': 'SURVEY_DATA',
  'INSTALLATION_SCHEME': 'SURVEY_DATA',
  'STATE': 'SURVEY_DATA',
  'POWER_SUPPLY': 'SURVEY_DATA',
  'POWER_SUPPLY_TYPE': 'SURVEY_DATA',
  'OVERHANG_HEIGHT': 'SURVEY_DATA',
  'LAMP_ANGLE': 'SURVEY_DATA',
  'OVERHANG_LENGTH': 'SURVEY_DATA',
  'UTILITY_POLE_LAMP_POSITION': 'SURVEY_DATA',
  'CITY_NUMERIC': 'SURVEY_DATA',
  'UTILITY_POLE_KIND': 'SURVEY_DATA',
  'VOLS': 'SURVEY_DATA',
  'OBJECT_ATTRIBUTE': 'SURVEY_DATA',
  'BELONGING': 'SURVEY_DATA',
  'LIGHT_FIXTURE_STATE': 'SURVEY_DATA',
  'CONSOLE': 'SURVEY_DATA',
  'NETWORK_COMPANY': 'SURVEY_DATA',
  'INSPECTION_DATE': 'SERVICING',
  'BYPASS_DATE': 'SERVICING',
  'MAINTENANCE_DATE': 'SERVICING',
  'REPAIR_DATE': 'SERVICING',
  'CAPITAL_REPAIR_DATE': 'SERVICING',
  'GUARANTEE_EXPIRATION_DATE': 'SERVICING',
  'CHECKING_DATE': 'SERVICING',
  'PLAN_PPR_DATE': 'SERVICING',
  'FACT_PPR_DATE': 'SERVICING',
  'MODERNIZATION': 'SERVICING',
}