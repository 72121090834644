import React from "react";

import {
  Container,
  Card,
  Header,
  Status,
  Date,
  TaskNumber,
  EssenceOfAppeal,
  ObjectType,
  DateDecision,
} from './styles'

const CardList = ({data}) => {

  return (
    <Container>
      {data.map((card) => {
        return (
          <Card>
            <Header>
              <Status status={card?.status}>{card?.status || 'Поступило'}</Status>
              <Date>{card?.registrationDate}</Date>
            </Header>
            <TaskNumber>{`Задача №${card.taskNumber}`}</TaskNumber>
            <EssenceOfAppeal>{card?.essenceOfAppeal}</EssenceOfAppeal>
            <ObjectType>{`Объект: ${card?.objectType}`}</ObjectType>
            <DateDecision plan>
              Дата решения (план):
              <Date>{card?.plannedExecutionDate}</Date>
            </DateDecision>
            <DateDecision>
              Дата решения (факт):
              <Date overdue={card?.overdue}>{card?.actualDateOfExecution}</Date>
            </DateDecision>
          </Card>
        )
      })}

    </Container>
  )
}

export default CardList