import styled from 'styled-components'
import {
  WHITE,
  GRAY,
  MINE_SHAFT,
} from '@/constants/styles/defaultTheme'
import { ACCEPT, RESET } from '@/constants/forms/globalFilterForm'

export const CheckBoxWrapper = styled.div`
  width: 38px;
  height: 100%;
  min-width: 38px;
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
`

export const AlarmToolTip = styled.span`
  position: absolute;
  min-width: 200px;
  visibility: hidden;
  background-color: ${({ theme }) => theme.colors.colors.tooltip};
  text-align: center;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5rem;
  border-radius: 4px;
  padding: 10px;
  position: absolute;
  opacity: 0;
  transition: opacity 1s;
  left: 0px;
  top: 48px;
  &::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 8%;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent ${({ theme }) => theme.colors.colors.tooltip} transparent;
  }
`

export const AlarmButton = styled.div`
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  margin-right: -5px;
  height: 48px;
  z-index: 5;
  &:hover {
    ${AlarmToolTip} {
      visibility: visible;
      opacity: 1;
    }
  }
`

export const Title = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  padding-left: 15px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.colors.default};
  ${({ paddingRight }) => !paddingRight && `
    padding-right: 15px;
  `}
`

export const Button = styled.div`
  cursor: pointer;
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: 2px;
  ${({ reverse }) => reverse && `
    align-items: flex-start;
    margin-top: 2px;
    svg {
      transform-origin: 50% 50%;
      transform: rotate(180deg);
    }
  `}

  ${({ active, theme }) => active && `
    .svgStroke {
      fill: ${theme.colors.colors.svg};
    }
  `}
`

export const ButtonsContainer = styled.div`
  width: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const ContentContainer = styled.div`
  padding: 10px;
  z-index: 110;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  background: ${({ theme }) => theme.colors.backgrounds.dark};
  position: absolute;
  top: 53px;
  min-width: 100%;
`
export const SecondaryTitle = styled.div`
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 3rem;
  text-align: left;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  ${({ right }) => right &&`
   text-align: right;
  `}
`

export const CheckBoxContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${SecondaryTitle} {
    width: 100%;
    padding-left: 10px;
  }
`
export const ContentContainerCheckBox = styled.div`
  padding: 10px 10px 10px 10px;
  z-index: 110;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  background: ${({ theme }) => theme.colors.backgrounds.dark};
  box-shadow: ${({ theme }) => theme.defaultBoxShadow(theme.colors.shadows.widget)};
  position: absolute;
  top: 73px;
  width: 300px;
`
export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  // height: 48px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 5px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  padding-top: 10px;
  border-top: 1px solid ${({ theme }) => theme.colors.borders.default};

`
export const CustomButton = styled(Button)`
  height: 38px;
  box-shadow: ${({ theme }) => theme.colors.shadows.field};
  border-radius: 4px;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  text-transform: none;
  display: flex;
  align-items: center;
  ${({ usage }) => usage === ACCEPT && `
    background: linear-gradient(180deg, #FBBA58 0%, #FF9A02 100%);
    color: ${WHITE};
  `}
  ${({ usage }) => usage === RESET && `
    background: ${GRAY};
    color: ${MINE_SHAFT};
  `}
  &:first-child {
    margin-right: 5px;
  }

  @media (max-width: 910px) {
  ${({ usage }) => usage === ACCEPT && `
    background: #1061DF;
  `}
  width: 165px;
  }
`

export const ContainerCheckBox = styled.div`
  max-height: 300px;
  overflow: auto;
  padding-top: 5px;
  margin-bottom: 5px;
`

export const ButtonFilterWraper = styled.div`
  padding: 0px 10px 0 10px;
`
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
 ${({ padding }) => !padding && `
  padding-top: 10px;
 `}
`
export const DiifContainer = styled.div`
  display: flex;
`
export const DiffValue = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  padding: 0 15px 0 15px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ color }) => color};
  gap: 5px;
`

export const Arrow = styled.div`
  margin-right: 0;
  max-width: 16px;
  max-height: 16px;
  ${({ transform }) => (transform
    ? 'transform: rotate(0deg);'
    : 'transform: rotate(180deg); margin-top: -3px')}
`
