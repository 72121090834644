import React from 'react'
import * as mapTypes from '@/propTypes/maps'
import mapIconst from '@/components/icons/maps'
import ZoneTooltip from '../ZoneTooltip'
import PieChart from '../PieChart'

import {
  ClusterContainerSmall, ClusterContainerMedium, ClusterContainerBig,
  PieChartContainerSmall, PieChartContainerMedium, PieChartContainerBig,
  PieCharOverLaySmall, PieCharOverLayMedium, PieCharOverLayBig,
  TitleSmall, TitleMedium, TitleBig,
} from './styles'

const ZoneIconHover = ({ 
  theme,
  name,
  count,
  statistic,
  onClose,
  isMonitoring,
  isPassportization,
}) => {
  const icon = mapIconst.ClusterIcon
  return (
    <>
      {count <= 99
      && (
        <>
          <ClusterContainerSmall theme={theme}>
            <PieChartContainerSmall theme={theme}>
              <PieChart size="small" statistic={statistic} theme={theme} isPassportization={isPassportization}/>
            </PieChartContainerSmall>
            <PieCharOverLaySmall theme={theme}>
              <TitleSmall theme={theme}>
                {count}
              </TitleSmall>
            </PieCharOverLaySmall>
          </ClusterContainerSmall>
          <ZoneTooltip statistic={statistic} count={count} name={name} theme={theme} type="small" icon={icon} onClose={onClose} isMonitoring={isMonitoring} isPassportization={isPassportization}/>
        </>
      )}
      {count > 99 && count <= 999
      && (
        <>
          <ClusterContainerMedium theme={theme}>
            <PieChartContainerMedium theme={theme}>
              <PieChart size="medium" statistic={statistic} theme={theme} isPassportization={isPassportization}/>
            </PieChartContainerMedium>
            <PieCharOverLayMedium theme={theme}>
              <TitleMedium theme={theme}>
                {count}
              </TitleMedium>
            </PieCharOverLayMedium>
          </ClusterContainerMedium>
          <ZoneTooltip statistic={statistic} count={count} name={name} theme={theme} type="medium" icon={icon} onClose={onClose} isMonitoring={isMonitoring} isPassportization={isPassportization}/>
        </>
      )}
      {count > 999
      && (
        <>
          <ClusterContainerBig theme={theme}>
            <PieChartContainerBig theme={theme}>
              <PieChart size="big" statistic={statistic} theme={theme} isPassportization={isPassportization}/>
            </PieChartContainerBig>
            <PieCharOverLayBig theme={theme}>
              <TitleBig theme={theme}>
                {count}
              </TitleBig>
            </PieCharOverLayBig>
          </ClusterContainerBig>
          <ZoneTooltip statistic={statistic} count={count} name={name} theme={theme} type="big" icon={icon} onClose={onClose} isMonitoring={isMonitoring} isPassportization={isPassportization}/>
        </>
      )}
    </>
  )
}

ZoneIconHover.propTypes = {
  theme: mapTypes.theme.isRequired,
}

export default ZoneIconHover
