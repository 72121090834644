import React, { useEffect, useState } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import isEqual from 'lodash/isEqual'
import { FormattedMessage as Lang } from 'react-intl'
import Icons from '@/components/icons/livetime'
import CheckBox from '@/components/controls/CheckBox'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import HeaderIcons from '@/components/icons/header'
import TextControl from '@/components/controls/TextControl'
import { injectIntl } from 'react-intl'
import UsersIcons from '@/components/icons/users'
import ArrowInventory from '@/components/icons/maps/ArrowInventory'
import { sortOptionsByTitle } from '@/helpers/sortOptionsByTitle'


import {
  Container,
  Title,
  ButtonsContainer,
  Button,
  AlarmButton,
  CheckBoxWrapper,
  CheckBoxContainer,
  SecondaryTitle,
  ContentContainerCheckBox,
  SearchContainer,
  CustomButton,
  ButtonsWrapper,
  ContainerCheckBox,
  ButtonFilterWraper,
  DiifContainer,
  Wrapper,
  DiffValue,
  Arrow,
} from './styles'

const { SortIcon, AlarmIcon } = Icons

const SortBullet = ({
  id,
  name,
  onClick,
  selected,
  showSortButtons,
  handleReadAllAlarms,
  withSelectAll,
  buttonValue,
  showCheckBox,
  isUsersTable,
  fieldOptions,
  intl,
  handleFilterColumn,
  descriptions,
  isOpenFilter,
  setIsOpenFilter,
  hideSortButtons,
  totalResultsInventory,
  diffTotalResultsInventory,
  isInventoryTable,
}) => {
  const { id: selectedId, value: selectedValue } = selected
  const [search, setSearch] = useState('')
  const [selectedFilter, setSelectedFilter] = useState([])
  const [options, setOptions] = useState([])
  const [oldValue, setOldValue] = useState([])
  const handleCheckBox = (value) => {
    if (selectedFilter.some(filter => isEqual(filter, value))) {
      setSelectedFilter(prev => prev.filter(field => !isEqual(field, value)))
    } else  setSelectedFilter(prev => [...prev, value])
  }
  const isFilterActive = () => {
    if (!selectedFilter.length) return false
    return selectedFilter.length < fieldOptions.length
  }

  useEffect(() => {
    setSelectedFilter(!!descriptions.length ? descriptions : fieldOptions)
    setOptions(fieldOptions)
  }, [fieldOptions, descriptions])


  const changeSearch = (value) => {
    setSearch(value)
    setSelectedFilter([])
    if (!value) {
      setOptions(fieldOptions)
      return 
    }
    setOptions(fieldOptions.filter(field => field.title.toString().toLowerCase().includes(value.toLowerCase())))
  }
  
  const setAllSelection = () => {
    if (fieldOptions.length > selectedFilter.length) {
      setSelectedFilter(options)
      return 
    }
    setSelectedFilter([])
  }

  const handleAccept = () => {
    handleFilterColumn(selectedFilter.length === fieldOptions.length ? [] : selectedFilter, id)
    setIsOpenFilter({})
  }
  const HandlerReset = () => {
    setIsOpenFilter({})
    setSelectedFilter(oldValue)
    setSearch('')
    setOptions(fieldOptions)
  }
  const toggleFilter = () => {
    setIsOpenFilter({[id]: true})
    setOldValue(selectedFilter)
    setSearch('')
    setOptions(fieldOptions)
  }

  const renderSettings = () => {
    return (
      <>
      <SearchContainer>
        <TextControl
          dark
          placeholder={intl.messages['mapsPage.titles.search']}
          name="search"
          icon={UsersIcons.MagnifierIcon}
          onChange={changeSearch}
          value={search}
        />
        <CheckBoxContainer onClick={() => setAllSelection()}>
              <CheckBox
                value={fieldOptions.length === selectedFilter.length}
              />
              <SecondaryTitle>
                {<Lang id="buttons.all" />}
              </SecondaryTitle>
              <SecondaryTitle right>
                {`${selectedFilter.length}/${options.length}`}
              </SecondaryTitle>
            </CheckBoxContainer>
      </SearchContainer>
      <ContainerCheckBox>
        {sortOptionsByTitle(options).map((option) => (
            <CheckBoxContainer onClick={() => handleCheckBox(option)} >
              <CheckBox
                value={selectedFilter.some(filter => isEqual(filter, option))}
              />
              <PortalTooltip
              title={option.title}
              renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                <SecondaryTitle
                  ref={wrapperRef}
                  onMouseEnter={onMouseEnterHandler}
                  onMouseLeave={onMouseLeaveHandler}
                >
                  {option.title}
                </SecondaryTitle>
              )}
            />
            </CheckBoxContainer>
        ))}
      </ContainerCheckBox>
      <ButtonsWrapper>
          <CustomButton usage="reset" onClick={HandlerReset} >
            {<Lang id="buttons.cancel" />}
          </CustomButton>
          <CustomButton usage="accept" onClick={() => handleAccept()}>
            {<Lang id="buttons.apply" />}
          </CustomButton>
        </ButtonsWrapper>  
     </>
    )
  }

  const onClickHandler = (value) => () => {
    onClick({
      sortType: id,
      sortValue: value,
    })
  }

  const isActive = (value) => {
    if (id === selectedId && selectedValue === value) {
      return true
    }
    return false
  }

  return (
    <Wrapper padding={!(id !== 'id' && id !== 'registerNumber')}>
    <Container>
      {withSelectAll && showCheckBox && (
        <CheckBoxWrapper>
          <CheckBox
            onChange={handleReadAllAlarms}
            value={buttonValue}
          />
        </CheckBoxWrapper>
      )}
      {withSelectAll && !isUsersTable && (
        <PortalTooltip
          title={(<Lang id="alarmManager.alarmToolTip" />)}
          renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
            <AlarmButton
              ref={wrapperRef}
              onMouseEnter={onMouseEnterHandler}
              onMouseLeave={onMouseLeaveHandler}
              onClick={handleReadAllAlarms}
            >
              <AlarmIcon />
            </AlarmButton>
          )}
        />
      )}
      <Title paddingRight={!fieldOptions.length && showSortButtons && hideSortButtons}>
        {name}
      </Title>
      {!!fieldOptions.length && (
        <>
        <ButtonFilterWraper onClick={toggleFilter}>
         {isFilterActive() ? 
            <HeaderIcons.ActiveGlobalFilterIcon /> 
              : 
            <HeaderIcons.GlobalFilterIcon /> 
          }
        </ButtonFilterWraper>
          {isOpenFilter && (
            <ContentContainerCheckBox>
              {renderSettings()}
            </ContentContainerCheckBox>
          )}
        </>
      )}
      {!fieldOptions.length && showSortButtons && hideSortButtons && (
        <ButtonsContainer>
          <Button
            onClick={onClickHandler('ASC')}
            active={isActive('ASC')}
          >
            <SortIcon />
          </Button>
          <Button
            reverse
            onClick={onClickHandler('DESC')}
            active={isActive('DESC')}
          >
            <SortIcon />
          </Button>
        </ButtonsContainer>
      )}
    </Container>
    {isInventoryTable && (
      <>
      { id !== 'id' && id !== 'registerNumber' && (
      <DiifContainer>
      <Title>
        {totalResultsInventory}
      </Title>
      {(diffTotalResultsInventory !== 0) && diffTotalResultsInventory &&  (
        <DiffValue color={diffTotalResultsInventory > 0 ? 'rgb(144, 199, 55)' : 'rgb(248, 61, 20)'}>
          <Arrow transform={diffTotalResultsInventory > 0}>
            <ArrowInventory color={diffTotalResultsInventory > 0 ? 'rgb(144, 199, 55)' : 'rgb(248, 61, 20)'}/>
          </Arrow>
          {Math.abs(diffTotalResultsInventory)}
        </DiffValue>
      )}
      </DiifContainer>
    )}
      </>
    )
      
    }
 
    </Wrapper>
  )
}


SortBullet.propTypes = {
  id: pt.oneOfType([pt.number, pt.string]),
  name: pt.string,
  onClick: pt.func,
  selected: pt.shape({
    id: pt.oneOfType([pt.number, pt.string]),
    value: pt.oneOfType([pt.number, pt.string]),
  }),
  showSortButtons: pt.bool,
  showAlarmButton: pt.bool,
}

SortBullet.defaultProps = {
  id: null,
  name: null,
  onClick: noop,
  selected: {},
  showSortButtons: true,
  showAlarmButton: false,
}

export default injectIntl(SortBullet)
