import passportSHUO from './Паспорт ШУНО Unilight.pdf'
import passportLampModule from './Паспорт Модуль Unilight NEMA.pdf'
import passportGaleonS from './Паспорт на Галеон серии S.pdf'
import passportGaleonL from './Паспорт на Галеон серии L.pdf'
import passportGalad from './Паспорт_светильник GALAD_Волна_LED-150-ШБ.pdf'
import passportGaleonSLed from './Паспорт на Галеон серии S LED.pdf'
import passportSHUOArt from './Паспорт ШУНО АХП.pdf'
import baseStation from './Паспорт Базовая станция.pdf'
import passportAvrora from './Паспорт GALAD Аврора LED.pdf'
import passportVega from './Паспорт GALAD Вега LED.pdf'
import passportIlluminator from './Паспорт GALAD Иллюминатор LED.pdf'
import passportKontur from './Паспорт GALAD Контур LED.pdf'
import passportTandem from './Паспорт GALAD Тандем LED.pdf'



import { 
  LIGHT_FIXTURE_GALEON_S, 
  LIGHT_FIXTURE_GALEON_L, 
  CONTROL_CUPBOARD, 
  LAMP_MODULE, 
  LIGHT_FIXTURE_GALAD, 
  LIGHT_FIXTURE_GALEON_S_LED, 
  CONTROL_CUPBOARD_ART,
  BASE_STATION,
  LIGHT_FIXTURE_AVRORA_ART,
  LIGHT_FIXTURE_VEGA_ART,
  LIGHT_FIXTURE_ILLUMINATOR_ART,
  LIGHT_FIXTURE_KONTUR_ART,
  LIGHT_FIXTURE_TANDEM_ART,
} from '@/constants/instalationPassport/types'

export default  {
  [CONTROL_CUPBOARD]: passportSHUO,
  [LAMP_MODULE]: passportLampModule,
  [LIGHT_FIXTURE_GALEON_S]: passportGaleonS,
  [LIGHT_FIXTURE_GALEON_L]: passportGaleonL,
  [LIGHT_FIXTURE_GALAD]: passportGalad,
  [LIGHT_FIXTURE_GALEON_S_LED]: passportGaleonSLed,
  [CONTROL_CUPBOARD_ART]: passportSHUOArt,
  [BASE_STATION] : baseStation,
  [LIGHT_FIXTURE_AVRORA_ART]: passportAvrora,
  [LIGHT_FIXTURE_VEGA_ART]: passportVega,
  [LIGHT_FIXTURE_ILLUMINATOR_ART]: passportIlluminator,
  [LIGHT_FIXTURE_KONTUR_ART]: passportKontur,
  [LIGHT_FIXTURE_TANDEM_ART]: passportTandem,
}