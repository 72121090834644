import control_cupboard from './control_cupboard.jpg'
import utility_pole from './utility_pole.jpg'
import line from './line.jpg'
import lamp_module from './lamp_module.jpg'
import light_fixture from './light_fixture.jpg'
import light_fixture_DNAT from './light_fixture_DNAT.jpeg'
import light_fixture_galeon_S from './light_fixture_galeon_S.jpg'
import light_fixture_galeon_L from './light_fixture_galeon_L.jpg'
import light_fixture_GALAD from './light_fixture_GALAD.png'
import line_art from './Линия АХП.jpg'
import base_station from './Базовая станция.JPG'
import light_fixture_avrora_Art from './GALAD Аврора LED.jpg'
import light_fixture_vega_Art from './GALAD Вега LED.jpg'
import light_fixture_illuminator_Art from './GALAD Иллюминатор LED.jpg'
import light_fixture_kontur_Art from './GALAD Контур LED.jpg'
import light_fixture_tandem_Art from './GALAD Тандем LED.jpg'


// production only
import control_cupboard_prod from './productionOnly/control_cupboard.png'
import line_prod from './productionOnly/line.png'
import utility_pole_concrete from './productionOnly/utility_pole_concrete.png'
import utility_pole_metal from './productionOnly/utility_pole_metal.png'
import utility_pole_wood from './productionOnly/utility_pole_wood.png'
import light_fixture_console_led from './productionOnly/light_fixture_console_led.png'
import light_fixture_console_park_dnat from './productionOnly/light_fixture_console_park_dnat.png'
import light_fixture_console_zku from './productionOnly/light_fixture_console_zku.png'
import light_fixture_floor_led from './productionOnly/light_fixture_floor_led.png'
import light_fixture_floor_zku from './productionOnly/light_fixture_floor_zku.png'
import light_fixture_spotlight_led from './productionOnly/light_fixture_spotlight_led.png'
import light_fixture_suspended_led from './productionOnly/light_fixture_suspended_led.png'
import light_fixture_suspended_zku from './productionOnly/light_fixture_suspended_zku.png'
import light_fixture_wall_led from './productionOnly/light_fixture_wall_led.png'
import light_fixture_wall_zku from './productionOnly/light_fixture_wall_zku.png'

import {
  CONTROL_CUPBOARD,
  LIGHT_FIXTURE,
  LINE,
  LAMP_MODULE,
  UTILITY_POLE,
  LIGHT_FIXTURE_GALEON_S,
  LIGHT_FIXTURE_GALEON_L,
  LIGHT_FIXTURE_GALAD,
  UTILITY_POLE_CONCRETE,
  UTILITY_POLE_METAL,
  UTILITY_POLE_WOOD,
  LIGHT_FIXTURE_CONSOLE_LED,
  LIGHT_FIXTURE_CONSOLE_PARK_DNAT,
  LIGHT_FIXTURE_CONSOLE_ZKU,
  LIGHT_FIXTURE_FLOOR_LED,
  LIGHT_FIXTURE_FLOOR_ZKU,
  LIGHT_FIXTURE_SPOTLIGHT_LED,
  LIGHT_FIXTURE_SUSPENDED_LED,
  LIGHT_FIXTURE_SUSPENDED_ZKU,
  LIGHT_FIXTURE_WALL_LED,
  LIGHT_FIXTURE_WALL_ZKU,
  LINE_ART,
  BASE_STATION,
  LIGHT_FIXTURE_AVRORA_ART,
  LIGHT_FIXTURE_VEGA_ART,
  LIGHT_FIXTURE_ILLUMINATOR_ART,
  LIGHT_FIXTURE_KONTUR_ART,
  LIGHT_FIXTURE_TANDEM_ART,
} from '@/constants/instalationPassport/types'

export const defaultImage = {
  [CONTROL_CUPBOARD]: control_cupboard,
  [UTILITY_POLE]: utility_pole,
  [LINE]: line,
  [LAMP_MODULE]: lamp_module,
  [LIGHT_FIXTURE]: light_fixture,
  [LINE_ART]: line_art,
  [BASE_STATION]: base_station,
}
// SLS-1911
export const prodDefaultImage = {
  [CONTROL_CUPBOARD]: control_cupboard_prod,
  [LINE]: line_prod,
  [LAMP_MODULE]: lamp_module,
  [BASE_STATION]: base_station,
}

export const lightFixtureImage = {
  [LIGHT_FIXTURE]: light_fixture_DNAT,
  [LIGHT_FIXTURE_GALEON_S]: light_fixture_galeon_S,
  [LIGHT_FIXTURE_GALEON_L]: light_fixture_galeon_L,
  [LIGHT_FIXTURE_GALAD]: light_fixture_GALAD,
}

export const lightFixtureArtImage = {
  [LIGHT_FIXTURE_AVRORA_ART]: light_fixture_avrora_Art,
  [LIGHT_FIXTURE_VEGA_ART]: light_fixture_vega_Art,
  [LIGHT_FIXTURE_ILLUMINATOR_ART]: light_fixture_illuminator_Art,
  [LIGHT_FIXTURE_KONTUR_ART]: light_fixture_kontur_Art,
  [LIGHT_FIXTURE_TANDEM_ART]: light_fixture_tandem_Art,
}
// SLS-1911
export const productionOnlyImage = {
  [UTILITY_POLE_CONCRETE]: utility_pole_concrete,
  [UTILITY_POLE_METAL]: utility_pole_metal,
  [UTILITY_POLE_WOOD]: utility_pole_wood,
  [LIGHT_FIXTURE_CONSOLE_LED]: light_fixture_console_led,
  [LIGHT_FIXTURE_CONSOLE_PARK_DNAT]: light_fixture_console_park_dnat,
  [LIGHT_FIXTURE_CONSOLE_ZKU]: light_fixture_console_zku,
  [LIGHT_FIXTURE_FLOOR_LED]: light_fixture_floor_led,
  [LIGHT_FIXTURE_FLOOR_ZKU]: light_fixture_floor_zku,
  [LIGHT_FIXTURE_SPOTLIGHT_LED]: light_fixture_spotlight_led,
  [LIGHT_FIXTURE_SUSPENDED_LED]: light_fixture_suspended_led,
  [LIGHT_FIXTURE_SUSPENDED_ZKU]: light_fixture_suspended_zku,
  [LIGHT_FIXTURE_WALL_LED]: light_fixture_wall_led,
  [LIGHT_FIXTURE_WALL_ZKU]: light_fixture_wall_zku,
}