import React, {
  useState,
  useMemo,
  useCallback,
} from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import get from 'lodash/get'
import { FormattedMessage as Lang } from 'react-intl'
import memoWithName from '@/hocs/memoWithName'
import useOutsideClickHandler from '@/hooks/useOutsideClickHandler'
import HeaderIcons from '@/components/icons/header'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import { APP_BY_WIDGET_ID } from '@/constants/widgetShopApp/names'
import {
  ANALYTICS_ALARMS,
  TYUMEN_OUR_HOME,
} from '@/constants/widgetsShop/names'
import DescForm from '@/forms/DescForm'
import { WIDGET_SHOP_WIDGET } from '@/constants/names'
import {
  SUPPORT,
} from '@/constants/applications'
import AnalyticsAlarms from './components/AnalyticsAlarms'
import TyumenOurHome from './components/TyumenOurHome'
import {
  StyledSidebar,
  Title,
  HeaderRow,
  IconWrapper,
  HeaderContentWrapper,
  TreeContainer,
  TreeWrapper,
} from './styles'

const WidgetSideBar = ({
  isOpen,
  onClose,
  tree,
  cityProcesses,
  widgetId,
  widgetType,
  onDeleteWidget,
  onSetSettings,
  selectedWidgetType,
  addIntegration,
  requestGetInfoWidget,
  requestDeleteIntegration,
  serviceDeskStatus,
  data,
  loggedInUser,
  userApplications,
  setUserServiceDesk,
  isCityPortal,
}) => {
  const ref = React.createRef()
  useOutsideClickHandler({
    ref,
    callback: onClose,
    opened: isOpen,
  })

  const userRoles = useMemo(() => get(loggedInUser, 'roles', {}), [loggedInUser])

  const [deleteWidget, setDeleteWidget] = useState(false)
  const handleSetDeleteWidget = useCallback((value) => () => {
    setDeleteWidget(value)
  }, [])

  const handleDeleteWidget = useCallback(() => {
    const currentServiceDeskStatus = serviceDeskStatus.find(
      (status) => widgetId === status.temporaryId || widgetId === status.widgetId,
    )
    if (currentServiceDeskStatus && userRoles.isSuperAdmin && selectedWidgetType === SUPPORT) {
      requestDeleteIntegration({ widgetId })
    }
    onDeleteWidget(widgetId, widgetType, get(currentServiceDeskStatus, 'id', null))
    setDeleteWidget(false)
    onClose()
  },
  [
    onDeleteWidget,
    widgetId,
    setDeleteWidget,
    onClose,
    widgetType,
    requestDeleteIntegration,
    serviceDeskStatus,
    userRoles.isSuperAdmin,
    selectedWidgetType,
  ])

  const onSelectSettings = useCallback((settings) => {
    onSetSettings(widgetId, settings, widgetType)
    setDeleteWidget(false)
    onClose()
  },
  [
    widgetId,
    widgetType,
    onSetSettings,
    setDeleteWidget,
    onClose,
  ])

  const handleCloseWidget = useCallback(() => {
    setDeleteWidget(false)
    onClose()
  },
  [setDeleteWidget, onClose])

  const renderHeader = useMemo(() => {
    if (deleteWidget) {
      return (
        <HeaderRow>
          <Title deleteWidget>
            {widgetType === WIDGET_SHOP_WIDGET
              ? (<Lang id="sideBar.deleteWidget" />)
              : (<Lang id="sideBar.deleteApplication" />)}
          </Title>
          <IconWrapper type="green" onClick={handleSetDeleteWidget(false)}>
            <Lang id="usersPage.formHeader.no" />
          </IconWrapper>
          <IconWrapper type="red" onClick={handleDeleteWidget}>
            <Lang id="usersPage.formHeader.yes" />
          </IconWrapper>
        </HeaderRow>
      )
    }
    return (
      <HeaderRow>
        <HeaderContentWrapper>
          <Title>
            {widgetType === WIDGET_SHOP_WIDGET
              ? (<Lang id="sideBar.widgetTitle" />)
              : (<Lang id="sideBar.applicationTitle" />)}
          </Title>
        </HeaderContentWrapper>
        <PortalTooltip
          title={(<Lang id="tooltip.remove" />)}
          renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
            <IconWrapper
              ref={wrapperRef}
              onMouseEnter={onMouseEnterHandler}
              onMouseLeave={onMouseLeaveHandler}
              onClick={handleSetDeleteWidget(true)}
            >
              <HeaderIcons.DeleteIcon />
            </IconWrapper>
          )}
        />
      </HeaderRow>
    )
  }, [deleteWidget, widgetType, handleDeleteWidget, handleSetDeleteWidget])

  const renderContainer = useMemo(() => {
    if (selectedWidgetType === ANALYTICS_ALARMS) {
      return (
        <TreeWrapper>
          <AnalyticsAlarms
            tree={tree}
            onSelectSettings={onSelectSettings}
          />
        </TreeWrapper>
      )
    }
    if (selectedWidgetType === TYUMEN_OUR_HOME) {
      return (
        <TreeWrapper>
          <TyumenOurHome
            processes={cityProcesses}
            onSelectSettings={onSelectSettings}
          />
        </TreeWrapper>
      )
    }
    if (selectedWidgetType === APP_BY_WIDGET_ID.SUPPORT) {
      const currentServiceDeskStatus = serviceDeskStatus.find(
        (status) => (
          userRoles.isSuperAdmin
            ? widgetId === status.temporaryId
            : widgetId === status.widgetId
        ),
      )
      return (
        <DescForm
          data={data}
          addIntegration={addIntegration}
          requestGetInfoWidget={requestGetInfoWidget}
          widgetId={widgetId}
          widgetType={widgetType}
          onSetSettings={onSetSettings}
          handleCloseWidget={handleCloseWidget}
          requestDeleteIntegration={requestDeleteIntegration}
          currentServiceDeskStatus={currentServiceDeskStatus}
          isSuperAdmin={userRoles.isSuperAdmin}
          userApplications={userApplications}
          setUserServiceDesk={setUserServiceDesk}
          serviceDeskStatus={serviceDeskStatus}
          isCityPortal={isCityPortal}
        />
      )
    }
  }, [
    selectedWidgetType,
    tree,
    onSelectSettings,
    cityProcesses,
    addIntegration,
    requestGetInfoWidget,
    widgetId,
    handleCloseWidget,
    data,
    widgetType,
    onSetSettings,
    requestDeleteIntegration,
    serviceDeskStatus,
    userRoles.isSuperAdmin,
    userApplications,
    setUserServiceDesk,
    isCityPortal,
  ])

  return (
    <StyledSidebar ref={ref} opened={isOpen} onClose={onClose} over position="right">
      {isOpen && (
        <>
          {renderHeader}
          <TreeContainer isSuperAdmin={userRoles.isSuperAdmin}>
            {renderContainer}
          </TreeContainer>
        </>
      )}
    </StyledSidebar>
  )
}

WidgetSideBar.propTypes = {
  selectedWidgetType: pt.string,
  isOpen: pt.bool.isRequired,
  onClose: pt.func.isRequired,
  onDeleteWidget: pt.func,
  onSetSettings: pt.func,
  widgetId: pt.string,
}
WidgetSideBar.defaultProps = {
  widgetId: null,
  onDeleteWidget: noop,
  onSetSettings: noop,
  selectedWidgetType: null,
}

export default memoWithName(WidgetSideBar)
