import { GET_COMPOSITION_NODE } from './index'

export const requestGetCompositionNode = (payload) => ({
  type: GET_COMPOSITION_NODE.REQUEST,
  payload,
})

export const successGetCompositionNode = (payload) => ({
  type: GET_COMPOSITION_NODE.SUCCESS,
  payload,
})

export const errorGetCompositionNode = (payload) => ({
  type: GET_COMPOSITION_NODE.ERROR,
  payload,

})
