import styled from 'styled-components'
import Button from '@/components/blocks/Button'
import { LIGHT_THEME_NAME } from '@/constants/names'



export const Main = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
  position: relative;
`

export const MapsContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const Header = styled.div`
  box-sizing: border-box;
  min-height: 48px;
  width: 100%;
  display: flex;
  justify-content: flex-start;  
  align-items: center  
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  max-height: 48px;
  padding-right: 15px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
`

export const ButtonContainer = styled.div`

`

export const TakeInventoryButton = styled(Button)`
  width: 100%;
  padding: 7px;
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.colors.light};
  background: ${({ theme }) => theme.colors.scheme.backgrounds.fuelYellow};
  text-transform: inherit;
  :hover {
    background: ${({ theme }) => theme.colors.scheme.backgrounds.fuelYellow};
  }
`

export const HeaderRow = styled.div`
  box-sizing: border-box;
  height: 48px;
  display: flex;
  justify-content: space-between
  align-items: center
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  max-height: 48px;
  padding-right: 15px;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  ${({ gradient, theme }) => gradient && `
    background: ${theme.colors.backgrounds.redisign.topDownGradient};
  `}
`

export const InventoryForm = styled.div`
  min-width: ${({ theme }) => theme.sizes.sideBarViewTreeMin}px;
  width: 300px;
  flex: 0 0 auto;
  height: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.dark};
  border-right: 1px solid ${({ theme }) => theme.colors.borders.default};
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
`

export const InventoryFormContent = styled.div`

`

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.colors.default};
  margin-left: 10px;
  font-size: 1.3rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: pre-wrap;
  text-align: left;
  ${({ theme }) => theme.themeName === LIGHT_THEME_NAME && `
    color:${theme.colors.colors.redisign.default};
  `}
`

export const CloseIconContainer = styled.div`
  cursor: pointer;
`
export const ProjectNameContainer = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.colors.default};
  font-size: 1.3rem;
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-left: 10px;
  margin-top: 10px;
`

export const ProjectNameWrapper = styled.div`
  margin-left: 10px;
  font-weight: ${({ theme }) => theme.fontWeights.light};

`



export const DateInventoryContainer = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.colors.default};
  font-size: 1.3rem;
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  margin-left: 10px;
  margin-top: 10px;
`
export const DateInventoryWrapper = styled.div`
`

export const DatePickerContainer = styled.div`
  display: flex;
  margin-right: 10px;
`

export const DecorElement = styled.div`
  padding: 5px;
`

export const CreateInventoryButton = styled(Button)`
  width: 100%;
  padding: 7px;
  color: ${({ theme }) => theme.colors.colors.light};
  background: ${({ theme }) => theme.colors.scheme.backgrounds.fuelYellow};
  text-transform: inherit;
  :hover {
    background: ${({ theme }) => theme.colors.scheme.backgrounds.fuelYellow};
  }
`

export const ButtonWrapper = styled.div`
  margin-right: 10px;
`

export const InventoryContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`

export const TableContainer = styled.div`
  width: calc(100% - 300px);
  height: 100%;
`

export const ToolTipInventory = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 30.72px;
  color: rgba(62, 60, 62, 0.6);
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
`

export const ToolTipInventoryTitle = styled.div`
  width: 320px;
`

export const LoaderWrapper = styled.div`
  margin-top: 5px;
`