import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import moment from 'moment'
import request from '@/helpers/axios'
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob'
import createNotifications from '@/helpers/notification'
import {
  GET_ALARM_MANAGER_FILE,
} from '@/store/actions/alarmManager'
import {
  ALARM_MANAGER_REPORT_TABLE_FILE_ENDPOINT,
} from '@/constants/apiRoutes'
import {
  successAlarmManagerFile,
  errorAlarmManagerFile,
} from '@/store/actions/alarmManager/getFile'
import { getSelectedNode } from '@/store/selectors/appSettings'
import { getTableParameters } from '@/store/selectors/alarmManager'
import getIsReadByStatus from "@/helpers/alarmsHelper";
import { getAlarmsFilters } from '@/store/selectors/appSettings'


function* getAlarmManagerFileSaga({ payload }) {
  const toast = createNotifications()
  try {
    const node = yield select(getSelectedNode)
    const tableParameters = yield select(getTableParameters)
    const { eventTypes, eventStatus, dates, descriptions } = yield select(getAlarmsFilters)


    const response = yield request({
      url: ALARM_MANAGER_REPORT_TABLE_FILE_ENDPOINT({
        params: {
          alertTypes: eventTypes,
          excludeColumns: payload.allColumns.filter(x => !tableParameters.activeFields.includes(x)),
          occurrenceDateFrom: dates.start ? moment(dates.start).format('DD.MM.yyyy HH:mm') : null,
          occurrenceDateTo: dates.end ? moment(dates.end).format('DD.MM.yyyy HH:mm') : null,
          parentId: node.id,
          pageNumber: tableParameters.page - 1,
          pageSize: tableParameters.perPage,
          name: tableParameters.requestQuery || '',
          sort: [`${tableParameters.sort.field}: ${tableParameters.sort.direction}`],
          isRead: getIsReadByStatus(eventStatus),
          descriptions: descriptions?.map(item => item.value),
        },
        format: payload.value
      }),
      method: 'get',
      options: {
        responseType: 'blob',
      },
    })

    downloadFileFromBlob({
      blob: response.data,
      fileName: `SLS24-${'all'}_alarm_table_report.${payload.value}`,
    })
    yield put(successAlarmManagerFile())
  } catch (error) {
    yield put(errorAlarmManagerFile(error))
    toast({
      title: 'Ошибка операции!',
      description: 'Невозможно выгрузить отчет.\nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_ALARM_MANAGER_FILE.REQUEST, getAlarmManagerFileSaga)
}
