import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import {
  getRegions,
} from '@/store/selectors/maps/socketEvents'
import {
  getPassportPhoto,
  getPassportFile,
  getOperatedElement,
  getSelectedElementEquipment,
  // getPassportData,
  // getCardData,
  getData,
  getTreeData,
  getPins,
  getZones,
  getLoading,
  getRequestCardStatus,
  getActiveFieldsPassport,
  getCompositionNode,
  getCompositionNodeStatus,
} from '@/store/selectors/passportization'
import { 
  setUnpinNode, 
  setSelectedNode, 
} from '@/store/actions/appSettings'
import {
  getSelectedNode,
  getPinnedNode,
  getUserApplications,
  getUserData,
  getGlobalFilters,
  getMapLayer,
  getIsGlobalFilterOpen,
  getToggleRussianBorders,
  getGlobalLoading,
} from '@/store/selectors/appSettings'
import {
  requestAddPassportFile,
} from '@/store/actions/passportization/addPassportFile'
import {
  requestGetPassportFile,
} from '@/store/actions/passportization/getPassportFile'
import {
  requestAddPassportPhoto,
} from '@/store/actions/passportization/addPassportPhoto'
import {
  requestDeletePassportFile,
} from '@/store/actions/passportization/deletePassportFile'
import {
  requestDeletePassportPhoto,
} from '@/store/actions/passportization/deletePassportPhoto'
import {
  requestExportPassportFile,
} from '@/store/actions/passportization/exportPassportFile'
import {
  requestUpdatePassport,
} from '@/store/actions/passportization/updatePassport'
import {
  setOperatedElement,
  requestGetObjectEquipment,
  requestActiveFieldsPassport,
} from '@/store/actions/passportization'

import Passportization from './component'
import { getClient } from '@/store/selectors/socket'
import { requestGetPinsAndZonesAndTelemetry } from '@/store/actions/installation/getZonesAndPinsAndTelemetry'
import {
  getConnectedLinesCoordinates,
  getDisplayElementAndTelemetryRequestStatus,
  getDisplayElements,
  getObjectConnectedIdentifiers,
  getRoot,
} from '@/store/selectors/installation'
import { requestGetObjectConnectedIdentifiers } from '@/store/actions/installation/getObjectConnectedIdentifiers'
import { requestGetConnectedLinesCoordinates } from '@/store/actions/installation/getConnectedLinesCoordinates'
import { requestGetCompositionNode } from '@/store/actions/passportization/getCompositionNode'
import { getGoToPassportization } from '@/store/selectors/ppr'


const mapStateToProps = (state) => ({
  roots: getRoot(state),
  treeData: getTreeData(state),
  regions: getRegions(state),
  pins: getPins(state),
  loading: getLoading(state),
  requestCardStatus: getRequestCardStatus(state),
  operatedElement: getOperatedElement(state),
  selectedNode: getSelectedNode(state),
  selectedElementEquipment: getSelectedElementEquipment(state),
  // passportData: getPassportData(state),
  // cardData: getCardData(state),
  data: getData(state),
  passportFile: getPassportFile(state),
  passportPhoto: getPassportPhoto(state),
  zones: getZones(state),
  pinnedNode: getPinnedNode(state),
  userApplications: getUserApplications(state),
  userData: getUserData(state),
  client: getClient(state),
  displayElements: getDisplayElements(state),
  displayElementAndTelemetryRequestStatus: getDisplayElementAndTelemetryRequestStatus(state),
  globalFilters: getGlobalFilters(state),
  objectConnectedIdentifiers: getObjectConnectedIdentifiers(state),
  connectedLinesCoordinates: getConnectedLinesCoordinates(state),
  mapLayer: getMapLayer(state),
  isGlobalFilterOpen: getIsGlobalFilterOpen(state),
  isRussianBorders: getToggleRussianBorders(state),
  activeFieldsPassport: getActiveFieldsPassport(state),
  globalLoading: getGlobalLoading(state),
  goToPassportization: getGoToPassportization(state),
  compositionNode: getCompositionNode(state),
  getCompositionNodeStatus: getCompositionNodeStatus(state),
})

const mapDispatchToProps = (dispatch) => ({
  requestAddPassportFile: (payload) => dispatch(requestAddPassportFile(payload)),
  requestGetPassportFile: (payload) => dispatch(requestGetPassportFile(payload)),
  requestAddPassportPhoto: (payload) => dispatch(requestAddPassportPhoto(payload)),
  requestDeletePassportFile: (payload) => dispatch(requestDeletePassportFile(payload)),
  requestDeletePassportPhoto: (payload) => dispatch(requestDeletePassportPhoto(payload)),
  requestExportPassportFile: (payload) => dispatch(requestExportPassportFile(payload)),
  requestUpdatePassport: (payload) => dispatch(requestUpdatePassport(payload)),
  setNode: (data, withoutPinned) => dispatch(setSelectedNode(data, withoutPinned)),
  setOperatedElement: (data) => dispatch(setOperatedElement(data)),
  requestGetObjectEquipment: (data) => dispatch(requestGetObjectEquipment(data)),
  requestGetPinsAndZonesAndTelemetry: (payload) => dispatch(requestGetPinsAndZonesAndTelemetry(payload)),
  requestGetObjectConnectedIdentifiers: (payload) => dispatch(requestGetObjectConnectedIdentifiers(payload)),
  requestGetConnectedLinesCoordinates: (payload) => dispatch(requestGetConnectedLinesCoordinates(payload)),
  onSetSelectedNode: (node) => dispatch(setSelectedNode(node)),
  onSetUnpinNode: (node) => dispatch(setUnpinNode(node)),
  requestActiveFieldsPassport: (payload) => dispatch(requestActiveFieldsPassport(payload)),
  requestGetCompositionNode: (payload) => dispatch(requestGetCompositionNode(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Passportization))
