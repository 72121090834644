import styled from 'styled-components'

export const CellContainer = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.4rem;
  max-height: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
  div:not(:last-child) {
    border-bottom: 1px solid #BDC3C7;
    padding-bottom: 5px;
  }
`
 
