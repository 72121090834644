import React from 'react'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import memoWithName from '@/hocs/memoWithName'
import UsersIcons from '@/components/icons/users'
import Sidebar from '@/components/regions/sidebars/Sidebar'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import BigWidthChildren from '@/components/blocks/BigWidthChildren'
import { WIDGETS_CONFIG } from '@/constants/widgets'
import WIDGETSHOP_APP_ITEMS from '@/constants/widgetShopApp'
import { isUserHasPermission } from '@/helpers/userPermissions'
import {
  ColumnsContainer,
  Row,
  Icon,
  Title,
  FoldButton,
  StyledLink,
  TitleContainer,
  TitleRow,
} from './styles'

const MenuSidebar = ({
  items,
  user,
  applications,
  isSideBarMenuPinned,
  pinnedSideBarMenu,
  isMenuOpen,
  onToggleMenu,
}) => {
  const ref = React.useRef()

  const onChangeMenuState = () => {
    if (isMenuOpen) {
      pinnedSideBarMenu(true)
    }
    if (isSideBarMenuPinned) {
      pinnedSideBarMenu(false)
      onToggleMenu(false)
    }
  }

  const onClose = () => {
    if (!isSideBarMenuPinned) {
      onToggleMenu(false)
    }
  }

  const onOpen = () => {
    if (!isSideBarMenuPinned) {
      onToggleMenu(true)
    }
  }
  
  return (
    <Sidebar
      ref={ref}
      small={!(isSideBarMenuPinned || isMenuOpen)}
      withoutOutsideClick
      opened
      onMouseEnter={onOpen}
      onMouseLeave={onClose}
    >
      <ColumnsContainer>
        {items.map((item) => {
          if (
            (item.applicationId)
            && !isUserHasPermission(user, item.applicationId)
          ) {
            return null
          }
          const MenuIcon = item.menuIcon
          return (
            <StyledLink key={item.name} to={item.path || '#'}>
              <PortalTooltip
                title={!(isSideBarMenuPinned || isMenuOpen) ? item.title : null}
                renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                  <Row
                    ref={wrapperRef}
                    onMouseEnter={onMouseEnterHandler}
                    onMouseLeave={onMouseLeaveHandler}
                  >
                    <Icon><MenuIcon /></Icon>
                    {(isMenuOpen || isSideBarMenuPinned) && (<Title>{item.title}</Title>)}
                  </Row>
                )}
              />
            </StyledLink>
          )
        })}
        {applications.map((item) => {
          const applicationFromConfig = WIDGETSHOP_APP_ITEMS
            .find((widget) => widget.id === item.type)
          const application = {
            ...applicationFromConfig,
            ...item,
          }
          if (
            (application.applicationId)
            && !isUserHasPermission(user, application.applicationId)
          ) {
            return null
          }
          const MenuIcon = application.menuIcon

          return (
            <StyledLink key={application.id} to={application.path || '#'}>
              <BigWidthChildren
                renderChildren={(childrenRef, isBigWidthChildren) => (
                  <PortalTooltip
                    title={isBigWidthChildren ? application.title : null}
                    renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                      <Row
                        ref={wrapperRef}
                        onMouseEnter={onMouseEnterHandler}
                        onMouseLeave={onMouseLeaveHandler}
                      >
                        {MenuIcon && <Icon><MenuIcon /></Icon>}
                        <TitleRow ref={childrenRef} showTitle={!(isMenuOpen || isSideBarMenuPinned)}>
                          <Title isBigChildren={isBigWidthChildren}>
                            {application.titleMenu || application.title}
                          </Title>
                        </TitleRow>
                      </Row>
                    )}
                  />
                )}
              />
            </StyledLink>
          )
        })}
      </ColumnsContainer>
      <FoldButton onClick={onChangeMenuState}>
        <Icon><UsersIcons.ListIcon /></Icon>
        {(isMenuOpen || isSideBarMenuPinned) && (
          <TitleContainer>
            <Title>
              {isSideBarMenuPinned
                ? (<Lang id="sideBar.unpinMenu" />)
                : (<Lang id="sideBar.pinMenu" />)
              }
            </Title>
          </TitleContainer>
        )}
      </FoldButton>
    </Sidebar>
  )
}

MenuSidebar.defaultProps = {
  items: WIDGETS_CONFIG,
}

MenuSidebar.propTypes = {
  items: pt.arrayOf(pt.object),
  pinnedSideBarMenu: pt.func.isRequired,
  isSideBarMenuPinned: pt.bool.isRequired,
  isMenuOpen: pt.bool.isRequired,
  onToggleMenu: pt.func.isRequired,
  user: pt.shape({
    modules: pt.arrayOf(pt.number),
    roles: pt.shape({
      isSuperAdmin: pt.bool,
    }),
  }).isRequired,
}

export default memoWithName(MenuSidebar)
