import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { SCHEDULE_MANAGER_SCHEDULE_ENDPOINT } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { GET_SCHEDULE } from '@/store/actions/scheduleManager'
import {
  errorGetSchedule,
  successGetSchedule
} from '@/store/actions/scheduleManager/getSchedule'
import moment from 'moment/moment'

function* getScheduleSaga({ payload }) {
  const { id } = payload
  try {
    const { data } = yield request({
      url: SCHEDULE_MANAGER_SCHEDULE_ENDPOINT(id),
      method: 'get',
    })
    const formattedData = {
      id: data.id,
      geoZoneId: data.geoZoneId,
      comment: data.comment,
      deviceType: data.equipmentType,
      name: data.name,
      phase: data.shunoPhases,
      timezone: data.timeZone * 60,
      scheduleWorkingHours: data.scheduleWorkingHours,
      tasks: data.actions.sort((a, b) => a.id - b.id).map((action, index) => {
        return {
          id: index,
          beIndex: action.id,
          startDate: moment.utc(action.fromDate).format('DD.MM.YYYY'),
          endDate: moment.utc(action.toDate).format('DD.MM.YYYY') || '',
          originalStartDate: moment.utc(action.fromDate).format('MM.DD.YYYY'),
          originalEndDate: moment.utc(action.toDate).format('MM.DD.YYYY'),
          fromDate: moment.utc(action.fromDate).format('YYYY.MM.DD'),
          toDate: moment.utc(action.toDate).format('YYYY.MM.DD'),
          name: action.name,
          title: action.name,
          value: action.name,
          workingHours: action.workingHours,
          dailyWorkingHours: action.dailyWorkingHours,
          showCheckbox: action.showCheckbox,
          actions: action.subActions.sort((a, b) => a.id - b.id).map((subAction, index) => {
            return {
              id: index,
              beIndex: subAction.id,
              canDelete: subAction.canDelete,
              disabled: subAction.disabled,
              timeDisabled: !index,
              hours: subAction.time.split(':')[0],
              minutes: subAction.time.substr(-2),
              percent: data.equipmentType === 'RM'
                ? subAction.rmDimmingLevel
                : subAction.shunoStatus === 'ON' ? 100 : 0,
            }
          })
        }
      })
    }
    yield put(successGetSchedule(formattedData))
    } catch (error) {
        yield put(errorGetSchedule())
        console.log('function*getScheduleSaga -> error', error)
        const toast = createNotifications()
        toast({
          title: 'Ошибка операции!',
          description: 'Не удалось получить данные карточки. \nПовторите попытку позже.',
          type: 'error',
        })
    }
}

export default function* root() {
  yield takeLatest(GET_SCHEDULE.REQUEST, getScheduleSaga)
}
