import styled from 'styled-components'
import { LIGHT_THEME_NAME } from '@/constants/names'

export default styled.div`
  color: '';
`

export const Main = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
`

export const EmptyLog = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.colors.redisign.lightGray};
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.92rem;
`

export const ContainerDetails = styled.div`
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  position: absolute;
  right: 0;
  width: 340px;
  height: 100%;
  border-left: 1px solid ${({ theme }) => theme.colors.borders.default};
  z-index: 10;
  overflow-y: scroll;
  box-shadow: 2px 3px 15px rgba(61,61,61,0.3);
`

export const HeaderDetails = styled.div`
  padding: 15px;
  width: 100%;
  height: 48px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
`
export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
`
export const IconContainer = styled.div`
  svg {
    width: 16px;
    height: 16px;
  }
`
export const TitleDetails = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.colors.default};
  font-size: 1.5rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: pre-wrap;
  text-align: left;
  ${({ theme }) => theme.themeName === LIGHT_THEME_NAME && `
    color:${theme.colors.colors.redisign.default};
  `}
`

export const ContentDeatils = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  padding: 15px;
`

export const RowDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`
export const TitleRow = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.colors.default};
  font-size: 1.4rem;
  line-height: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: pre-wrap;
  text-align: left;
  ${({ theme }) => theme.themeName === LIGHT_THEME_NAME && `
    color:${theme.colors.colors.redisign.default};
  `}
`
export const ValueRow = styled.div`
  padding-left: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 1.3rem;
  line-height: auto;
  text-align: left;
  ${({ theme }) => theme.themeName === LIGHT_THEME_NAME && `
    color:${theme.colors.colors.redisign.default};
  `}
`
