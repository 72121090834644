import request from '@/helpers/axios'
import { GEOZONE, INTEGRATION, PROJECT } from '@/constants/objectTypes'
import get from 'lodash/get'

const specifiedDepths = (ppr, inventory) => {
  if (ppr) {
    return INTEGRATION
  }
  if (inventory) {
    return PROJECT
  }
  return null
}

export const getChildren = ({
  rootId, 
  closestGeoZoneId, 
  parentTreeId, 
  // asu, 
  // objectsTypes, 
  // integrations, 
  objectStates, 
  url, 
  telemetryStatuses, 
  // processes, 
  // owners, 
  // exploitationOrganizations, 
  ppr,
  isReportManager,
  inventory,
  ...filters
}) => async (node, propsPageSize) => {
  const pinnedNode = JSON.parse(window.localStorage.getItem('pinnedNode'))
  const pageSize = propsPageSize ? 250 * propsPageSize : 250
  const { data } = await request({
    url: url({
      params: {
        parentId: get(node, 'id', null)
            || get(node, 'node.id', null)
            || get(node, 'node.data.id', null)
            || get(node, 'node', null),
        ...filters,
        // integrationTypes: asu,
        // installationTypes: objectsTypes,
        // integrationIds: integrations,
        // cityProcessTypes: processes,
        telemetryStatuses,
        objectStates,
        // owners,
        // exploitationOrganizations,
        pageSize: pageSize,
        sort: ['name: asc'],
        pageNumber: 0,
        includedIds: pinnedNode?.id || null,
        treeNodeType: isReportManager ? 'GEOZONE' : null,
      },
    }),
    method: 'get',
  })
  const childWithCount = await Promise.all(data.content.map(async (element, index) => {
    const parentGeoZoneId = element.treeNodeType === GEOZONE
      ? element.id
      : closestGeoZoneId
    return {
      ...element,
      type: element.installationType || element.treeNodeType,
      systemType: element.treeNodeType,
      name: element.name,
      parentGeoZoneId: closestGeoZoneId,
      parentTreeId,
      rootGeoZone: rootId,
      childrenCount: element.countChildElements,
      asuId: element.asuId,
      state: element.objectState,
      isLast: (!(data.content.length === data.pageable.totalElements) && (data.pageable.totalElements / pageSize) > 1) && data.content.length === (index +1),
      getChildren: ((ppr || inventory) && element.treeNodeType === specifiedDepths(ppr, inventory)) ? 
        null 
          : 
        getChildren({
          rootId, 
          parentGeoZoneId, 
          parentTreeId: element.id, 
          objectStates, 
          ...filters,
          // objectsTypes, 
          // integrations, 
          // asu, 
          url, 
          telemetryStatuses, 
          // processes, 
          // owners, 
          // exploitationOrganizations, 
          ppr,
          pageSize,
          isReportManager,
          inventory,
        }),
    }
  }))
  return childWithCount
}

export default getChildren
