import { GET_INVENTORY_FILE } from '@/store/actions/inventory';

import { select, takeLatest, put} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_INVENTORY_FILE_URL } from '@/constants/apiRoutes';
import moment from 'moment/moment';
import getTimeZoneOffset from '@/helpers/getTimeZoneOffset';
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob';
import { getDates, getIdIntegrations, getTableParameters } from '@/store/selectors/inventory';
import createNotifications from '@/helpers/notification';
import { successRequestGetFile } from '@/store/actions/inventory/getFile';

function* getInventoryFileSaga({ payload }) {
  const toast = createNotifications()
  try {
    const tableParameters = yield select(getTableParameters)
    const idIntegrations = yield select(getIdIntegrations)

    const {dateFirst: dateFirstFilter, dateSecond: dateSecondFilter} = yield select(getDates)
    const dateFirst = new Date(dateFirstFilter)
    const dateSecond = new Date(dateSecondFilter)
    const dateCheckup = new Date('2021-04-19')
    const ids = () => {
      if (dateFirstFilter && dateSecondFilter) {
        if ((dateFirst <= dateCheckup) && (dateSecond > dateCheckup)) {
          return {
            firstIntegrationId: idIntegrations.modernizationAndReconstruction,
            secondIntegrationId: idIntegrations.acceptanceAndInspection,
          }
        }
        if ((dateFirst > dateCheckup) && (dateSecond <= dateCheckup)) {
          return {
            firstIntegrationId: idIntegrations.acceptanceAndInspection,
            secondIntegrationId: idIntegrations.modernizationAndReconstruction,
          }
        }
        if ((dateFirst <= dateCheckup) && (dateSecond <= dateCheckup)) {
          return {
            firstIntegrationId: idIntegrations.acceptanceAndInspection,
            secondIntegrationId: null,
          }
        }
        if ((dateFirst > dateCheckup) && (dateSecond > dateCheckup)) {
          return {
            firstIntegrationId: idIntegrations.modernizationAndReconstruction,
            secondIntegrationId: null,
          }
        }
      }
      if (dateFirstFilter) {
        if ((dateFirst <= dateCheckup)) {
          return {
            firstIntegrationId: idIntegrations.acceptanceAndInspection,
            secondIntegrationId: null,
          }
        } else {
          return {
            firstIntegrationId: idIntegrations.modernizationAndReconstruction,
            secondIntegrationId: null,
          }
        }
      }
      if (dateSecondFilter) {
        if ( (dateSecond <= dateCheckup)) {
          return {
            firstIntegrationId: idIntegrations.acceptanceAndInspection,
            secondIntegrationId: null,
          }
        } else {
          return {
            firstIntegrationId: idIntegrations.modernizationAndReconstruction,
            secondIntegrationId: null,
          }
        }
      }
      return {}
    }

    const response = yield request({
      url: GET_INVENTORY_FILE_URL({
        params: {
          firstIntegrationId: ids().firstIntegrationId,
          secondIntegrationId: ids().secondIntegrationId,
          sort: [`${tableParameters.sort.field}: ${tableParameters.sort.direction}`],
          timeZoneOffset: getTimeZoneOffset(),
        },
      }),
      method: 'get',
      options: {
        responseType: 'blob',
      },
    })

    downloadFileFromBlob({
      blob: response.data,
      fileName: `Инвентаризационная ведомость за ${moment(new Date()).format('DD.MM.YYYY')}.xlsx`,
    })

    yield put(successRequestGetFile())

  } catch (error) {
    console.log(error)
    toast({
      title: 'Ошибка операции!',
      description: 'Невозможно выгрузить отчет.\nПовторите попытку позже.',
      type: 'error',
    })
  }

  return null
}

export default function* root() {
  yield takeLatest(GET_INVENTORY_FILE.REQUEST, getInventoryFileSaga)
}