import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ControlCupboardArtIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)
  return (
  <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"  viewBox="0 0 18 18">
    <path className="svgFill" fill={fill} fill-rule="evenodd" clip-rule="evenodd" d="M9 18A9 9 0 1 0 9 0a9 9 0 0 0 0 18Zm2.025-5.063H9.622L11.7 8.213H8.104L9.675 2.7h-1.35L6.3 9.787h3.47l-1.498 3.15H6.975L9 15.3l2.025-2.363Z" />
  </svg>

  
  )
}

ControlCupboardArtIcon.propTypes = {
  onClick: pt.func,
  color: pt.string,
}
ControlCupboardArtIcon.defaultProps = {
  onClick: noop,
  color: null,
}

export default memoWithName(ControlCupboardArtIcon)
