import createAction from '@/helpers/redux/createAction'

export const GET_USERS_COUNT = createAction('@/USERS_PAGE_GET_USERS_COUNT')
export const GET_CURRENT_USER = createAction('@/USERS_PAGE_GET_CURRENT_USER')
// TENANTS
export const GET_ALL_TENANTS = createAction('@/USERS_PAGE_GET_ALL_TENANTS')
export const CREATE_TENANT = createAction('@/USERS_PAGE_CREATE_TENANT')
export const GET_TENANT = createAction('@/USERS_PAGE_GET_TENANT')
export const UPDATE_TENANT = createAction('@/USERS_PAGE_UPDATE_TENANT')
export const DELETE_TENANT = createAction('@/USERS_PAGE_DELETE_TENANT')
// ADMINS
export const GET_TENANT_ADMINS = createAction('@/USERS_PAGE_GET_TENANTS_ADMINS')
export const CREATE_TENANT_ADMIN = createAction('@/USERS_PAGE_CREATE_TENANT_ADMIN')
export const GET_TENANT_ADMIN = createAction('@/USERS_PAGE_GET_TENANT_ADMIN')
export const DELETE_TENANT_ADMIN = createAction('@/USERS_PAGE_DELETE_TENANT_ADMIN')
// GROUP
export const CREATE_GROUP = createAction('@/USERS_PAGE_CREATE_GROUP')
export const GET_GROUP = createAction('@/USERS_PAGE_GET_GROUP')
export const UPDATE_GROUP = createAction('@/USERS_PAGE_UPDATE_GROUP')
export const DELETE_GROUP = createAction('@/USERS_PAGE_DELETE_GROUP')
export const GET_TENANT_GROUPS = createAction('@/USERS_PAGE_GET_TENANT_GROUPS')
// USERS
export const CREATE_USER = createAction('@/USERS_PAGE_CREATE_USER')
export const GET_USER = createAction('@/USERS_PAGE_GET_USER')
export const GET_GROUP_USERS = createAction('@/USERS_PAGE_GET_GROUP_USERS')
export const UPDATE_USER = createAction('@/USERS_PAGE_UPDATE_USER')
export const UPDATE_USER_FROM_ADMIN = createAction('@/USERS_PAGE_UPDATE_USER_FROM_ADMIN')
export const DELETE_USER = createAction('@/USERS_PAGE_DELETE_USER')
export const MULTIPLE_EDITING = createAction('@/USERS_PAGE_MULTIPLE_EDITING')
export const MULTIPLE_DELETE = createAction('@/USERS_PAGE_MULTIPLE_DELETE')
export const GET_ONLINE_USERS = createAction('@/GET_ONLINE_USERS')
export const RESEND_VERIFICATION_EMAIL = createAction('@/RESEND_VERIFICATION_EMAIL')
// COMMON
export const TENANT_UPDATE = '@/USERS_PAGE_TENANT_UPDATE'
export const UPDATE_SELECTED_USER_TYPE = '@/USERS_PAGE_UPDATE_SELECTED_USER_TYPE'
export const UPDATE_SELECTED_TENANT = '@/USERS_PAGE_UPDATE_SELECTED_TENANT'
export const USERS_SET_ENTITY = '@/USERS_PAGE_USERS_SET_ENTITY'
export const USERS_SORT = '@/USERS_PAGE_USERS_SORT'
export const GET_ALL_SORTED_USERS = '@/USERS_PAGE_GET_ALL_SORTED_USERS'
export const IS_FORM_MODIFYING = '@/USERS_PAGE_IS_FORM_MODIFYING'

export const setFormIsModifying = (payload) => ({
  type: IS_FORM_MODIFYING,
  payload,
})

export const setEntity = (newEntity) => ({
  type: USERS_SET_ENTITY,
  payload: newEntity,
})

export const updateSelectedUserType = (newType) => ({
  type: UPDATE_SELECTED_USER_TYPE,
  payload: newType,
})

export const setSelectedTenant = (newSelectedTenant) => ({
  type: UPDATE_SELECTED_TENANT,
  payload: newSelectedTenant,
})

export const setUsersSort = (payload) => ({
  type: USERS_SORT,
  payload,
})

export const getAllSortedUsers = (payload) => ({
  type: GET_ALL_SORTED_USERS,
  payload,
})

export const resendVerificationEmail = (payload) => ({
  type: RESEND_VERIFICATION_EMAIL.REQUEST,
  payload,
})
