import LightBulbIcon from '@/components/icons/maps/LightBulbIcon'
import HeatIcon from '@/components/icons/maps/HeatIcon'
import WaterIcon from '@/components/icons/maps/WaterIcon'
import ControllerIcon from '@/components/icons/maps/ControllerIcon'
import TrashpinIcon from '@/components/icons/maps/TrashpinIcon'
import ContractorIcon from '@/components/icons/maps/ContractorIcon'
import LEPIcon from '@/components/icons/maps/LEPIcon'
import PoleIcon from '@/components/icons/maps/PoleIcon'
import ControllerChipIcon from '@/components/icons/maps/ControllerChipIcon'
import SencorIcon from '@/components/icons/maps/SencorIcon'
import LampModuleIcon from '@/components/icons/maps/LampModuleIcon'
import LampModuleArtIcon from '@/components/icons/maps/LampModuleArtIcon'
import ControlCupboardArtIcon from '@/components/icons/maps/ControlCupboardArtIcon'
import LineArtIcon from '@/components/icons/maps/LineArtIcon'

import {
  BASE_STATION,
  BRANCH_CIRCUIT,
  CENTRAL_HEATING_DEVICE,
  CHECKPOINT,
  COLD_WATER_DEVICE,
  COMPANY_OBJECT,
  CONTACTOR,
  CONTROL_CUPBOARD,
  CONTROLLER,
  CONTROLLER_FUSE,
  CURRENT_SENSOR,
  DEVICE,
  DMX_DEVICE,
  DOOR_OPEN_SENSOR,
  ELECTRIC_METER,
  FIRE_SENSOR,
  FUSE, GROUP,
  LAMP,
  LAMP_MODULE,
  LIGHT_FIXTURE,
  LIGHT_SENSOR,
  LINE,
  METEO,
  MODULE,
  MULTIRATE_METER,
  PHASE,
  PHASE_CIRCUIT_BREAKER,
  PHOTO_SENSOR,
  ROAD_TEMPERATURE_SENSOR,
  SENSOR,
  SMOKE_SENSOR,
  STREETLIGHT,
  STREETLIGHT_CONTROL_CABINET,
  STREETLIGHT_GROUP,
  SWITCHING_GROUP,
  TRANSFER_SWITCH,
  UTILITY_POLE,
  VOLUME_SENSOR,
  WASTE_CONTAINER,
  LIGHT_FIXTURE_ART,
  CONTROL_CUPBOARD_ART,
  LINE_ART,
} from '@/constants/instalationPassport/types'

import {
  FUEL_YELLOW, REGENT_GRAY,
  MALACHITE, RED, EBONY_CLAY,
  WHITE, CADET_BLUE,
  MALACHITE_LIGHT, RED_LIGHT,
  SHARK_DARK, BLUE_LIGHT, BLUE_DARK,
  BOULDER, YELLOW_LIGHT, ORANGE_PEEL,
  MIDDLE_RED, ATLANTIS_LIGHT, WHITE_GRAY,
} from './defaultTheme'
import {
  OK,
  WARNING,
  ERROR,
  INFO,
  NOT_VERIFIED,
  VERIFIED,
  NEW,
  NOT_EXIST,
  NOT_INSTALLED,
  INSTALLED,
  UNINSTALLED,
  OFF,
} from '../objectStatuses'

export const MAPS_ICONS = {
  [LIGHT_FIXTURE]: LightBulbIcon,
  [LAMP]: LightBulbIcon,
  [CONTROLLER]: ControllerIcon,
  [COMPANY_OBJECT]: ControllerIcon,
  [CONTROL_CUPBOARD]: ControllerIcon,
  [GROUP]: ControllerIcon,
  [CENTRAL_HEATING_DEVICE]: HeatIcon,
  [COLD_WATER_DEVICE]: WaterIcon,
  [LIGHT_SENSOR]: SencorIcon,
  [WASTE_CONTAINER]: TrashpinIcon,
  [ELECTRIC_METER]: SencorIcon,
  [CONTACTOR]: ContractorIcon,
  [PHASE]: SencorIcon,
  [CURRENT_SENSOR]: SencorIcon,
  [BRANCH_CIRCUIT]: SencorIcon,
  [DMX_DEVICE]: SencorIcon,
  [SENSOR]: SencorIcon,
  [CONTROLLER]: ControllerIcon,
  [BASE_STATION]: ControllerIcon,
  [ELECTRIC_METER]: ContractorIcon,
  [MULTIRATE_METER]: ContractorIcon,
  [CONTACTOR]: ContractorIcon,
  [BRANCH_CIRCUIT]: ContractorIcon,
  [LIGHT_SENSOR]: SencorIcon,
  [PHOTO_SENSOR]: SencorIcon,
  [VOLUME_SENSOR]: SencorIcon,
  [FIRE_SENSOR]: SencorIcon,
  [TRANSFER_SWITCH]: ControllerChipIcon,
  [DMX_DEVICE]: ControllerChipIcon,
  [CONTROLLER_FUSE]: ControllerIcon,
  [FUSE]: ControllerIcon,
  [CHECKPOINT]: ContractorIcon,
  [CURRENT_SENSOR]: SencorIcon,
  [CONTROL_CUPBOARD]: ContractorIcon,
  [METEO]: SencorIcon,
  [ROAD_TEMPERATURE_SENSOR]: SencorIcon,
  [DOOR_OPEN_SENSOR]: SencorIcon,
  [SMOKE_SENSOR]: SencorIcon,
  [DEVICE]: ControllerChipIcon,
  [SWITCHING_GROUP]: PoleIcon,
  [STREETLIGHT_CONTROL_CABINET]: ControllerChipIcon,
  [STREETLIGHT_GROUP]: PoleIcon,
  [STREETLIGHT]: LightBulbIcon,
  [UTILITY_POLE]: PoleIcon,
  [LINE]: LEPIcon,
  [LAMP_MODULE]: LampModuleIcon,
  [MODULE]: LightBulbIcon,
  [PHASE_CIRCUIT_BREAKER]: ContractorIcon,
  [LIGHT_FIXTURE_ART]: LampModuleArtIcon,
  [CONTROL_CUPBOARD_ART]: ControlCupboardArtIcon,
  [LINE_ART]: LineArtIcon,
}

export const zoneColor = RED_LIGHT
export const zoneBorder = RED
export const highlightedzoneColor = MALACHITE_LIGHT
export const highlightedzoneBorder = MALACHITE

export const STATUS_COLORS = {
  [OK]: ATLANTIS_LIGHT,
  'OK': ATLANTIS_LIGHT,
  [WARNING]: FUEL_YELLOW,
  [ERROR]: RED,
  [OFF]: RED,
  [INFO]: REGENT_GRAY,
  [NOT_VERIFIED]: BLUE_LIGHT,
  [VERIFIED]: BLUE_DARK,
  [NEW]: BOULDER,
  [NOT_EXIST]: YELLOW_LIGHT,
  [NOT_INSTALLED]: ORANGE_PEEL,
  [INSTALLED]: ATLANTIS_LIGHT,
  [UNINSTALLED]: MIDDLE_RED,
  null: REGENT_GRAY,
  UNDEFINED: REGENT_GRAY,
  NONE: WHITE_GRAY,
}

export const MARKS_COLORS = {
  ...STATUS_COLORS,
  backdrop: EBONY_CLAY,
  white: WHITE,
  gray: CADET_BLUE,
  background: SHARK_DARK,
}

// new clusters
export const clusterContainerBase = {
  zIndex: 120,
  position: 'relative',
  display: 'flex',
  jsutifyContent: 'center',
  alignItems: 'center',
  background: MARKS_COLORS.backdrop,
  borderRadius: '50%',
  transition: 'all 1s ease-out',
}

export const pieChartContainerBase = {
  position: 'absolute',
  borderRadius: '50%',
  display: 'flex',
  jsutifyContent: 'center',
  alignItems: 'center',
  zIndex: 120,
}

export const pieChartOverlayBase = {
  position: 'absolute',
  borderRadius: '50%',
  background: MARKS_COLORS.backdrop,
  zIndex: 125,
}

export const countTitleBase = {
  position: 'absolute',
  color: MARKS_COLORS.white,
  textAlign: 'center',
  left: '0px',
  zIndex: 130,
}

export const titleHoverBase = {
  position: 'absolute',
  top: '0',
  background: MARKS_COLORS.backdrop,
  display: 'flex',
  jsutifyContent: 'center',
  alignItems: 'center',
  color: MARKS_COLORS.white,
  zIndex: 100,
}

// new clusters

// new icons
export const pinToolTip = {
  position: 'absolute',
  padding: ' 3px 10px',
  background: MARKS_COLORS.white,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 100,
}

export const pinTip = {
  position: 'absolute',
  background: MARKS_COLORS.white,
  transform: 'rotate(45deg)',
  zIndex: 90,
}

// new icons

export const zoneOptions = {
  hasBallon: false,
  fillColor: zoneColor,
  strokeColor: zoneBorder,
  opacity: 1,
  strokeWidth: 1,
  strokeStyle: 'solid',
}

export const highlightedZoneOptions = {
  ...zoneOptions,
  fillColor: highlightedzoneColor,
  strokeColor: highlightedzoneBorder,
}

export const defaultZoneOptions = {
  // ...zoneOptions,
  fillColor: '#00000000',
  strokeWidth: 2,
  strokeStyle: 'solid',
  strokeColor: RED,
}

const commonBase = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: MARKS_COLORS.backdrop,
}

export const baseSmall = {
  width: '40px',
  height: '40px',
  ...commonBase,
}
export const baseMedium = {
  width: '40px',
  height: '40px',
  ...commonBase,
}

export const baseBig = {
  width: '50px',
  height: '50px',
  ...commonBase,
}

export const baseLarge = {
  width: '60px',
  height: '60px',
  ...commonBase,
}

export const base = {
  width: '40px',
  height: '40px',
  ...commonBase,
}

export const pieChartHole = {
  borderRadius: '50%',
  fontSize: '1.2rem',
  justifyContent: 'center',
}
export const pieChartHoleHover = {
  ...pieChartHole,
  display: 'flex',
  alignItems: 'center',
}

export const clusterWrapper = {
  backgroundColor: 'transparent',
  border: '3px solid transparent',
  borderRadius: '50%',
  justifyContent: 'center',
}
export const clusterWrapperHover = {
  border: `3px solid ${MARKS_COLORS.backdrop}`,
  borderRadius: '50px',
}

export const clusterContainer = {
  borderRadius: '50%',
  border: `1px solid ${MARKS_COLORS.backdrop}`,
  justifyContent: 'center',
}

export const clusterContainerHover = {
  borderRadius: '50%',
  justifyContent: 'center',
}

export const geoZonePosition = {
  position: 'absolute',
  top: '-15px',
  left: '-15px',
  borderRadius: '50px',
}

export const markTriangle = {
  position: 'absolute',
  border: '10px solid transparent',
  borderTop: `15px solid ${MARKS_COLORS.backdrop}`,
}

export const iconStyles = {
  height: '20px',
  fill: MARKS_COLORS.white,
}

export const pinBase = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '40px',
  height: '45px',
}

export const circleBase = {
  position: 'absolute',
  top: '5px',
  left: '5px',
  width: '30px',
  height: '30px',
  borderRadius: '50px',
  backgroundColor: MARKS_COLORS.backdrop,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 110,
}

export const flagBasic = {
  position: 'absolute',
  top: '0',
  left: '20px',
  color: MARKS_COLORS.white,
  width: '100px',
  height: '40px',
  borderRadius: '0 50px 50px  0',
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '20px',
  zIndex: 100,
}

export const pinBaseContainer = {
  position: 'relative',
  top: '-10px',
  left: '-10px',
  width: '40px',
  height: '45px',
  zIndex: 120,
}

export const meshTitle = {
  width: '40px',
  position: 'absolute',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  lineHeight: '1.4rem',
  color: MARKS_COLORS.white,
  textAlign: 'center',
  top: '4px',
  zIndex: 120,
}

export const meshCircle = {
  position: 'absolute',
  borderRadius: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 110,
}

export const meshBase = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '40px',
  height: '61px',
}

export const meshContainer = {
  position: 'relative',
  width: '40px',
  height: '45px',
  zIndex: 120,
}

export const meshIconStyles = {
  height: '20px',
  fill: MARKS_COLORS.white,
}
