import React, {
  useState, useMemo, createRef, useEffect,
} from 'react'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import noop from 'lodash/noop'
import get from 'lodash/get'
import MapIcons from '@/components/icons/maps'
import CoreIcons from '@/components/icons/core'
import SyncIcon from '@/components/icons/sync'
import ObjectCreateForm from '@/forms/ObjectCreateForm'
import PassportImage from '@/components/blocks/PassportImage'
import cardAndPassportByType from '@/constants/instalationPassport'
import Loader from '@/components/blocks/Loader'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import defaultPassport from '@/assets/passports'
import {
  LIGHT_FIXTURE_GALEON_L,
  LIGHT_FIXTURE_GALEON_S, 
  LIGHT_FIXTURE_GALAD,
  LAMP_MODULE,
  CONTROL_CUPBOARD,
  UNILIGHT,
  WITHOUT_ASU,
  LIGHT_FIXTURE_GALEON_S_LED,
  CONTROL_CUPBOARD_ART,
  BASE_STATION,
  LIGHT_FIXTURE_ART,
  LIGHT_FIXTURE_AVRORA_ART,
  LIGHT_FIXTURE_VEGA_ART,
  LIGHT_FIXTURE_ILLUMINATOR_ART,
  LIGHT_FIXTURE_KONTUR_ART,
  LIGHT_FIXTURE_TANDEM_ART,
} from '@/constants/instalationPassport/types'
import {
  CARD,
  PASSPORT,
} from '@/constants/passportization'
import {
  OBJECT_ELEMENT,
  ROOT,
  GEOZONE,
  PROJECT,
  INTEGRATION,
} from '@/constants/objectTypes'
import config from './config'
import { formatDataForObject, handlePhotoConvertToBase64 } from '../../utils'
import {
  Container,
  Header,
  IconContainer,
  InfoBlock,
  Title,
  Row,
  PrimaryTitle,
  SecondaryTitle,
  PassportContainer,
  LoaderContainer,
  ContentContainer,
} from './styles'

const titleToType = {
  [INTEGRATION]: 'integrationInfo',
  [ROOT]: 'geoZoneInfo',
  [GEOZONE]: 'geoZoneInfo',
  [PROJECT]: 'projectInfo',
}

const Preview = ({
  selectedNode,
  onClose,
  onDelete,
  onEdit,
  intl,
  userRoles,
  objectConnectedIdentifiers
}) => {
  const [uploadImage, setUploadImage] = useState()
  const formRef = createRef()
  const [tabType, setTabType] = useState(PASSPORT)
  const { infoStructure = [], fields = [], options = {} } = config[selectedNode.type] || {}
  const { edit, remove } = options

  const closeHandler = () => {
    onClose({})
  }
  const data = useMemo(() => {
    if (selectedNode.type !== OBJECT_ELEMENT) {
      return {}
    }
    return formatDataForObject(selectedNode, intl)
  }, [selectedNode, intl])

  const getDefaultPassport = () => {
     if (data.objectType === LIGHT_FIXTURE_ART) {
    
        if (data.GENERAL_INFORMATION.MODEL_MARK?.includes('Аврора')) {
          return {
            name: 'Паспорт GALAD Аврора LED',
            file: defaultPassport[LIGHT_FIXTURE_AVRORA_ART]
          }    
        }
    
        if (data.GENERAL_INFORMATION.MODEL_MARK?.includes('Вега')) {
          return {
            name: 'Паспорт GALAD Вега LED',
            file: defaultPassport[LIGHT_FIXTURE_VEGA_ART]
          }    
        }
    
        if (data.GENERAL_INFORMATION.MODEL_MARK?.includes('Иллюминатор')) {
          return {
            name: 'Паспорт GALAD Иллюминатор LED',
            file: defaultPassport[LIGHT_FIXTURE_ILLUMINATOR_ART]
          }    
        }
    
        if (data.GENERAL_INFORMATION.MODEL_MARK?.includes('Контур')) {
          return {
            name: 'Паспорт GALAD Контур LED',
            file: defaultPassport[LIGHT_FIXTURE_KONTUR_ART]
          }    
        }
    
        if (data.GENERAL_INFORMATION.MODEL_MARK?.includes('Тандем')) {
          return {
            name: 'Паспорт GALAD Тандем LED',
            file: defaultPassport[LIGHT_FIXTURE_TANDEM_ART]
          }    
        }
        
      }
  
    if (data.objectType === CONTROL_CUPBOARD) {
      return {
        name: 'Паспорт ШУНО Unilight',
        file: defaultPassport[CONTROL_CUPBOARD]
      }
    }

    if (data.GENERAL_INFORMATION.MODEL_MARK?.includes('Галеон S LED')) {
      return {
        name: 'Паспорт на Галеон серии S LED',
        file: defaultPassport[LIGHT_FIXTURE_GALEON_S_LED]
      }    
    }
    
    if (data.GENERAL_INFORMATION.MODEL_MARK?.includes('Галеон S')) {
      return {
        name: 'Паспорт на Галеон серии S',
        file: defaultPassport[LIGHT_FIXTURE_GALEON_S]
      }    
    }

    if (data.GENERAL_INFORMATION.MODEL_MARK?.includes('Галеон L')) {
      return {
        name: 'Паспорт на Галеон серии L',
        file: defaultPassport[LIGHT_FIXTURE_GALEON_L]
      }    
    }

    if (data.GENERAL_INFORMATION.MODEL_MARK?.includes('GALAD')) {
      return {
        name: 'Паспорт на GALAD Волна',
        file: defaultPassport[LIGHT_FIXTURE_GALAD]
      }    
    }
    
    if (data.objectType === LAMP_MODULE) {
      return {
        name: 'Паспорт Модуль Unilight NEMA',
        file: defaultPassport[LAMP_MODULE]
      }    
    }

    if (data.objectType === CONTROL_CUPBOARD_ART) {
      return {
        name: 'Паспорт ШУНО АХП',
        file: defaultPassport[CONTROL_CUPBOARD_ART]
      }    
    }

    if (data.objectType === BASE_STATION) {
      return {
        name: 'Паспорт Базовая станция',
        file: defaultPassport[BASE_STATION]
      }    
    }

    return ''
  }

  const getInitialValues = (values) => {
    if (!selectedNode.fileId) {
      if ((selectedNode.integrationType === WITHOUT_ASU && (data.path[3] !== 50061 || data.path[3] !== 4459)) || selectedNode.integrationType === UNILIGHT ) // change integration id before merge in prod SLS-1794
      return {
        ...values,
        'ATTACHMENT': {
          'DOCUMENT_TITLE' : getDefaultPassport(),
        }
      }
    }
    return values
  }

  useEffect(() => {
    const photo = get(data, 'ATTACHMENT.PHOTO_TITLE', null)
    if (photo) {
      handlePhotoConvertToBase64(photo, setUploadImage)
    }
    if (!photo && uploadImage) {
      setUploadImage(null)
    }
  }, [data, uploadImage])

  const returnName = (name) => {
    const limit = 22
    return (
      <>
        {((name || '').length > limit)
          ? (
            <PortalTooltip
              title={name}
              renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                <Title
                  ref={wrapperRef}
                  onMouseEnter={onMouseEnterHandler}
                  onMouseLeave={onMouseLeaveHandler}
                >
                  {(name || '').substr(0, limit)}
                  ...
                </Title>
              )}
            />
          )
          : (
            <Title>
              {name}
            </Title>
          )}
      </>
    )
  }

  const formConfig = useMemo(() => {
    if (tabType === CARD) {
      return get(cardAndPassportByType, `${data.integrationType}.card.${data.objectType}`, [])
    }
    return get(cardAndPassportByType, `${data.integrationType}.passport.${data.objectType}`, [])
  }, [tabType, data.integrationType, data.objectType])

  const setTabTypeHandler = (type) => () => { setTabType(type) }
  const isDisplayLoader = useMemo(() => !selectedNode.name, [selectedNode.name])
  return (
    <Container>
      {isDisplayLoader
        ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )
        : (
          <>
            <Header>
              <IconContainer
                onClick={closeHandler}
                arrow
              >
                <MapIcons.BackIcon />
              </IconContainer>
              {(edit || userRoles.isSuperAdmin) && (
                <IconContainer pr={10} onClick={onEdit}>
                  <CoreIcons.EditIcon />
                </IconContainer>
              )}
              {returnName(selectedNode.name || selectedNode.integrationName)}
              {(remove || userRoles.isSuperAdmin) && (
                <IconContainer onClick={onDelete}>
                  <SyncIcon.TrashCanIcon />
                </IconContainer>
              )}
            </Header>
            {selectedNode.type !== OBJECT_ELEMENT && (
              <ContentContainer>
                {infoStructure.length !== 0 && (
                  <InfoBlock>
                    <PrimaryTitle>
                      <Lang id="installation.createGeoZoneForm.infoStructure" />
                    </PrimaryTitle>
                    {infoStructure.map(({ icon: Icon, title, selector }) => (
                      <Row key={`${selector}-${get(selectedNode, `elementsInfo.${selector}`, 0)}`}>
                        <IconContainer>
                          <Icon />
                        </IconContainer>
                        <SecondaryTitle>
                          {title}
                          :
                        </SecondaryTitle>
                        <SecondaryTitle>
                          {get(selectedNode, `elementsInfo.${selector}`, 0)}
                        </SecondaryTitle>
                      </Row>
                    ))}
                  </InfoBlock>
                )}
                {fields.length !== 0 && (
                  <InfoBlock>
                    <PrimaryTitle>
                      <Lang id={`installation.createGeoZoneForm.${titleToType[selectedNode.type]}`} />
                    </PrimaryTitle>
                    {fields.map(({ selector, formatter }) => {
                      if (selector === 'customFields') {
                        return (
                          <>
                            {selectedNode.customFields && selectedNode.customFields.map(({ value, nameField }) => (
                              <Row stack key={`${nameField}-${value}`}>
                                <SecondaryTitle>
                                  {nameField}
                                  :
                                </SecondaryTitle>
                                <SecondaryTitle>
                                  {value || '...'}
                                </SecondaryTitle>
                              </Row>
                            ))}
                          </>
                        )
                      }
                      if (selector === 'integrationType') {
                        return (
                          <Row stack>
                            <SecondaryTitle>
                              <Lang id={`installation.createGeoZoneForm.${selector}`} />
                              :
                            </SecondaryTitle>
                            <SecondaryTitle>
                              <Lang id={`globalNames.integrationType.${get(selectedNode, selector, null) || '...'}`} />
                            </SecondaryTitle>
                          </Row>
                        )
                      }
                      const value = formatter ? formatter(get(selectedNode, selector, null)) : get(selectedNode, selector, null)
                      return (
                        <Row stack key={`${selector}-${value}`}>
                          <SecondaryTitle>
                            <Lang id={`installation.createGeoZoneForm.${selector}`} />
                            :
                          </SecondaryTitle>
                          <SecondaryTitle>
                            {value || '...'}
                          </SecondaryTitle>
                        </Row>
                      )
                    })}
                  </InfoBlock>
                )}
              </ContentContainer>
            )}
            {selectedNode.type === OBJECT_ELEMENT && (
              <PassportContainer>
                <PassportImage
                  setTabType={setTabTypeHandler}
                  tabType={tabType}
                  uploadedImage={uploadImage}
                  withSearch
                  selectedNode={selectedNode}
                />
                <ObjectCreateForm
                  ref={formRef}
                  options={formConfig}
                  data={getInitialValues(data)}
                  preview
                  block
                />
              </PassportContainer>
            )}
          </>
        )}
    </Container>
  )
}

Preview.propTypes = {
  selectedNode: pt.objectOf(pt.any),
  onClose: pt.func,
  onDelete: pt.func,
  onEdit: pt.func,
  intl: pt.objectOf(pt.object),
}
Preview.defaultProps = {
  selectedNode: {},
  onClose: noop,
  onDelete: noop,
  onEdit: noop,
  intl: {},
}

export default Preview
