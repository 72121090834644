import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_SEARCH_FIELDS_OPTIONS_URL } from '@/constants/apiRoutes'
import { GET_SEARCH_FIELDS_OPTIONS } from '@/store/actions/dictionaries'
import createNotifications from '@/helpers/notification'
import {
  errorGetSearchFieldsOptions,
  responseGetSearchFieldsOptions
} from '@/store/actions/dictionaries/searchFieldsOptions'
import {
    ASU_TYPE_TO_NAME,
    TYPE_TO_NAME,
    PROCESS_TO_NAME
} from '@/constants/maps'
import { sortOptionsByTitle } from '@/helpers/sortOptionsByTitle'
import { setIdIntergrations } from '@/store/actions/inventory'

function* getDictionary({ payload }) {
    try {
        const {NO_PROBLEM, WARNING, ERROR, UNDEFINED, ...filters} = payload || {}
        const statuses = {
            NO_PROBLEM,
            WARNING,
            ERROR,
            UNDEFINED,
          }
          const statusesTrue = Object.keys(statuses).filter((status) => statuses[status])
          const telemetryStatuses = statusesTrue.length === Object.keys(statuses).length ? [] : statusesTrue
        const response = yield request({
            url: GET_SEARCH_FIELDS_OPTIONS_URL({
                ...filters,
                telemetryStatuses,
            }),
            method: 'get',
        })
        if (response && response.data) {
            const equipmentTypes = response.data.OBJECT_TYPES.map(item => {
                return {
                    title: TYPE_TO_NAME[item.value],
                    value: item.value,
                }
            })
            const sortedEquipmentTypes = sortOptionsByTitle(equipmentTypes)

            const geoZones = response.data.GEOZONES.map(item => {
                return {
                    title: item.value,
                    value: item.id
                }
            })
            const sortedGeoZones = sortOptionsByTitle(geoZones)

            const projects = response.data.PROJECTS.map(item => {
                return {
                    title: item.value,
                    value: item.id
                }
            })
            const sortedProjects = sortOptionsByTitle(projects)

            const integrations = response.data.INTEGRATIONS.map(item => {
                return {
                    title: item.value,
                    value: item.id
                }
            })
            const sortedIntegrations = sortOptionsByTitle(integrations)
            yield put(setIdIntergrations({
                acceptanceAndInspection : sortedIntegrations.filter((integration) => integration.title.includes('Приемка'))[0]?.value,
                modernizationAndReconstruction: sortedIntegrations.filter((integration) => integration.title.includes('Модернизация'))[0]?.value,
            }))

            const installationTypes = response.data.INSTALLATION_TYPES.map(item => {
                return {
                    title: TYPE_TO_NAME[item.value],
                    value: item.value,
                }
            })
            const sortedInstallationTypes = sortOptionsByTitle(installationTypes)

            const cities = response.data.CITIES.map(item => {
                return {
                    title: item.value,
                    value: item.value
                }
            })
            const sortedCities = sortOptionsByTitle(cities)
            const regions = response.data.REGIONS.map(item => {
                return {
                    title: item.value,
                    value: item.value
                }
            })
            const sortedRegions = sortOptionsByTitle(regions)

            const owners = response.data.OWNERS.map(item => {
                return {
                    title: item.value,
                    value: item.value
                }
            })
            const sortedOwners = sortOptionsByTitle(owners)

            const exploitationOrganizations = response.data.EXPLOAITATION_ORGANIZATIONS.map(item => {
                return {
                    title: item.value,
                    value: item.value
                }
            })
            const sortedExploitationOrganizations = sortOptionsByTitle(exploitationOrganizations)

            const cityProcesses = response.data.CITY_PROCESS_TYPES.map(item => {
                return {
                    title: PROCESS_TO_NAME[item.value],
                    value: item.value
                }
            })
            const sortedCityProcesses = sortOptionsByTitle(cityProcesses)
            
            const integrationTypes = response.data.INTEGRATION_TYPES.map(item => {
                return {
                    title: ASU_TYPE_TO_NAME[item.value],
                    value: item.value
                }
            })
            const sortedIntegrationTypes = sortOptionsByTitle(integrationTypes)

            const registerNumber = response.data.REGISTRY_NUMBER.map(item => {
                return {
                    title: item.value,
                    value: item.value
                }
            })
            const sortedRegisterNumber = sortOptionsByTitle(registerNumber)

            const street = response.data.STREET.map(item => {
                return {
                    title: item.value,
                    value: item.value
                }
            })
            const sortedStreet = sortOptionsByTitle(street)

            const vols = response.data.VOLS.map(item => {
                return {
                    title: item.value,
                    value: item.value
                }
            })
            const sortedVols = sortOptionsByTitle(vols)

            const balanceSheetHolders = response.data.BALANCE_SHEET_HOLDER.map(item => {
                return {
                    title: item.value,
                    value: item.value
                }
            })
            const sortedBalanceSheetHolders = sortOptionsByTitle(balanceSheetHolders)

            const locationType = response.data.LOCATION_TYPE.map(item => {
                return {
                    title: item.value,
                    value: item.value
                }
            })
            const sortedlocationType = sortOptionsByTitle(locationType)

            const utilityPoleType = response.data.UTILITY_POLE_TYPE.map(item => {
                return {
                    title: item.value,
                    value: item.value
                }
            })
            const sortedUtilityPoleType = sortOptionsByTitle(utilityPoleType)

            const lampType = response.data.LAMP_TYPE.map(item => {
                return {
                    title: item.value,
                    value: item.value
                }
            })
            const sortedLampType = sortOptionsByTitle(lampType)

            const controlCupboard = response.data.CONTROL_CUPBOARD.map(item => {
                return {
                    title: item.value,
                    value: item.id
                }
            })
            const sortedControlCupboard = sortOptionsByTitle(controlCupboard)
            
            
            const formattedData = {
                installationTypes: sortedEquipmentTypes,
                geoZone: sortedGeoZones,
                project: sortedProjects,
                integrationIds: sortedIntegrations,
                city: sortedCities,
                region: sortedRegions,
                owners: sortedOwners,
                integrationTypes: sortedIntegrationTypes,
                installationType: sortedInstallationTypes,
                exploitationOrganization: sortedExploitationOrganizations,
                cityProcessTypes: sortedCityProcesses,
                registryNumbers: sortedRegisterNumber,
                street: sortedStreet,
                vols: sortedVols,
                balanceSheetHolders: sortedBalanceSheetHolders,
                locationType: sortedlocationType,
                utilityPoleType: sortedUtilityPoleType,
                lampType: sortedLampType,
                controlCupboard: sortedControlCupboard,
            }
            yield put(responseGetSearchFieldsOptions(formattedData))
        }
    } catch (error) {
        yield put(errorGetSearchFieldsOptions(error))
        const toast = createNotifications()
        toast({
            title: 'Ошибка операции!',
            description: 'Не удалось получить данные для формы расширенного поиска.\nПовторите попытку позже.',
            type: 'error',
        })
    }
}

export default function* () {
    yield takeLatest(GET_SEARCH_FIELDS_OPTIONS.REQUEST, getDictionary)
}