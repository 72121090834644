export const ACCEPT = 'accept'
export const RESET = 'reset'
export const ASU = 'integrationTypes'
export const INTEGRATION = 'integrationIds'
export const OBJECT_TYPES = 'installationTypes'
export const PROCESSES = 'cityProcessTypes'
export const EXPLOITATION_ORGANIZATIONS = 'exploitationOrganizations'
export const OWNERS = 'owners'
export const INSTALLATION_TYPES = 'installationTypes'
export const GEOZONE = 'geoZone'
export const PROJECT = 'project'
export const REGISTER_NUMBER = 'registryNumbers'
export const STREET = 'streets'
export const VOLS = 'vols'
export const BALANCE_SHEET_HOLDERS = 'balanceSheetHolders'
export const LOCATION_TYPE = 'locationTypes'
export const UTILITY_POLE_TYPE = 'utilityPoleTypes'
export const LAMP_TYPE = 'lampTypes'
export const CONTROL_CUPBOARD = 'controlCupboardIds'
