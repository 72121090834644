import styled from 'styled-components'
import { clusterBySize, clusterBySizeMonitoring } from '@/constants/maps'
import {
  MARKS_COLORS,
} from '@/constants/styles/mapsMarks'

export const ElementContainer = styled.div`
  height: 18px;
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 0px 10px;
  .svgFill,
  .svgFill {
    height: 18px;
    width: 18px;
  }
`

export const IconContainer = styled.div`
  height: 18px;
  width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  padding: 17px 5px 17px 5px;
  width: 220px;
  position: absolute;
  top: ${({ type, isMonitoring }) => isMonitoring ? clusterBySizeMonitoring[type].top : clusterBySize[type].top}px;
  left: ${({ type }) => clusterBySize[type].left}px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  background: ${({ theme }) => theme.colors.backgrounds.default};
  z-index: 9999;
  ${ElementContainer}:not(:first-child){
    margin-top: 5px;
  }
  ${ElementContainer}:not(:last-child){
    margin-bottom: 5px;
  }
  cursor: pointer;
  box-shadow: 2px 2px 10px 0 rgba(0,0,0,0.4);
`

export const ShadowShalfe = styled.div`
  height: 260px;
  width: 240px;
  position: absolute; 
  top: ${({ type }) => clusterBySize[type].top}px;
  left: ${({ type }) => clusterBySize[type].left}px;
  border-radius: 4px;
  box-shadow: 2px 2px 10px 0 rgba(0,0,0,0.4);
`

export const Tip = styled.div`
  width: 12px;
  height: 12px;
  position: absolute;
  top: ${({ type }) => clusterBySize[type].tip.top}px;
  left: ${({ type }) => clusterBySize[type].tip.left}px;
  background: ${({ theme }) => theme.colors.backgrounds.default};
  transform: rotate(45deg);
  box-shadow: 2px 4px 10px 0 rgba(0,0,0,0.4);
`

export const ElementMark = styled.div`
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: ${({ type }) => MARKS_COLORS[type]};
`

export const ElementTitle = styled.div`
  width: 200px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: 0.01em;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  white-space: pre-wrap;
  text-align: left;
  margin-left: 10px;
  ${({ primary }) => primary && `
    margin-left: 15px;
  `}
`
