import {
    TEXT
  } from '@/constants/semanticNames'
  
  export default [
    {
      selector: 'LOCATION.PROJECT',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'LOCATION',
    },
    {
      selector: 'LOCATION.LATITUDE',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'LOCATION',
      pattern: '[0-9.+-]',
    },
    {
      selector: 'LOCATION.LONGITUDE',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'LOCATION',
      pattern: '[0-9.+-]',
    },
    {
      selector: 'LOCATION.REGION',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'LOCATION',
    },
    {
      selector: 'LOCATION.CITY',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'LOCATION',
    },
    {
      selector: 'LOCATION.STREET',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'LOCATION',
    },
    {
      selector: 'LOCATION.HOME_NUMBER',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'LOCATION',
    },

    {
      selector: 'OBJECT_CHARACTERISTIC.LAMP_COUNT',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'OBJECT_CHARACTERISTIC',
    },

  
    {
      selector: 'SERVICING.PLAN_PPR_DATE',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'SERVICING',
      mask: '99.99.9999',
    },
    {
      selector: 'SERVICING.FACT_PPR_DATE',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'SERVICING',
      mask: '99.99.9999',
    },
    {
      selector: 'SERVICING.MODERNIZATION',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'SERVICING',
    },
  ]
  