import { INTEGRATION } from '@/constants/objectTypes'

export default (array) => {
  const nodeMap = array.reduce((acc, item) => {
    acc[item.id] = { ...item, children: [] }

    return acc
  }, {})

  const buildNode = (id) => {
    const node = nodeMap[id]
    node.children = array.filter(item => item.parentId === id).map(item => buildNode(item.id))

    return (
      {
       id: node.id,
       isSupportObject: node.isSupportObject,
       isChecked: node.checked,
       children: node.children.length ? node.children : node.childrenChecked && node.childrenCount ? null : []
      })
  }

  return array.filter(item => item.nodeType === INTEGRATION).map(item => buildNode(item.id))
}