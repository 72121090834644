import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const LampModuleArtIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)
  return (
  <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18">
    <path className="svgFill" fill={fill} fill-rule="evenodd" clip-rule="evenodd" d="M18 9A9 9 0 1 1 0 9a9 9 0 0 1 18 0ZM9 3C7 3 5 4 5 4v1s2-1 4-1 4 1 4 1V4s-2-1-4-1ZM5 6h1l2 4H7L5 6Zm7 0h1l-2 4h-1l2-4Zm-1 9v-2H7v2h4Zm-4-3v-1h4v1H7Z" />
  </svg>
  
  )
}

LampModuleArtIcon.propTypes = {
  onClick: pt.func,
  color: pt.string,
}
LampModuleArtIcon.defaultProps = {
  onClick: noop,
  color: null,
}

export default memoWithName(LampModuleArtIcon)
