import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

import Inventory from './component'
import {
  getPinnedNode,
  getGlobalLoading,
  getUserApplications
} from '@/store/selectors/appSettings'
import {
  getDisplayElements,
} from '@/store/selectors/installation'
import { requestGetPinsAndZonesAndTelemetry } from '@/store/actions/installation/getZonesAndPinsAndTelemetry'
import { getZones } from '@/store/selectors/passportization'
import { requestInventoryTable } from '@/store/actions/inventory/getTable'
import { requestTotalResults } from '@/store/actions/inventory/getTotalResults'
import { getTableData, getTotalResults, getIdIntegrations, getDates, getFileStatus } from '@/store/selectors/inventory'
import { setDatesRange } from '@/store/actions/inventory'
import { requestGetFile } from '@/store/actions/inventory/getFile';


const mapStateToProps = (state) => ({
  selectedNode: getPinnedNode(state),
  userApplications: getUserApplications(state),
  displayElements: getDisplayElements(state),
  zones: getZones(state),
  tableData: getTableData(state),
  totalResults: getTotalResults(state),
  loading: getGlobalLoading(state),
  iddIntegrations: getIdIntegrations(state),
  dates: getDates(state),
  fileStatus: getFileStatus(state),
})

const mapDispatchToProps = (dispatch) => ({
  requestGetPinsAndZonesAndTelemetry: (payload) => dispatch(requestGetPinsAndZonesAndTelemetry(payload)),
  requestInventoryTable: (payload) => dispatch(requestInventoryTable(payload)),
  requestTotalResults: (payload) => dispatch(requestTotalResults(payload)),
  setDatesRange: (payload) => dispatch(setDatesRange(payload)),
  getFile: (payload) => dispatch(requestGetFile(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Inventory))
