import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import passportizationIcons from '@/components/icons/passportization'

import {
  CONTROLLER,
  LIGHT,
  SHUO,
  BASE_STATION,
  LIGHT_MODULE,
  LET,
  UNICERSE_METER,
  SUPPORT,
} from '@/constants/objectTypes'


export const CARD = 'CARD'
export const PASSPORT = 'PASSPORT'
export const COMPOSITION = 'COMPOSITION'

export const typesToObject = {
  [CONTROLLER]: [SHUO, CONTROLLER, UNICERSE_METER],
  [SHUO]: [SHUO, CONTROLLER, UNICERSE_METER],
  [UNICERSE_METER]: [SHUO, CONTROLLER, UNICERSE_METER],
  [BASE_STATION]: [BASE_STATION],
  [LET]: [LET],
  [LIGHT]: [LIGHT, SUPPORT, LIGHT_MODULE],
}


export const filterTypes = [
  {
    id: 1,
    type: SHUO,
    name: <Lang id="mapsPage.filterTypes.shuo" />,
    icon: passportizationIcons.ShuoIcon,
  },
  {
    id: 2,
    type: BASE_STATION,
    name: <Lang id="mapsPage.filterTypes.baseStation" />,
    icon: passportizationIcons.BaseStationIcon,
  },
  {
    id: 3,
    type: CONTROLLER,
    name: <Lang id="mapsPage.filterTypes.controler" />,
    icon: passportizationIcons.ControllerIcon,
  },
  {
    id: 5,
    type: UNICERSE_METER,
    name: <Lang id="mapsPage.filterTypes.universMeter" />,
    icon: passportizationIcons.UniversalMeterIIcon,
  },
  {
    id: 6,
    type: LET,
    name: <Lang id="mapsPage.filterTypes.let" />,
    icon: passportizationIcons.LEPIcon,
  },
  {
    id: 7,
    type: SUPPORT,
    name: <Lang id="mapsPage.filterTypes.support" />,
    icon: passportizationIcons.SupportIcon,
  },
  {
    id: 8,
    type: LIGHT,
    name: <Lang id="mapsPage.filterTypes.light" />,
    icon: passportizationIcons.LightIcon,
  },
  {
    id: 9,
    type: LIGHT_MODULE,
    name: <Lang id="mapsPage.filterTypes.lightModule" />,
    icon: passportizationIcons.LightModuleIcon,
  },
]

export default filterTypes
