import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import noop from 'lodash/noop'
import pt from 'prop-types'
import get from 'lodash/get'
import { Form, Formik } from 'formik'
import scheduleConfig, { DEVICE_TYPES, PHASES } from '@/forms/ScheduleManager/CreateEditScheduleForm/config'
import { TIMEZONE_CONFIG } from '@/forms/ReportManagerForm/config'
import {
  HeaderButton,
  Container,
  FieldsContainer,
  Header,
  HeaderTitle,
  IconContainer,
  GlobalPopupMessage
} from './styles'
import { InfoBlock } from '@/forms/ScheduleManager/CreateEditTaskForm/styles'
import LabelWithIcon from '@/components/blocks/LabelWithIcon'
import TextField from '@/components/fields/TextField'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import CoreIcons from '@/components/icons/core'
import SelectField from '@/components/fields/SelectField'
import TextAreaField from '@/components/fields/TextAreaField'
import { createScheduleValidator } from '@/constants/validationFields'
import CREATE_SCHEDULE_NAMES from '@/constants/forms/createSchedule'
import GlobalPopup from '@/components/blocks/GlobalPopup'
import GlobalPopupContent from '@/components/blocks/GlobalPopupContent'
import MenuIcon from '@/components/icons/menu'
import moment from 'moment'

const CreateEditScheduleForm = React.forwardRef(({
  selectedSchedule,
  setFormValues,
  submit,
  submitError,
  title,
  onCancel,
  formValues,
  edit,
  setSelectedDeviceType,
  geoZone,
  isTaskFormValid,
  taskFormRef,
  intl
}, ref) => {
 const [isCancelConfirmationPopupOpen, setIsCancelConfirmationPopupOpen] = useState(false)
 const [isJustificationPopupOpen, setIsJustificationPopupOpen] = useState(false)
 const [justificationComment, setJustificationComment] = useState('')
 const [values, setValues] = useState()
 const [isValidTasks, setIsValidTasks] = useState(false)
 const scheduleWorkingHours = formValues?.tasks?.reduce((accumulator, {workingHours}) => accumulator + (workingHours || 0), 0)
 const localInitialValues = useMemo(() => {
   const initialValues = scheduleConfig.reduce((accumulator, field) => {
     const fieldValue = get(selectedSchedule, field.selector, '')
       return {
         ...accumulator,
         [field.selector]: fieldValue,
       }}, {})

   initialValues.id = selectedSchedule.id

   return initialValues
 }, [selectedSchedule])

 useEffect(() => {
  const tasks = formValues?.tasks || []
  tasks.forEach((element) => {
    if (element.endDate && element.startDate) {
      setIsValidTasks(true)
    } else {
      setIsValidTasks(false)
    }
   });
 }, [formValues])

 const handleSetCancelPopup = useCallback((value) => () => {
   setIsCancelConfirmationPopupOpen(value)
 }, [])

 const handleSetJustificationPopup = useCallback((value) => () => {
   setIsJustificationPopupOpen(value)
 }, [])

 const handleJustificationCommentChange = useCallback((value) => {
   setJustificationComment(value)
 }, [])

 const errorHandler = (values, validateForm) => () => {
   submitError()
   if (taskFormRef.current) {
     taskFormRef.current.validateForm(formValues?.tasks[0])
   }
   validateForm(values)
 }

 const setFieldToNull = (selector, setFieldValue) => () => {
   setFieldValue(selector, '')
 }

 const onDeviceTypeChange = (value) => {
   return setSelectedDeviceType(value)
 }

 const showJustificationPopup = (values) => () => {
   setValues(values)
   setIsJustificationPopupOpen(true)
 }

 const handleSubmit = () => ()=> {
   submit({
     formValues: {...formValues, scheduleWorkingHours},
     geoZoneId: edit ? selectedSchedule.geoZoneId : geoZone.id,
     justificationComment,
     values,
     onCancel
   })
   setIsJustificationPopupOpen(false)
 }

 return (
   <Formik
     ref={ref}
     key={JSON.stringify(localInitialValues)}
     enableReinitialize
     initialValues={localInitialValues}
     validationSchema={createScheduleValidator}
     validateOnMount
     validateOnChange
     isInitialValid={edit}
     render={({
       touched, errors, values, validateForm, setFieldValue, handleChange, setSubmitting, isValid, ...ownProps
     }) => {
       return (
         <>
           <Form id="ScheduleForm" name="ScheduleForm">
             <Container>
               <Header>
                 <HeaderTitle>
                   {title}
                 </HeaderTitle>
                 <>
                   <HeaderButton type="green" onClick={isValid && isTaskFormValid && isValidTasks ? showJustificationPopup(values) : errorHandler(values, validateForm)}>
                     <Lang id="installation.createGeoZoneForm.save" />
                   </HeaderButton>
                   <HeaderButton type="red" onClick={handleSetCancelPopup(true)}>
                     <Lang id="installation.createGeoZoneForm.cancel" />
                   </HeaderButton>
                 </>
               </Header>
               <FieldsContainer>
                 <LabelWithIcon
                   title={<Lang id={'scheduleManager.form.name'} />}
                 />
                 <TextField
                   error={(touched[CREATE_SCHEDULE_NAMES.NAME] && errors[CREATE_SCHEDULE_NAMES.NAME])}
                   name={CREATE_SCHEDULE_NAMES.NAME}
                   fieldProps={{
                     autoComplete: 'off',
                   }}
                   controls={(
                     <>
                       {(get(values, CREATE_SCHEDULE_NAMES.NAME, null)) &&
                         <PortalTooltip
                           title={(<Lang id="tooltip.erase" />)}
                           renderChildren={(
                             wrapperRef,
                             onMouseEnterHandler,
                             onMouseLeaveHandler,
                           ) => (
                             <IconContainer
                               type="cross"
                               onClick={setFieldToNull(CREATE_SCHEDULE_NAMES.NAME, setFieldValue)}
                               ref={wrapperRef}
                               onMouseEnter={onMouseEnterHandler}
                               onMouseLeave={onMouseLeaveHandler}
                             >
                              <CoreIcons.EraserIcon />
                             </IconContainer>
                           )}
                         />
                       }
                     </>
                   )}
                 />
                 <LabelWithIcon
                   isError={(touched[CREATE_SCHEDULE_NAMES.TIMEZONE] && errors[CREATE_SCHEDULE_NAMES.TIMEZONE])}
                   title={<Lang id={`scheduleManager.form.timezone`} />}
                 />
                 <SelectField
                   error={(touched[CREATE_SCHEDULE_NAMES.TIMEZONE] && errors[CREATE_SCHEDULE_NAMES.TIMEZONE])}
                   name={CREATE_SCHEDULE_NAMES.TIMEZONE}
                   withSearch
                   placeholder={<Lang id={'scheduleManager.form.select'} />}
                   options={TIMEZONE_CONFIG}
                   light
                   fieldProps={{
                     autoComplete: 'off',
                   }}
                 />
                 <LabelWithIcon
                   isError={(touched[CREATE_SCHEDULE_NAMES.DEVICE_TYPE] && errors[CREATE_SCHEDULE_NAMES.DEVICE_TYPE])}
                   title={<Lang id={`scheduleManager.form.deviceType`} />}
                 />
                 <SelectField
                   error={(touched[CREATE_SCHEDULE_NAMES.DEVICE_TYPE] && errors[CREATE_SCHEDULE_NAMES.DEVICE_TYPE])}
                   name={CREATE_SCHEDULE_NAMES.DEVICE_TYPE}
                   withSearch
                   placeholder={<Lang id={'scheduleManager.form.select'} />}
                   options={DEVICE_TYPES}
                   onAfterChange={onDeviceTypeChange(values[CREATE_SCHEDULE_NAMES.DEVICE_TYPE])}
                   light
                   fieldProps={{
                     autoComplete: 'off',
                   }}
                 />
                   {values.deviceType === 'SHUNO' && <>
                     <LabelWithIcon
                       isError={(touched[CREATE_SCHEDULE_NAMES.PHASE] && errors[CREATE_SCHEDULE_NAMES.PHASE])}
                       title={<Lang id={`scheduleManager.form.phase`} />}
                     />
                     <SelectField
                       error={(touched[CREATE_SCHEDULE_NAMES.PHASE] && errors[CREATE_SCHEDULE_NAMES.PHASE])}
                       name={CREATE_SCHEDULE_NAMES.PHASE}
                       withSearch
                       multiselect
                       placeholder={<Lang id={'scheduleManager.form.select'} />}
                       options={PHASES}
                       light
                       fieldProps={{
                         autoComplete: 'off',
                       }}
                     />
                   </>}
                 <LabelWithIcon
                   isError={(touched[CREATE_SCHEDULE_NAMES.COMMENT] && errors[CREATE_SCHEDULE_NAMES.COMMENT])}
                   title={(<Lang id={`scheduleManager.form.comment`} />)}
                 />
                 <TextAreaField
                   error={(touched[CREATE_SCHEDULE_NAMES.COMMENT] && errors[CREATE_SCHEDULE_NAMES.COMMENT])}
                   name={CREATE_SCHEDULE_NAMES.COMMENT}
                   rows="4"
                   charLimit={150}
                   fieldProps={{
                     autoComplete: 'off',
                   }}
                   controls={(
                     <>
                       {(get(values, CREATE_SCHEDULE_NAMES.COMMENT, null)) &&
                         <PortalTooltip
                           title={(<Lang id="tooltip.erase" />)}
                           renderChildren={(
                             wrapperRef,
                             onMouseEnterHandler,
                             onMouseLeaveHandler,
                           ) => (
                             <IconContainer
                               type="cross"
                               onClick={setFieldToNull(CREATE_SCHEDULE_NAMES.COMMENT, setFieldValue)}
                               ref={wrapperRef}
                               onMouseEnter={onMouseEnterHandler}
                               onMouseLeave={onMouseLeaveHandler}
                             >
                              <CoreIcons.EraserIcon />
                             </IconContainer>
                           )}
                         />
                       }
                       </>
                   )}
                 />
                 <InfoBlock>
                   <LabelWithIcon
                     title={<Lang id={'scheduleManager.form.scheduleWorkingHours'}/>}
                   />
                     {`${Math.floor(moment.duration(scheduleWorkingHours).asHours()) || 0} ${intl.messages[`widgets.hour`]} ${moment.duration(scheduleWorkingHours).minutes() || 0} ${intl.messages[`widgets.minutes`]}`}
                 </InfoBlock>
               </FieldsContainer>
             </Container>
           </Form>
           {isCancelConfirmationPopupOpen && (
             <GlobalPopup
               content={(
                 <GlobalPopupContent
                   type={'error'}
                   onClose={handleSetCancelPopup(false)}
                   title={<Lang id={'scheduleManager.popup.attention'}/>}
                   message={edit ? <Lang id={'scheduleManager.popup.cancelEditScheduleMessage'}/> : <Lang id={'scheduleManager.popup.cancelCreateScheduleMessage'}/>}
                   config={{
                     error: {
                       icon: MenuIcon.TreshIcon,
                       buttons: [
                         {
                          statusType: 'flat',
                          onClickSelector: handleSetCancelPopup(false),
                          title: <Lang id="scheduleManager.popup.no" />,
                         },
                         {
                          statusType: 'error',
                          onClickSelector: onCancel,
                          title: <Lang id="scheduleManager.popup.yes" />,
                         },
                       ],
                     },
                   }}
                 />
               )}
             />
           )}
           {isJustificationPopupOpen && (
             <GlobalPopup
               content={(
                 <GlobalPopupContent
                   type={'warning'}
                   onClose={handleSetJustificationPopup(false)}
                   title={edit ? <Lang id={'scheduleManager.popup.editSchedule'}/> : <Lang id={'scheduleManager.popup.createSchedule'}/>}
                   message={
                     <GlobalPopupMessage>
                       {edit ? <Lang id={'scheduleManager.popup.editScheduleMessage'}/> : <Lang id={'scheduleManager.popup.createScheduleMessage'}/>}
                       <TextAreaField
                          name={CREATE_SCHEDULE_NAMES.JUSTIFICATION_COMMENT}
                          rows="3"
                          charLimit={100}
                          onAfterChange={(value) => handleJustificationCommentChange(value)}
                          placeholder={intl.messages['scheduleManager.form.commentText']}
                       />
                     </GlobalPopupMessage>
                   }
                   config={{
                     warning: {
                       icon: MenuIcon.AttentionIcon,
                       buttons: [
                         {
                          statusType: 'flat',
                          onClickSelector: handleSetJustificationPopup(false),
                          title: <Lang id="scheduleManager.popup.cancel" />,
                         },
                         {
                          statusType: 'warning',
                          disabled: !justificationComment.length,
                          onClickSelector: handleSubmit(),
                          title: <Lang id="scheduleManager.popup.save" />,
                         },
                       ],
                     },
                   }}
                 />
               )}
             />
           )}
         </>
       )
     }}
   />
 )
})

CreateEditScheduleForm.defaultProps = {
    selectedSchedule: {},
    setFormValues: noop,
    submit: noop,
    submitError: noop,
    onCancel: noop,
    title: false,
    formValues: {},
    edit: false,
}
CreateEditScheduleForm.propTypes = {
    selectedSchedule: pt.objectOf(pt.object),
    setFormValues: pt.func,
    submit: pt.func,
    submitError: pt.func,
    onCancel: pt.func,
    title: pt.string,
    formValues: pt.objectOf(pt.string),
    edit: pt.bool,
}

export default CreateEditScheduleForm