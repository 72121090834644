import { handleActions } from 'redux-actions'
import generateDefaultTableReducer, {
  generateTableParameter,
} from '@/helpers/table/generateDefaultTableReducer'
import generateDefaultTableState from '@/helpers/table/generateDefaultTableState'

import {GET_INVENTORY_TABLE, GET_TOTOAL_RESULTS, INVENTORY_TABLE, SET_ID_INTEGRATIONS, SET_DATES_RANGE, GET_INVENTORY_FILE} from '@/store/actions/inventory'
import { fields } from '@/constants/tablesConfig/inventory'
import REQUEST_STATUSES from '@/constants/requests';



const initialState = {
  fileExportStatus: REQUEST_STATUSES.NOT_REQUESTED,
  data: [],
  dates: {
    dateFirst: null,
    dateSecond: null,
  },
  totalResults: {},
  loading: false,
  table: generateDefaultTableState(fields),
  idIntegrations: {},
}

export const reducer = handleActions(
  {
    [GET_INVENTORY_TABLE.REQUEST]: (state) => ({
      ...state,
      loading: true
    }),
    [GET_INVENTORY_TABLE.SUCCESS]: (state, { payload: { data, totalElements } }) => ({
      ...state,
      data,
      loading: false,
      ...generateTableParameter(state, 'resultCount', totalElements),
    }),
    [GET_INVENTORY_TABLE.ERROR]: (state) => ({
      ...state,
      loading: false,
    }),
    [GET_TOTOAL_RESULTS.SUCCESS]: (state, { payload }) => ({
      ...state,
      totalResults: payload
    }),
    [SET_ID_INTEGRATIONS]: (state, { payload }) =>({
      ...state,
      idIntegrations: payload
    }),
    [SET_DATES_RANGE]: (state, { payload }) => ({
      ...state,
      dates: payload,
    }),
    [GET_INVENTORY_FILE.REQUEST]: (state) => ({
      ...state,
      fileExportStatus: REQUEST_STATUSES.PENDING,
    }),
    [GET_INVENTORY_FILE.SUCCESS]: (state) => ({
      ...state,
      fileExportStatus: REQUEST_STATUSES.IDLE,
    }),
    ...generateDefaultTableReducer(INVENTORY_TABLE),
  },
  initialState,
)

export default reducer