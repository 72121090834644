import {
    takeLatest, put, select
} from 'redux-saga/effects'
import createNotifications from "@/helpers/notification";
import request from '@/helpers/axios'
import { generateSearchReportFileUrl } from '@/constants/apiRoutes'
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob'
import {
    errorSearchReportFile,
    successSearchReportFile
} from '@/store/actions/search/getFile'
import { GET_SEARCH_REPORT_FILE } from '@/store/actions/search'
import {
    getSearchTableParameters,
    getSearchValues
} from '@/store/selectors/search'

function* getSearchReportFileSaga({ payload }) {
    const toast = createNotifications()
    try {
        const tableParameters = yield select(getSearchTableParameters)
        const searchValues = yield select(getSearchValues)

        const response = yield request({
            url: generateSearchReportFileUrl({
                params: {
                    searchByName: searchValues.name,
                    installationTypes: searchValues.installationTypes,
                    geoZoneIds: searchValues.geoZone,
                    projectIds: searchValues.project,
                    integrationIds: searchValues.integrationIds,
                    regions: searchValues.region,
                    cities: searchValues.city,

                    excludeColumns: payload.allColumns.filter(x => !tableParameters.activeFields.includes(x)),

                    pageNumber: tableParameters.page - 1,
                    pageSize: tableParameters.perPage,
                    searchByTable: tableParameters.requestQuery || '',
                    sort: [`${tableParameters.sort.field}: ${tableParameters.sort.direction}`],
                },
            }),
            method: 'get',
            options: {
               responseType: 'blob',
            },
        })

        downloadFileFromBlob({
            blob: response.data,
            fileName: `SLS24-search_results.xlsx`,
        })

        yield put(successSearchReportFile())
    } catch (error) {
        yield put(errorSearchReportFile(error))
        toast({
            title: 'Ошибка операции!',
            description: 'Невозможно выгрузить результаты поиска.\nПовторите попытку позже.',
            type: 'error',
        })
    }
}

export default function* root() {
    yield takeLatest(GET_SEARCH_REPORT_FILE.REQUEST, getSearchReportFileSaga)
}