export default {
  titles: {
    add: 'Add a group of objects',
    create: 'Create\ngroup',
    edit: 'Edit\ngroup',
    filters: 'Filters',
    schedule: 'Schedule',
    passportization: 'Passportization',
    control: 'Control',
    verification: 'Verification',
  },
  popup: {
    yes: 'Yes',
    no: 'No',
    accept: 'Accept',
    executeImpossible: 'Impossible to execute!',
    attention: 'Attention!',
    createObjectsGroupValidationMessage: 'To create a group of objects, you must set all required parameters',
    editObjectsGroupValidationMessage: 'To save changes to a group of objects, you must set all required parameters',
    cancelCreateObjectsGroupMessage: 'Are you sure you want to cancel the creation of the object group?',
    cancelEditObjectsGroupMessage: 'Are you sure you want to undo the changes you made to a group of objects?',
    objectsGroupEmptyObjectsMessage: 'Objects for the group have not been selected'
  },
  form: {
    name: 'Group name',
    color: 'Group color',
    objects: 'List of objects to select',
    reset: 'Reset'
  },
  filters: {
    street: 'Street',
    registryNumber: 'Registry number',
    objectType: 'Object type',
    vols: 'VOLS',
    utilityPoleType: 'Utility pole type',
    lightFixtureType: 'Lamp type',
    pedestrianCrossingSign: 'Pedestrian crossing sign',
    controlCupboard: 'SHUNO',
    networkCompany: 'Balance holder',
    streetPlaceholder: 'Select street',
    registryNumberPlaceholder: 'Select registry number',
    objectTypePlaceholder: 'Select object type',
    volsPlaceholder: 'Select VOLS',
    utilityPoleTypePlaceholder: 'Select utility pole type',
    lightFixtureTypePlaceholder: 'Select lamp type',
    pedestrianCrossingSignPlaceholder: 'Select pedestrian crossing sign',
    controlCupboardPlaceholder: 'Select SHUNO',
    networkCompanyPlaceholder: 'Select balance holder',
  },
  schedule: {
    label: 'Select schedule:',
    placeholder: 'Not selected',
    warning: 'This functionality is not available for the selected group of objects. Check that the group includes only Lights or only SHUNO.',
    set: 'Set',
    reset: 'Reset schedule',
    emptyStateMessage: 'No scheduling options available',
    setScheduleErrorMessage1: 'Attention! This schedule cannot be set. Please add more members to the group',
    setScheduleErrorMessage2: 'new objects that must operate according to the selected schedule.',
  },
  verification: {
    label: 'Status',
    placeholder: 'Not selected',
    warning: 'This functionality is not available for the selected group of objects. Check that the group includes only objects with the status "New", "Not verified", "Checked"',
    verify: 'Verify',
    checkAll: 'Check all'
  },
  verificationStatus: {
    INSTALLED: 'Installed',
    VERIFIED: 'Verified',
    NOT_EXIST: 'Not exist',
    NOT_INSTALLED: 'Not installed',
    UNINSTALLED: 'Uninstalled',
    NEW: 'New',
    NOT_VERIFIED: 'Not verified',
  },
  control: {
    labelRM: 'Dimming level:',
    labelSHUNO: 'State:',
    labelPhase: 'Phase:',
    placeholderState: 'Not selected',
    placeholderPhase: 'Not selected',
    warning: 'This functionality is not available for the selected group of objects. Check that the group includes only Lights or only SHUNO',
    apply: 'Apply to all',
    cancel: 'Cancel command',
    on: 'On',
    off: 'Off',
    status: {
      set: 'Set...',
      sent: 'Sent...',
      received: 'Received...',
      completed: 'Completed successfully',
      failed: 'Execution failed',
      canceled: 'Canceled',
    }
  },
  passportization: {
    warning: 'To make changes to the matching fields of the passport of the selected group of objects, select the passport field and enter the new value.',
    labelField: 'Passport field:',
    labelValue: 'Value:',
    placeholder: 'Not selected',
    apply: 'Apply to all',
    change: 'Change field',
    save: 'Save',
  }
}