export default {
  formTitles: {
    ASU: 'АСУ:',
    integration: 'Интеграция:',
    objectTypes: 'Типы объектов:',
    process: 'Городские процессы:',
    objectState: 'Состояние объекта:',
    owner: 'Собственник:',
    exploitationOrganization: 'Эксплуатирующая организация:',
    registerNumber: 'Реестровый номер:',
    street: 'Улица:',
    vols: 'ВОЛС:',
    balanceSheetHolders: 'Балансодержатель:',
    locationType: 'Признак пешеходного пространства:',
    utilityPoleType: 'Тип опоры:',
    lampType: 'Тип светильника:',
    controlCupboard: 'ШУНО:',
  },
  selectPlaceholders: {
    ASU: 'Все АСУ',
    integration: 'Все интеграции',
    objectTypes: 'Все типы объектов',
    process: 'Все городские процессы',
    exploitationOrganizations: 'Все организации',
    owners: 'Все собственники',
    registerNumbers: 'Все реестровые номера',
    streets: 'Все улицы',
    vols: 'Все ВОЛС',
    balanceSheetHolders: 'Все балансодержатели',
    locationTypes: 'Все признаки',
    utilityPoleTypes: 'Все типы опор',
    lampTypes: 'Все типы светильников',
    controlCupboards: 'Все ШУНО',
  },
  buttons: {
    reset: 'Сбросить',
    accept: 'Применить',
  },
}
