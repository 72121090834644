import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import random from 'lodash/random'
import LiveTime from '@/components/icons/livetime'
import ActivitiLogCell from '@/components/blocks/DutyTable/customCells/ActivitiLogCell'


export const fields = [
  {
    id: 'fullName',
    name: <Lang id="tableFields.actionLog.user" />,
    value: 'ASC',
    type: 'string',
    customWidth: 170,
  },
  {
    id: 'eventTime',
    name: <Lang id="tableFields.actionLog.eventTime" />,
    value: 'ASC',
    type: 'string',
    customWidth: 150,
  },
  {
    id: 'appName',
    name: <Lang id="tableFields.actionLog.appName" />,
    value: 'ASC',
    type: 'string',
  },
  {
    id: 'objectType',
    name: <Lang id="tableFields.actionLog.objectType" />,
    value: 'ASC',
    type: 'string',
    customCell: (props) => <ActivitiLogCell {...props} />,  
  },
  {
    id: 'objectName',
    name: <Lang id="tableFields.actionLog.objectId" />,
    value: 'ASC',
    type: 'string',
    customCell: (props) => <ActivitiLogCell {...props} />, 
  },
  {
    id: 'action',
    name: <Lang id="tableFields.actionLog.action" />,
    value: 'ASC',
    type: 'string',
    customWidth: 200,
  },
  {
    id: 'oldValue',
    name: <Lang id="tableFields.actionLog.oldValue" />,
    value: 'ASC',
    type: 'string',
    customWidth: 220,
    customCell: (props) => <ActivitiLogCell {...props} />,  
  },
  {
    id: 'newValue',
    name: <Lang id="tableFields.actionLog.newValue" />,
    value: 'ASC',
    type: 'string',
    customWidth: 220,
    customCell: (props) => <ActivitiLogCell {...props} />,  
  },
  {
    id: 'otherChanges',
    name: <Lang id="tableFields.actionLog.otherСhanges" />,
    value: 'ASC',
    type: 'string',  
    customCell: (props) => <ActivitiLogCell {...props} />,  
  },
]

export const exportOptions = [
  {
    name: 'EXCEL',
    icon: LiveTime.EXELIcon,
  },
]

const actions = [
  'Проверка  состояния',
  'Заказ отчета',
  'Инсталляция',
  'Действия с оповещением',
]

export const generateTableData = (rows = 20) => {
  const data = []

  for (let i = 0; i < rows; i += 1) {
    data.push({
      id: i + 1,
      action: actions[random(0, 3)],
      object: `Осв.0${3451 + i}`,
      geoZone: 'Тюмень',
      date: '10.12.2018',
      time: '10:40',
      problem: 'Нет',
    })
  }

  return data
}

export const data = generateTableData()
export const activeFields = [
  'action',
  'object',
  'geoZone',
  'date',
  'time',
  'problem',
]

export default {
  fields,
  exportOptions,
  activeFields,
}
